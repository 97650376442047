<section style="margin-bottom: 100px; background-color: #fff">
  <div class="container">
    <div class="mt-5 mb-5" style="justify-content: center">
      <iframe
        [id]="iframeId"
        frameBorder="0"
        width="100%"
        height="100%"
        [src]="iframeSrc | sanitize"
      ></iframe>
    </div>
  </div>
</section>
