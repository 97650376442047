<section>
  @if (configuration().images.length > 0) {
    <!-- obtener las images -->
    @for (image of configuration().images; track $index) {
      <div
        class="full-width-top-banner detail-top-banner"
        style="background: center / cover no-repeat url('{{ image.url }}');"
      >
        <img
          class="ticket-shadow"
          title="{{ configuration().headerTitle }}"
          alt="{{ configuration().headerTitle }}"
          src="/assets/images/shared/header_shadow.png"
        />
        <div class="faq-title-container">
          <h2 class="title-faq">
            {{ configuration().headerTitle }}
          </h2>
        </div>
      </div>
    }
  } @else {
    <div
      class="full-width-top-banner detail-top-banner"
      style="height: 200px !important"
    >
      <div class="faq-title-container">
        <h2 class="title-faq" style="color: #000 !important">
          {{ configuration().headerTitle }}
        </h2>
      </div>
    </div>
  }
</section>

<section class="accordion">
  <div class="container-fluid home-products-container">
    <div class="col-home">
      <div class="row title">
        <div class="col">
          <h2 class="faq-title">{{ configuration().title }}</h2>
        </div>
      </div>
      <div
        id="accordion-faqs"
        class="row-accordion accordion accordion-flush"
        id="accordionFlushFaq"
      >
        @for (tab of configuration().tabs; track tab; let key = $index) {
          <div class="accordion-item">
            <h2 class="accordion-header" id="{{ 'accordionHead' + key }}">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                [attr.data-bs-target]="'#collapse' + key"
                aria-expanded="false"
                [attr.aria-controls]="'#collapse' + key"
              >
                {{ tab.title }}
              </button>
            </h2>
            <div
              id="{{ 'collapse' + key }}"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              [attr.data-bs-parent]="'#accordionHead' + key"
            >
              <div class="accordion-body">
                <p>
                  <span [innerHTML]="tab.description"></span>
                  @if (tab.path !== "") {
                    <a [href]="tab.path" class="link-accordion">
                      {{ tab.linkText }}
                    </a>
                  }
                </p>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</section>

<!-- Accordion -->

<!-- more info faqs -->
@if (configuration().buttonPath) {
  <section class="more-info">
    <div class="container-fluid home-products-container">
      <div class="col-home">
        <div class="row">
          <div class="col">
            <h2 class="faq-title">{{ configuration().footerTitle }}</h2>
            <h3 class="faq-subtitle">{{ configuration().footerSubtitle }}</h3>
            <a
              href="{{ configuration().buttonPath }}"
              class="btn-comprar btn-faq"
            >
              {{ configuration().footerButton }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
}
<!-- more info faqs -->
