<section>
  <div
    class="full-width-top-banner small mrb40 parallax-bg"
    style="
      background-image: url(/assets/images/cookies-widget/barcelona-night.jpg);
    "
  >
    <div class="container">
      <h1>{{ "template.static_cookies.title" | translate }}</h1>
    </div>
  </div>
  <!-- end full-width-banner -->
</section>

<!-- INIT CONTENT MAIN TEXT -->
<section>
  <div class="container mrb90">
    <div class="row">
      <div class="col-sm-8">
        <h3>{{ "template.static_cookies.title" | translate }}</h3>
        {{ "template.static_cookies.what_are_cookies" | translate }}
        {{ "template.static_cookies.cookies_types" | translate }}
        {{ "template.static_cookies.cookies_description" | translate }}
        <style></style>
        <table
          id="cookies-others"
          width="100%"
          border="1"
          bgcolor="#ffffff"
          align="left"
          cellspacing="0"
          cellpadding="0"
        >
          @for (number of numbers; track $index) {
            <tr [ngClass]="{ 'row-header': $index === 0 }" class="cookie-row">
              <td width="20%">
                {{
                  "template.static_cookies.cookies_table.caption_" +
                    number +
                    "_1" | translate
                }}
              </td>
              <td width="40%">
                {{
                  "template.static_cookies.cookies_table.caption_" +
                    number +
                    "_2" | translate
                }}
              </td>
              <td>
                {{
                  "template.static_cookies.cookies_table.caption_" +
                    number +
                    "_3" | translate
                }}
              </td>
            </tr>
          }
        </table>

        {{ "template.static_cookies.user_options" | translate }}
        {{ "template.static_cookies.cookies_remove" | translate }}
      </div>
    </div>
  </div>
</section>
