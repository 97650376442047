import { Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TermsAndConditionsWidgetConfiguration } from './models/terms-and-conditions-widget-configuration.interface';

@Component({
  selector: 'hola-terms-and-conditions-widget',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './terms-and-conditions-widget.component.html',
  styleUrl: './terms-and-conditions-widget.component.scss',
})
export class TermsAndConditionsWidgetComponent {
  configuration = input.required<TermsAndConditionsWidgetConfiguration>();
}
