import { DatePipe } from '@angular/common';
import { Component, computed, inject, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PurchaseSummaryComponent } from '../../shared/components/purchase-summary/purchase-summary.component';
import { PageId } from '../../shared/enums/page-id.enum';
import { GtmDataLayerService } from '../../shared/gtm/gtm.service';
import { CartSummaryProduct } from '../../shared/models/cart-summary-product.interface';
import { DiscountAmount } from '../../shared/models/discount-amount.interface';
import { DiscountConcept } from '../../shared/models/discount-concept.interface';
import { Discount } from '../../shared/models/discount.interface';
import { CustomCurrencyPipe } from '../../shared/pipes/custom-currency.pipe';
import { CartService } from '../../shared/services/cart.service';
import { CatalogService } from '../../shared/services/catalog.service';
import { LanguageService } from '../../shared/services/language.service';
import { NavigationService } from '../../shared/services/navigation.service';
import { OrderService } from '../../shared/services/order.service';
import { ThanksWidgetConfiguration } from './models/thanks-widget-configuration.interface';

// TODO REFACTORIZAR COMPONENTE

@Component({
  selector: 'hola-thanks-widget',
  standalone: true,
  imports: [
    TranslateModule,
    RouterModule,
    DatePipe,
    CustomCurrencyPipe,
    PurchaseSummaryComponent,
  ],
  templateUrl: './thanks-widget.component.html',
  styleUrl: './thanks-widget.component.scss',
})
export class ThanksWidgetComponent {
  public configuration = input.required<ThanksWidgetConfiguration>();

  private readonly languageService = inject(LanguageService);
  private readonly cartService = inject(CartService);
  private readonly catalogService = inject(CatalogService);
  private readonly orderService = inject(OrderService);
  private readonly navigationService = inject(NavigationService);
  private readonly gtmDataLayerService = inject(GtmDataLayerService);

  public locale = this.languageService.locale;
  public homeUrl = this.navigationService.homeUrl;
  public cartUrl = this.navigationService.cartUrl;

  public productsGrouped = computed(() =>
    this.cartService
      .cartSummaryProducts()
      .reduce(
        (
          acc: { [key: string]: CartSummaryProduct[] },
          curr: CartSummaryProduct,
        ) => {
          const key = `${curr.parentProduct?.code || ''} ${curr.departDate || ''} ${curr.returnDate || ''} ${curr.tagCode || ''}`;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(curr);
          return acc;
        },
        {},
      ),
  );

  public products = computed(() =>
    Array.from(
      Object.entries(this.productsGrouped()),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([key, value]) => value,
    ),
  );

  public itemDiscounts = computed(
    () =>
      this.orderService
        .order()
        ?.items.map((item) =>
          item.itemDiscounts.map(
            (itemDiscount) =>
              ({
                productCode: item.productCode,
                productName: this.getCartProductName(item?.productCode),
                discountName: itemDiscount.discountName,
                discountCode: itemDiscount.discountCode,
                discountAmount: itemDiscount.finalAmount,
                discountPercentage: itemDiscount.discountValue,
                quantity: itemDiscount.quantity,
              }) as Discount,
          ),
        )
        .flat() || [],
  );

  public itemDiscountsAmounts = computed(() =>
    this.itemDiscounts().reduce((acc: DiscountAmount[], curr: Discount) => {
      const item = acc.find((a) => a.code === curr?.discountCode);
      if (item) {
        item.amount = item.amount + curr.discountAmount;
      } else {
        const item = {
          code: curr.discountCode,
          name: curr.discountName,
          amount: curr.discountAmount,
        } as DiscountAmount;
        acc = [...acc, item];
      }
      return acc;
    }, []),
  );

  public itemDiscountConcepts = computed(() =>
    this.itemDiscounts().reduce((acc: DiscountConcept[], curr: Discount) => {
      const item = acc.find((a) => a.discountCode === curr?.discountCode);
      if (item) {
        item.productConcepts.push({
          name: curr.productName,
          quantity: curr.quantity,
          amount: curr.discountAmount,
          percentage: curr.discountPercentage,
        });
      } else {
        acc = [
          ...acc,
          {
            discountName: curr.discountName,
            discountCode: curr.discountCode,
            productConcepts: [
              {
                amount: curr.discountAmount,
                name: curr.productName,
                percentage: curr.discountPercentage,
                quantity: curr.quantity,
              },
            ],
          } as DiscountConcept,
        ];
      }
      return acc;
    }, []),
  );

  public order = this.orderService.order;

  constructor() {
    const order = this.orderService.order();
    const products = this.cartService.products();

    if (products.length > 0 && order) {
      this.gtmDataLayerService.purchase(products, order);
      this.cartService.clear();
      this.orderService.reset();
    } else {
      this.navigationService.navigateToPage(PageId.Home);
    }
  }

  private getCartProductName(productCode: string): string {
    return this.catalogService
      .variantProducts()
      .filter((product) => product.code === productCode)[0]?.code;
  }
}
