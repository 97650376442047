<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="container-fluid head-container">
  <section class="head">
    <!-- menu -->
    <div class="row row-container-nav">
      <div class="col-xl-12 col-md-12 container nav">
        <a class="navbar-brand" [routerLink]="homeUrl()">
          <img src="/assets/images/shared/logo.jpg" alt="" />
        </a>
        <nav class="navbar navbar-expand-lg navbar-light main bg-light">
          <!-- cart button mobile display -->
          <div class="cart-mobile">
            <!-- menu-item -->
            <div class="nav-item short-item cart">
              <button
                class="btn btn-sm"
                type="button"
                data-toggle="modal"
                data-target="#modal-cart"
                (click)="showCart()"
              >
                <div class="cart-ico">
                  @if (productsQuantity() > 0) {
                    <span class="items-comprados">
                      {{ productsQuantity() }}
                    </span>
                  }
                </div>
              </button>
            </div>
          </div>
          <!-- cart button mobile display -->
          <div class="">
            <button
              class="navbar-toggler navbar-toggler-right collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-expanded="false"
              aria-controls="#navbarSupportedContent"
            >
              <span> </span>
              <span> </span>
              <span> </span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav">
                <!-- menu-item -->
                <li
                  class="nav-item dropdown position-static tickets"
                  (mouseover)="showTickets()"
                  (focus)="showTickets()"
                  (click)="toogleTickets()"
                  aria-expanded="false"
                  aria-controls="#navbarDropdownTickets"
                >
                  <div class="nav-link dropdown-toggle" href="#" role="button">
                    {{ "template.header.tickets.title" | translate }}
                  </div>
                  <div
                    id="navbarDropdownTickets"
                    class="col-xl-4 link-productos-hover collapse"
                  >
                    <ul class="productos-tmb">
                      <!--TODO: obtener el distribuidor del menu, aprovecho el mock del footer-->
                      @for (product of products(); track $index) {
                        <li class="li-product-hover">
                          <a
                            class="producto-hover menu_event_buy se__menu-product-{{
                              product.code
                            }} comprar_menu_{{ product.code }}"
                            (click)="product?.onClick()"
                            id="product-{{ $index }}"
                            >{{ product.title }}
                          </a>
                        </li>
                      }
                    </ul>
                  </div>
                </li>
                <!-- menu-item -->
                <li class="nav-item mouret">
                  <a class="nav-link" [routerLink]="mouteAmbHolaUrl()">
                    {{ "template.header.menu.how_to_move" | translate }}
                  </a>
                </li>
                <!-- menu-item -->
                <li class="nav-item blog">
                  <a
                    class="nav-link"
                    rel="nofollow"
                    target="_blank"
                    href="{{ urlHolaBcnBlog }}"
                  >
                    {{ "template.header.menu.blog" | translate }}
                  </a>
                </li>
                <!-- menu-item -->
                <li class="nav-item app">
                  <a class="nav-link" [routerLink]="appHolaBarcelonaUrl()"
                    >App</a
                  >
                </li>
                <!-- menu-item -->

                <li
                  class="nav-item lang"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarDropdownLang"
                  aria-expanded="false"
                  aria-controls="#navbarDropdownLang"
                >
                  <div class="dropdown select-lang">
                    <a
                      class="dropdown-toggle"
                      data-toggle="dropdown"
                      (mouseover)="showLangs()"
                      (focus)="showLangs()"
                      (click)="hideLangs($event)"
                    >
                      <span class="globe-ico"></span>
                      <span class="globe-text">{{ appLanguage() }} </span>
                    </a>
                    <div
                      class="dropdown-menu dropdown-lang collapse"
                      id="navbarDropdownLang"
                      [ngClass]="{ 'show-langs': langsVisible }"
                      (mouseout)="hideLangs($event)"
                      (blur)="hideLangs($event)"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <!--TODO obtener listado idiomas-->
                      <a
                        class="dropdown-item"
                        (click)="selectLang(AppLanguage.CA)"
                      >
                        CA
                      </a>
                      <a
                        class="dropdown-item"
                        (click)="selectLang(AppLanguage.EN)"
                      >
                        EN
                      </a>
                      <a
                        class="dropdown-item"
                        (click)="selectLang(AppLanguage.ES)"
                      >
                        ES
                      </a>
                      <a
                        class="dropdown-item"
                        (click)="selectLang(AppLanguage.FR)"
                      >
                        FR
                      </a>
                      <a
                        class="dropdown-item"
                        (click)="selectLang(AppLanguage.IT)"
                      >
                        IT
                      </a>
                      <a
                        class="dropdown-item"
                        (click)="selectLang(AppLanguage.DE)"
                      >
                        DE
                      </a>
                    </div>
                  </div>
                  <!-- end lang-block -->
                </li>

                <!-- menu-item -->
                <li class="nav-item short-item cart">
                  <button
                    class="btn btn-sm"
                    type="button"
                    data-toggle="modal"
                    data-target="#modal-cart"
                    (click)="showCart()"
                  >
                    <div class="cart-ico">
                      @if (productsQuantity() > 0) {
                        <span class="items-comprados">
                          {{ productsQuantity() }}
                        </span>
                      }
                    </div>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        @if (showBuyTicketsButton()) {
          <a (click)="onClickBuyTickets()" class="btn-comprar comprar_menu">
            {{ "template.header.menu.buy_tickets" | translate }}
            <div class="ticket-ico"></div>
          </a>
        }
      </div>
      <!-- desplegable menu tickets  -->
      <ul
        class="dropdown-tickets dropdown-menu megamenu"
        [ngClass]="{ 'mega-visible': megaVisible }"
        (mouseout)="hideTickets($event)"
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-4 link-productos-hover">
              <ul class="productos-tmb">
                <!--TODO: obtener el distribuidor del menu, aprovecho el mock del footer-->
                @for (product of products(); track $index) {
                  <li class="li-product-hover">
                    <a
                      class="producto-hover menu_event_buy se__menu-product-{{
                        product.code
                      }} comprar_menu_{{ product.code }}"
                      (click)="product?.onClick()"
                      id="product-{{ $index }}"
                      (mouseover)="muestraProducto($index)"
                      (blur)="muestraProducto($index)"
                      (focus)="muestraProducto($index)"
                      >{{ product.title }}
                    </a>
                  </li>
                }
              </ul>
            </div>
            <div class="col-xl-8 link-productos-detail">
              <ul class="productos-tmb-detail">
                <!--TODO: obtener el distribuidor del menu-->
                @for (product of products(); track $index) {
                  <li class="" [ngClass]="{ mostrar: mostrarProducto($index) }">
                    <div class="container detail">
                      <div class="row">
                        <div class="col-md-6 img-productos">
                          <img
                            class="img-bg"
                            [alt]="product.title"
                            [src]="product.imageUrl"
                          />
                        </div>

                        <div class="col-md-6 detail-productos">
                          <a
                            class="menu_event_buy"
                            (click)="product?.onClick()"
                            id="product-detail-{{ $index }}"
                          >
                            <div class="desde">
                              {{ "template.menu.desde" | translate }}
                            </div>
                            <div class="blank-line"></div>
                            <div class="preciosd">
                              {{
                                product.activePrice | customCurrency: locale()
                              }}
                            </div>
                            <div class="preciocd">
                              <!-- TODO: aplicar descuento -->
                              {{
                                product.reducedPrice | customCurrency: locale()
                              }}
                            </div>

                            <div class="descripcion">
                              {{ product.shortDescription }}
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </li>
                }
              </ul>
            </div>
          </div>
        </div>
      </ul>

      <!-- desplegable menu tickets  -->
    </div>
    <!-- menu -->
  </section>
  <!-- head     -->
</div>

<hola-cart-menu></hola-cart-menu>

<!-- Modal carrito-->
<!--{% render(controller("TmbStoreCartBundle:Component\\ProductComponent:cartMenu", { 'new': true})) %}-->
<!-- Modal carrito-->
