import { Component, input } from '@angular/core';
import { SectionIntroWidgetConfiguration } from './models/section-intro-widget-configuration.interface';

@Component({
  selector: 'hola-section-intro-widget',
  standalone: true,
  imports: [],
  templateUrl: './section-intro-widget.component.html',
  styleUrl: './section-intro-widget.component.scss',
})
export class SectionIntroWidgetComponent {
  configuration = input.required<SectionIntroWidgetConfiguration>();
}
