@if (showDiscountForm()) {
  <hola-order-discounts-apply></hola-order-discounts-apply>
}

<div class="row">
  <div class="col-sm-12 discounts_list">
    @if (showOrderSummary()) {
      <hola-order-products-summary></hola-order-products-summary>
    }
    <hola-order-discounts-amounts></hola-order-discounts-amounts>
  </div>
</div>

<div class="row">
  <hola-order-discounts-concepts></hola-order-discounts-concepts>
</div>

<div class="row">
  <div class="col-sm-12">
    <div class="ch-discounts-border"></div>
  </div>
</div>
<div id="resume-carry" class="row">
  <div class="col-4">
    <span class="ch-total"> {{ "template.product.total" | translate }}: </span>
  </div>
  <div class="col-8 ch-total-container">
    @if (orderActivePrice() > orderReducedPrice()) {
      <span class="price-tag subtotal">
        {{ orderActivePrice() | customCurrency: locale() }}
      </span>
    }
    <span class="price-tag total">
      {{ orderReducedPrice() | customCurrency: locale() }}
    </span>
  </div>
  <div class="col-sm-12">
    <span class="xsmall">
      {{ "template.cart.taxes" | translate }}
    </span>
  </div>
</div>
<!-- RESUME CARRY CHECKOUT -->
@if (showButtons()) {
  <div class="row ch-resum-actions-block">
    <div class="col-sm-12">
      <a [routerLink]="homeUrl()" class="btn-primary grey ch-btn-back">
        <span>{{ "template.cart.continue_shopping" | translate }}</span>
      </a>
    </div>
    <div class="col-sm-12">
      <div class="form-group">
        <button
          class="button-pay btn-secundary cursor-not-allowed red ch-btn-checkout"
          (click)="onClickCheckout()"
        >
          {{ "template.payment.pay" | translate }}
        </button>
      </div>
    </div>
  </div>
}
