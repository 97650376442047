import { NgClass } from '@angular/common';
import { Component, inject, input, output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CustomCurrencyPipe } from '../../pipes/custom-currency.pipe';
import { CartService } from '../../services/cart.service';
import { LanguageService } from '../../services/language.service';
import { NavigationService } from '../../services/navigation.service';
import { OrderDiscountsAmountsComponent } from './components/order-discounts-amounts/order-discounts-amounts.component';
import { OrderDiscountsApplyComponent } from './components/order-discounts-apply/order-discounts-apply.component';
import { OrderDiscountsConceptsComponent } from './components/order-discounts-concepts/order-discounts-concepts.component';
import { OrderProductsSummaryComponent } from './components/order-products-summary/order-products-summary.component';

@Component({
  selector: 'hola-purchase-summary',
  standalone: true,
  imports: [
    OrderDiscountsAmountsComponent,
    OrderDiscountsConceptsComponent,
    OrderDiscountsApplyComponent,
    OrderProductsSummaryComponent,
    NgClass,
    TranslateModule,
    RouterModule,
    CustomCurrencyPipe,
  ],
  templateUrl: './purchase-summary.component.html',
  styleUrl: './purchase-summary.component.scss',
})
export class PurchaseSummaryComponent {
  public showDiscountForm = input.required<boolean>();
  public showOrderSummary = input.required<boolean>();
  public showButtons = input.required<boolean>();
  public checkout = output();

  private readonly languageService = inject(LanguageService);
  private readonly cartService = inject(CartService);
  private readonly navigationService = inject(NavigationService);

  public homeUrl = this.navigationService.homeUrl;
  public locale = this.languageService.locale;
  public cartSummaryProductsGroups = this.cartService.cartSummaryProductsGroups;
  public orderActivePrice = this.cartService.orderActivePrice;
  public orderReducedPrice = this.cartService.orderReducedPrice;

  public onClickCheckout(): void {
    this.checkout.emit();
  }
}
