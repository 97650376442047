<section>
  <div class="full-width-top-banner detail-top-banner">
    <img
      class="ticket-shadow"
      alt="shadow"
      src="/assets/images/shared/header_shadow.png"
    />
    <div class="recover-title-container">
      <h2 class="title-recover">
        {{ "template.voucher_recover.title" | translate }}
      </h2>
    </div>
  </div>
  <!-- end full-width-banner -->
</section>

<!-- INIT CONTENT MAIN TEXT -->
<section class="recover_section">
  <div class="container-fluid home-products-container">
    <div class="row col-home">
      <div class="col-sm-8">
        <h2>{{ "template.voucher_recover.subtitle" | translate }}</h2>
        <h3>{{ "template.voucher_recover.highlight" | translate }}</h3>
        <p>{{ "template.voucher_recover.content" | translate }}</p>
      </div>
    </div>
    <div class="row col-home">
      <div class="col-sm-8">
        <!-- TODO: error/succes del formulario
                         <div class="messages-box">
                             {% if (app.session.flashbag.get('success') or (message == 'success')) %}
                             <p class="recover-success">{{ 'template.voucher_recover.success' | translate }}</p>
                             {% elseif (app.session.flashbag.get('error') or (message == 'error')) %}
                                 <p class="recover-error">{{ 'template.voucher_recover.error' | translate }}</p>
                             {% endif %}
                         </div>
                        -->
      </div>
    </div>

    <form [formGroup]="form" (ngSubmit)="recover()">
      <div class="row col-home">
        <div class="col-sm-8">
          <div class="input-box" style="padding-bottom: 0">
            <div
              class="form-group"
              [ngClass]="{
                'input-filled': f['email'].value !== '' || emailFocus
              }"
            >
              <label class="input-label-animate inp-lab" for="email"
                ><span class="input-label-content">{{
                  "template.voucher_recover.mail" | translate
                }}</span></label
              >
              <input
                (focus)="onFocus('email')"
                (blur)="onBlur('email')"
                id="email"
                type="text"
                formControlName="email"
                class="form-control input-field"
                [ngClass]="{
                  'is-invalid error': submitted && f['email'].errors,
                  valid: submitted && !f['email'].errors
                }"
              />
            </div>
          </div>
        </div>
        <div class="col-sm-8">
          <div class="input-check-box">
            <label class="label_read_and_accept">
              <input
                type="checkbox"
                formControlName="acceptTerms"
                class="form-check-input"
                [ngClass]="{
                  'is-invalid error': submitted && f['acceptTerms'].errors,
                  valid: submitted && !f['acceptTerms'].errors
                }"
              />
              <span class="border-check" tabindex="17">
                {{ "template.payment.read_and_accept_2" | translate }}
                <a
                  target="_blank"
                  class="color-secundary"
                  [routerLink]="termsAndConditionsUrl()"
                  >{{ "template.payment.terms_and_conditions" | translate }}</a
                >
              </span>
            </label>
          </div>
        </div>
        <div class="col-sm-8">
          <div class="input-box">
            <p class="label_read_and_accept">
              <span
                [innerHtml]="
                  'template.payment.information_data_protection' | translate
                "
              ></span
              >&nbsp;
              <!--TODO: Calcular href según idioma-->
              <a
                href="https://www.tmb.cat/en/personal-data-protection-policy#content-title-transport-users"
                target="_blank"
                class="color-secundary"
                >{{
                  "template.payment.information_data_protection_1" | translate
                }}</a
              >
              {{ "template.payment.information_data_activities" | translate }}
              <!--TODO: Calcular href según idioma-->
              <a
                href="https://www.tmb.cat/en/registration-personal-data-processing-activities#24"
                target="_blank"
                class="color-secundary"
                >{{
                  "template.payment.information_data_activities_1" | translate
                }}</a
              >.
            </p>
          </div>
        </div>
        <div class="col-sm-8">
          <div class="input-check-box pull-right" style="clear: both">
            <button
              type="submit"
              class="button-pay btn-secundary cursor-not-allowed red ch-btn-checkout"
            >
              {{ "template.contact.submit" | translate }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
