@if (configuration().title !== "") {
  <div class="moute-tabs-block">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-sm-7 col-moute">
          <h4 class="moute-title-tabs">{{ configuration().title }}</h4>
          @if (configuration().description !== "") {
            <p class="moute-description-tabs">
              {{ configuration().description }}
            </p>
          }
          @if (
            configuration().button_link !== "" &&
            configuration().button_text !== ""
          ) {
            <a [href]="configuration().button_link" class="moute-button-tabs">{{
              configuration().button_text
            }}</a>
          }
        </div>
        <div class="col-12 col-sm-4 offset-sm-1 col-moute">
          @if (configuration().links_title !== "") {
            <h4 class="moute-link-title-tabs">
              {{ configuration().links_title }}
            </h4>
            <ul class="moute-link-list-tabs">
              <li>
                <a [href]="configuration().link_1">{{
                  configuration().link_text_1
                }}</a>
              </li>
              <li>
                <a [href]="configuration().link_2">{{
                  configuration().link_text_2
                }}</a>
              </li>
              <li>
                <a [href]="configuration().link_3">{{
                  configuration().link_text_3
                }}</a>
              </li>
            </ul>
          }
        </div>
      </div>
    </div>
  </div>
}

<!--TODO: slider imagenes -->
@if (configuration().images.length > 0) {
  <div class="top-content">
    <div class="top-content-relative">
      <div class="slider-wrapper">
        <div
          class="slider-slide"
          [ngStyle]="{
            transform: 'translateX(-' + currentSlideIndex * slideWidth + '%)',
          }"
        >
          @for (image of configuration().images; track $index) {
            <div class="img-slider">
              <img
                [src]="image.url"
                [alt]="configuration().title"
                [ngClass]="{ active: true }"
              />
            </div>
          }
        </div>
      </div>
      <!-- Flechas de navegación -->
      <button
        class="slider-nav prev"
        (click)="prevSlide()"
        (keyup)="prevSlide()"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"
          ><b
            class="fa fa-chevron-left fa-chevron-left-extra"
            aria-hidden="true"
          ></b
        ></span>
      </button>
      <button
        class="slider-nav next"
        (click)="nextSlide()"
        (keyup)="nextSlide()"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"
          ><b
            class="fa fa-chevron-right fa-chevron-right-extra"
            aria-hidden="true"
          ></b
        ></span>
      </button>
    </div>
  </div>
}
