<div class="row ch-discount-import-title">
  <div class="col-sm-12">
    <h2>{{ "template.cart.discount.import" | translate }}</h2>
  </div>
</div>

@for (discountAmount of itemDiscountsAmounts(); track $index) {
  <div class="row row-discount-code code-valid ch-product-item">
    <div class="col-7 col-sm-7 col-xl-8 ch-discount-name">
      <h2>
        <!--
              TODO: Mirar como son los translates con parametros
            -->
        <span> {{ discountAmount?.name }} </span>*
      </h2>
    </div>
    <div class="col-5 col-sm-5 col-xl-4 ch-discount-amount">
      <h2>-{{ discountAmount.amount | customCurrency: locale() }}</h2>
    </div>
  </div>
  <div class="ch-discounts-border"></div>
}
