import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { EMPTY, expand, Observable, reduce } from 'rxjs';
import { environment } from '../../../environments/environment';
import { StrapiResponse } from '../models/strapi.interface';

@Injectable({
  providedIn: 'root',
})
export class StrapiService<T> {
  private httpClient = inject(HttpClient);

  private headersConfiguration = {
    Authorization: `Bearer ${environment.strapiToken}`,
  };

  public getAll(url: string): Observable<T[]> {
    return this.get(url).pipe(
      expand((response) =>
        response.meta.pagination.pageCount > response.meta.pagination.page
          ? this.get(url, response.meta.pagination.page + 1)
          : EMPTY,
      ),
      reduce((acc, current) => acc.concat(current.data), [] as T[]),
    );
  }

  public get(url: string, page = 1): Observable<StrapiResponse<T>> {
    const httpParams = new HttpParams().set('pagination[page]', page);
    return this.httpClient.get<StrapiResponse<T>>(url, {
      headers: this.headersConfiguration,
      params: httpParams,
    });
  }
}
