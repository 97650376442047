import { computed, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { combineLatest, Subject, switchMap, tap } from 'rxjs';
import { VerifiedOpinion } from '../models/verified-opinion.interface';
import { LanguageService } from './language.service';
import { StrapiVerifiedOpinionsService } from './strapi-verified-opinions.service';

interface VerifiedOpinionsState {
  verifiedOpinions: VerifiedOpinion[];
  updated: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class VerifiedOpinionsService {
  private strapiVerifiedOpinionsService = inject(StrapiVerifiedOpinionsService);
  private languageService = inject(LanguageService);

  private state = signal<VerifiedOpinionsState>({
    verifiedOpinions: [],
    updated: null,
  });

  private load$ = new Subject<void>();
  private changeLanguage$ = toObservable(this.languageService.language);

  public verifiedOpinions = computed(() => this.state().verifiedOpinions);
  public siteValorationUrl = computed(
    () => this.verifiedOpinions()[0]?.siteValorationUrl,
  );
  public siteValorationScript = computed(
    () => this.verifiedOpinions()[0]?.siteValorationScript,
  );
  public siteValorationCarrousel = computed(
    () => this.verifiedOpinions()[0]?.siteValorationCarrousel,
  );
  public productValorationScript = computed(
    () => this.verifiedOpinions()[0]?.productValorationScript,
  );

  constructor() {
    combineLatest([this.changeLanguage$, this.load$])
      .pipe(
        switchMap(([language]) =>
          this.strapiVerifiedOpinionsService.loadVerifiedOpinions(language),
        ),
        tap((response) => console.log('opiniones verificadas', response)),
        takeUntilDestroyed(),
      )
      .subscribe((verifiedOpinions) =>
        this.state.update((state) => ({
          ...state,
          verifiedOpinions,
          updated: new Date().toISOString(),
        })),
      );
  }

  public load(): void {
    if (!this.state().updated) {
      this.load$.next();
    }
  }
}
