@if (configuration().text) {
  <section class="link_cards">
    <div class="container-fluid home-products-container">
      <!-- top alert -->
      <div class="row">
        <div class="col-8 offset-2">
          <h1 class="link_cards_title">{{ configuration().title }}</h1>
          <span class="link_cards_text">{{ configuration().text }}</span>
        </div>

        <div class="col-sm-12 col-home">
          <div class="row">
            <!-- CARDS -->

            @for (num of numbers; track $index) {
              <div class="col-sm-4">
                <a href="{{ getVariable(num, 'link') }}">
                  <span class="card_img" [style.background]="getImage(num)">
                    <div class="link_card">
                      <div class="card_container">
                        <div class="card_title">
                          {{ getVariable(num, "title") }}
                        </div>
                        <div class="card_link">
                          {{ getVariable(num, "link_text") }}
                          <i class="fa fa-angle-right" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                  </span>
                </a>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="link_cards_mobile">
    <!-- top alert -->
    <div class="row">
      <div class="col-8 offset-2">
        <h1 class="link_cards_title">{{ configuration().title }}</h1>
        <span class="link_cards_text">{{ configuration().text }}</span>
      </div>
    </div>

    <!-- CARDS -->
    <!-- Top content -->
    <div class="top-content">
      <div class="container-fluid">
        <div
          id="carousel-link-cards"
          class="carousel slide"
          data-ride="carousel"
        >
          <div class="carousel-inner row w-100 mx-auto" role="listbox">
            @for (num of numbers; track $index) {
              <div class="carousel-item col-sm-6">
                <a href="{{ getVariable(num, 'link') }}">
                  <span class="card_img" [style.background]="getImage(num)">
                    <div class="link_card">
                      <div class="card_container">
                        <div class="card_title">
                          {{ getVariable(num, "title") }}
                        </div>
                        <div class="card_link">
                          {{ getVariable(num, "link_text") }}
                          <i class="fa fa-angle-right" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                  </span>
                </a>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </section>
}
