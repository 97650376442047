import { Injectable, Type } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Page } from '../../page/models/page.interface';
import { WidgetConfigurationApiResponse } from '../../page/models/widget-configuration-api-response.type';
import { WidgetConfiguration } from '../../page/models/widget-configuration.type';
import { WidgetImageResize } from '../../page/models/widget-image-resize.interface';
import { Widget } from '../../page/models/widget.interface';
import { widgetConfiguration } from '../../page/utils/widget.config';
import { AppLanguage } from '../enums/app-language.enum';
import { PageId } from '../enums/page-id.enum';
import { WidgetType } from '../enums/widget-id.enum';
import {
  StrapiWidgetData,
  StrapiWidgetPageData,
} from '../models/strapi.interface';
import { StrapiService } from './strapi.service';

@Injectable({
  providedIn: 'root',
})
export class StrapiWidgetPagesService extends StrapiService<StrapiWidgetPageData> {
  private pageApiUrl = (id: string) =>
    `${environment.strapiUrl}/widget-pages?filters[name]=${id}&populate[widgets][populate]=*`;

  public loadPage(id: PageId, locale = AppLanguage.EN): Observable<Page> {
    return this.getAll(`${this.pageApiUrl(id)}`).pipe(
      map((strapiWidgetPage) =>
        this.mapWidgetPage(strapiWidgetPage[0], locale),
      ),
    );
  }

  private mapWidgetPage(
    strapiWidgetPage: StrapiWidgetPageData,
    language: AppLanguage,
  ): Page {
    return {
      id: strapiWidgetPage.attributes.name as PageId,
      enabled: strapiWidgetPage.attributes.enabled,
      metaTitle: strapiWidgetPage.attributes.metaTitle,
      metaDescription: strapiWidgetPage.attributes.metaDescription,
      metaKeywords: strapiWidgetPage.attributes.metaKeywords,
      widgets: strapiWidgetPage.attributes.widgets.data.map(
        (strapiWidgetData) => this.createWidget(strapiWidgetData, language),
      ),
    };
  }

  private createWidget(
    strapiWidgetData: StrapiWidgetData,
    language: AppLanguage,
  ): Widget {
    const widgetType =
      strapiWidgetData.attributes.widgetType.data.attributes.name;
    const configuration = this.transformWidgetConfiguration(
      widgetType as WidgetType,
      strapiWidgetData.attributes.configuration,
      language,
    );
    return {
      name: strapiWidgetData.attributes.name,
      type: widgetType,
      configuration: configuration,
      active: strapiWidgetData.attributes.active,
      component: this.getWidgetData(widgetType as WidgetType)?.component,
    };
  }

  private transformWidgetConfiguration(
    type: WidgetType,
    configuration: WidgetConfigurationApiResponse,
    language: AppLanguage,
  ): WidgetConfiguration {
    const transform = this.getWidgetData(type)?.transform;
    const imageResize = this.getWidgetData(type)?.imageResize;
    return transform ? transform(configuration, language, imageResize) : null;
  }

  private getWidgetData(type: WidgetType) {
    return widgetConfiguration.filter((w) => w.type === type)[0] as {
      type: WidgetType;
      imageResize: WidgetImageResize;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      component: Type<any>;
      // eslint-disable-next-line @typescript-eslint/ban-types
      transform: Function;
    };
  }
}
