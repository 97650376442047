<!-- #PRODUCT CARD FULL-WIDTH BANNER -->
<section class="product_card">
  <div class="container-fluid productCard-body">
    <div class="row">
      @if (superProducts().length === 2) {
        <div
          class="col-md-12 col-lg-6 pc-discount"
          data-discount-label="Hasta 10%"
          [attr.data-discount]="
            superProducts()[0].percentageDiscount !== 0
              ? '-' + superProducts()[0].percentageDiscount * 100 + '%'
              : superProducts()[0].fixDiscount !== 0
                ? '-' +
                  (superProducts()[0].fixDiscount | customCurrency: locale())
                : null
          "
        >
          <div class="product_container">
            <div class="shadow-container"></div>
            <img
              class="img-bg"
              [src]="configuration().images[0].url"
              alt="{{ superProducts()[0].title }}"
            />
            <div class="product_card_info">
              <h2>{{ superProducts()[0].title }}</h2>
              <p>{{ superProducts()[0].shortDescription }}</p>
              <div class="pc_prices">
                <span class="pc_preciosd">
                  {{ superProducts()[0].activePrice }}
                </span>
                <span class="pc_preciocd">
                  {{ superProducts()[0].reducedPrice }}
                </span>
              </div>
              <a
                href="{{ superProducts()[0].routeCode }}"
                class="btn-secundary red product_card_event_buy"
                >{{ "template.product.buy" | translate }}</a
              >
            </div>
            <div style="clear: both"></div>
          </div>
        </div>
        <div
          class="col-md-12 col-lg-6 pc-discount"
          data-discount-label="Hasta 10%"
          [attr.data-discount]="
            superProducts()[1].percentageDiscount !== 0
              ? '-' + superProducts()[1].percentageDiscount * 100 + '%'
              : superProducts()[1].fixDiscount !== 0
                ? '-' +
                  (superProducts()[1].fixDiscount | customCurrency: locale())
                : null
          "
        >
          <div class="product_container">
            <div class="shadow-container"></div>
            <img
              class="img-bg"
              [src]="configuration().images[1].url"
              alt="{{ superProducts()[1].title }}"
            />
            <div class="product_card_info">
              <h2>{{ superProducts()[1].title }}</h2>
              <p>{{ superProducts()[1].shortDescription }}</p>
              <div class="pc_prices">
                <span class="pc_preciosd">
                  {{ superProducts()[1].activePrice }}
                </span>
                <span class="pc_preciocd">
                  {{ superProducts()[1].reducedPrice }}
                </span>
              </div>
              <a
                href="{{ superProducts()[1].routeCode }}"
                class="btn-secundary red product_card_event_buy"
                >{{ "template.product.buy" | translate }}</a
              >
            </div>
            <div style="clear: both"></div>
          </div>
        </div>
      } @else {
        <div
          class="col-12 pc-discount"
          data-discount-label="Hasta 10%"
          [attr.data-discount]="
            superProducts()[0].percentageDiscount !== 0
              ? '-' + superProducts()[0].percentageDiscount * 100 + '%'
              : superProducts()[0].fixDiscount !== 0
                ? '-' +
                  (superProducts()[0].fixDiscount | customCurrency: locale())
                : null
          "
        >
          <div class="product_container one_product">
            <div class="shadow-container"></div>
            <img
              class="img-bg img-sm"
              [src]="configuration().images[0].url"
              alt="{{ superProducts()[0].title }}"
            />
            <img
              class="img-bg"
              src="/assets/images/home-widget/5.jpg"
              alt="{{ superProducts()[0].title }}"
            />
            <div class="product_card_info">
              <h2>{{ superProducts()[0].title }}</h2>
              <p>{{ superProducts()[0].shortDescription }}</p>
              <div class="pc_prices">
                <span class="pc_preciosd">{{
                  superProducts()[0].activePrice
                }}</span>
                <span class="pc_preciocd">
                  {{ superProducts()[0].reducedPrice }}
                </span>
              </div>
              <!-- TODO Aplicar rutas correctamente -->
              <a
                href="{{ superProducts()[0].routeCode }}"
                class="btn-secundary red product_card_event_buy"
                >{{ "template.product.buy" | translate }}</a
              >
            </div>
            <div style="clear: both"></div>
          </div>
        </div>
      }
      <div style="clear: both"></div>
    </div>
  </div>
  <div style="clear: both"></div>
  <!--
  @for (product of configuration().productCodes; track $index) {
    <h1>{{ product }}</h1>
  }
--></section>
