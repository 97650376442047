<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->

<section>
  <div class="checkout-top-banner">
    <div class="checkout-top-banner-container">
      <a class="navbar-brand" [routerLink]="homeUrl()"
        ><img
          src="/assets/images/shared/checkout_logo.png"
          alt=""
          class="img-producto img-fluid"
      /></a>
      <h1>{{ "template.cart.header.title" | translate }}</h1>
    </div>
  </div>
</section>

<section>
  <div class="checkout-breadcrumb">
    <a class="navbar-brand" [routerLink]="cartUrl()"
      ><i class="fa fa-caret-left"></i>
      {{ "template.cart.header.step1" | translate }}</a
    >
  </div>
</section>

<section>
  <div class="checkout-steps">
    <div>
      <h1 class="active">
        <ng-container>1</ng-container>
        <span class="subtext">
          {{ "template.cart.header.step1" | translate }}
        </span>
        <i class="step-icon fa fa-ticket"></i>
      </h1>
    </div>
    <div class="separator active"></div>
    <div>
      <h1 class="active">
        <ng-container>2</ng-container>
        <span class="subtext"
          >{{ "template.cart.header.step2" | translate }}
        </span>
        <i class="step-icon fa-solid fa-pencil"></i>
      </h1>
    </div>
    <div class="separator active"></div>
    <div>
      <h1>
        <ng-container>3</ng-container>
        <span class="subtext">
          {{ "template.cart.header.step3" | translate }}
        </span>
        <i class="step-icon fa fa-check"></i>
      </h1>
    </div>
  </div>
  <div style="clear: both"></div>
</section>

<section>
  <div class="checkout-title">
    <h1>{{ "template.cart.header.step2" | translate }}</h1>
  </div>
</section>

@if (hppData(); as hppData) {
  <section class="section-checkout-container">
    <hola-redsys-iframe [hppData]="hppData"></hola-redsys-iframe>
  </section>
} @else {
  <section class="section-checkout-container">
    <div class="container-fluid checkout-container">
      <div class="row">
        <div class="col-sm-12">
          <div class="row ch-headers">
            <div class="col-sm-9">
              <h2>{{ "template.payment.contact_email" | translate }}</h2>
            </div>
            <div class="col-sm-3 ch-mh">
              <h2>{{ "template.cart.header.your_purchase" | translate }}</h2>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-9">
            <form [formGroup]="form">
              <div class="row">
                <div class="col-md-9">
                  <section class="checkout-section">
                    <div class="row mrb30">
                      <div class="col-sm-12">
                        <div class="input-box">
                          <div
                            class="form-group"
                            [ngClass]="{
                              'input-filled':
                                f['name'].value !== '' || nameFocus,
                            }"
                          >
                            <label
                              class="input-label-animate inp-lab"
                              for="nombre"
                              ><span class="input-label-content"
                                >Name</span
                              ></label
                            >
                            <input
                              (focus)="onFocus('name')"
                              (blur)="onBlur('name')"
                              id="nombre"
                              type="text"
                              formControlName="name"
                              class="form-control input-field"
                              [ngClass]="{
                                'is-invalid error':
                                  submitted && f['name'].errors,
                                valid: submitted && !f['name'].errors,
                              }"
                            />
                            @if (submitted && f["name"].errors) {
                              <div>
                                @if (f["name"].errors["required"]) {
                                  <div>Name is required</div>
                                }
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="input-box">
                          <div
                            class="form-group"
                            [ngClass]="{
                              'input-filled':
                                f['surname'].value !== '' || surnameFocus,
                            }"
                          >
                            <label
                              class="input-label-animate inp-lab"
                              for="surname"
                              ><span class="input-label-content"
                                >Surname</span
                              ></label
                            >
                            <input
                              id="surname"
                              (focus)="onFocus('surname')"
                              (blur)="onBlur('surname')"
                              type="text"
                              formControlName="surname"
                              class="form-control input-field"
                              [ngClass]="{
                                'is-invalid error':
                                  submitted && f['surname'].errors,
                                valid: submitted && !f['surname'].errors,
                              }"
                            />
                            @if (submitted && f["surname"].errors) {
                              <div>
                                @if (f["surname"].errors["required"]) {
                                  <div>Surname is required</div>
                                }
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="input-box">
                          <div
                            class="form-group"
                            [ngClass]="{
                              'input-filled':
                                f['email'].value !== '' || email1Focus,
                            }"
                          >
                            <label
                              class="input-label-animate inp-lab"
                              for="email"
                              ><span class="input-label-content"
                                >Email</span
                              ></label
                            >
                            <input
                              id="email"
                              (focus)="onFocus('email1')"
                              (blur)="onBlur('email1')"
                              type="text"
                              formControlName="email"
                              class="form-control input-field"
                              [ngClass]="{
                                'is-invalid error':
                                  submitted && f['email'].errors,
                                valid: submitted && !f['email'].errors,
                              }"
                            />
                            @if (submitted && f["email"].errors) {
                              <div>
                                @if (f["email"].errors["required"]) {
                                  <div>Email is required</div>
                                }
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12">
                        <div class="input-box">
                          <div
                            class="form-group"
                            [ngClass]="{
                              'input-filled':
                                f['confirmEmail'].value !== '' || email2Focus,
                            }"
                          >
                            <label
                              class="input-label-animate inp-lab"
                              for="confirmEmail"
                              ><span class="input-label-content"
                                >Confirm Email</span
                              ></label
                            >
                            <input
                              id="confirmEmail"
                              (focus)="onFocus('email2')"
                              (blur)="onBlur('email2')"
                              type="text"
                              formControlName="confirmEmail"
                              class="form-control input-field"
                              [ngClass]="{
                                'is-invalid error':
                                  submitted && f['confirmEmail'].errors,
                                valid: submitted && !f['confirmEmail'].errors,
                              }"
                            />
                            @if (submitted && f["confirmEmail"].errors) {
                              <div>
                                @if (f["confirmEmail"].errors["required"]) {
                                  <div>Email is required</div>
                                }
                                @if (f["confirmEmail"].errors["matching"]) {
                                  <div>Confirm Email does not match</div>
                                }
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12">
                        <div class="input-check-box">
                          <label>
                            <input
                              type="checkbox"
                              formControlName="requestInvoice"
                              class="form-check-input"
                            />
                            <span class="border-check">{{
                              "template.payment.recieve_bill" | translate
                            }}</span>
                          </label>
                        </div>
                      </div>
                    </div>

                    @if (f["requestInvoice"].value) {
                      <div formGroupName="invoiceRequested">
                        <div class="col-sm-12">
                          <div class="input-box">
                            <div
                              class="form-group"
                              [ngClass]="{
                                'input-filled':
                                  invoiceForm['customerType'].value !== '' ||
                                  customerTypeFocus,
                              }"
                            >
                              <label
                                tabindex=""
                                class="input-label-animate inp-lab"
                                for="customerType"
                                (click)="openSelect('customerType')"
                                (keydown)="openSelect('customerType')"
                                ><span class="input-label-content">
                                  {{
                                    "template.payment.customer_type" | translate
                                  }}
                                </span></label
                              >
                              <select
                                #customerType
                                id="customerType"
                                (focus)="onFocus('customerType')"
                                (blur)="onBlur('customerType')"
                                type="text"
                                formControlName="customerType"
                                class="form-control input-field"
                                [ngClass]="{
                                  'is-invalid error':
                                    submitted &&
                                    invoiceForm['customerType'].errors,
                                  valid:
                                    submitted &&
                                    !invoiceForm['customerType'].errors,
                                }"
                              >
                                @for (type of customerTypes(); track $index) {
                                  <option [ngValue]="type">
                                    {{
                                      "template.payment.customer_type_" +
                                        type.toLowerCase() | translate
                                    }}
                                  </option>
                                }
                              </select>
                              @if (
                                submitted && invoiceForm["customerType"].errors
                              ) {
                                <div>
                                  @if (
                                    invoiceForm["customerType"].errors[
                                      "required"
                                    ]
                                  ) {
                                    <div>CustomerType is required</div>
                                  }
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <div class="input-box">
                            <div
                              class="form-group"
                              [ngClass]="{
                                'input-filled':
                                  invoiceForm['invoiceName'].value !== '' ||
                                  invoiceNameFocus,
                              }"
                            >
                              <label
                                class="input-label-animate inp-lab"
                                for="invoiceName"
                                ><span class="input-label-content"
                                  >InvoiceName</span
                                ></label
                              >
                              <input
                                id="invoiceName"
                                (focus)="onFocus('invoiceName')"
                                (blur)="onBlur('invoiceName')"
                                type="text"
                                formControlName="invoiceName"
                                class="form-control input-field"
                                [ngClass]="{
                                  'is-invalid error':
                                    submitted &&
                                    invoiceForm['invoiceName'].errors,
                                  valid:
                                    submitted &&
                                    !invoiceForm['invoiceName'].errors,
                                }"
                              />
                              @if (
                                submitted && invoiceForm["invoiceName"].errors
                              ) {
                                <div>
                                  @if (
                                    invoiceForm["invoiceName"].errors[
                                      "required"
                                    ]
                                  ) {
                                    <div>InvoiceName is required</div>
                                  }
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <div class="input-box">
                            <div
                              class="form-group"
                              [ngClass]="{
                                'input-filled':
                                  invoiceForm['country'].value !== '' ||
                                  countryFocus,
                              }"
                            >
                              <label
                                class="input-label-animate inp-lab"
                                for="country"
                                ><span class="input-label-content"
                                  >Country</span
                                ></label
                              >
                              <select
                                id="country"
                                (focus)="onFocus('country')"
                                (blur)="onBlur('country')"
                                type="text"
                                formControlName="country"
                                class="form-control input-field"
                                [ngClass]="{
                                  'is-invalid error':
                                    submitted && invoiceForm['country'].errors,
                                  valid:
                                    submitted && !invoiceForm['country'].errors,
                                }"
                              >
                                @for (country of countries(); track $index) {
                                  <option [ngValue]="country">
                                    {{ country.name }}
                                  </option>
                                }
                              </select>
                              @if (submitted && invoiceForm["country"].errors) {
                                <div>
                                  @if (
                                    invoiceForm["country"].errors["required"]
                                  ) {
                                    <div>Country is required</div>
                                  }
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <div class="input-box">
                            <div
                              class="form-group"
                              [ngClass]="{
                                'input-filled':
                                  invoiceForm['city'].value !== '' || cityFocus,
                              }"
                            >
                              <label
                                class="input-label-animate inp-lab"
                                for="city"
                                ><span class="input-label-content"
                                  >city</span
                                ></label
                              >
                              <input
                                id="city"
                                (focus)="onFocus('city')"
                                (blur)="onBlur('city')"
                                type="text"
                                formControlName="city"
                                class="form-control input-field"
                                [ngClass]="{
                                  'is-invalid error':
                                    submitted && invoiceForm['city'].errors,
                                  valid:
                                    submitted && !invoiceForm['city'].errors,
                                }"
                              />
                              @if (submitted && invoiceForm["city"].errors) {
                                <div>
                                  @if (invoiceForm["city"].errors["required"]) {
                                    <div>city is required</div>
                                  }
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <div class="input-box">
                            <div
                              class="form-group"
                              [ngClass]="{
                                'input-filled':
                                  invoiceForm['province'].value !== '' ||
                                  cityFocus,
                              }"
                            >
                              <label
                                class="input-label-animate inp-lab"
                                for="province"
                              >
                                <span class="input-label-content">
                                  province
                                </span>
                              </label>
                              <input
                                id="province"
                                (focus)="onFocus('province')"
                                (blur)="onBlur('province')"
                                type="text"
                                formControlName="province"
                                class="form-control input-field"
                                [ngClass]="{
                                  'is-invalid error':
                                    submitted && invoiceForm['province'].errors,
                                  valid:
                                    submitted &&
                                    !invoiceForm['province'].errors,
                                }"
                              />
                              @if (
                                submitted && invoiceForm["province"].errors
                              ) {
                                <div>
                                  @if (
                                    invoiceForm["province"].errors["required"]
                                  ) {
                                    <div>province is required</div>
                                  }
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-sm-12">
                          <div class="input-box">
                            <div class="form-group">
                              <label
                                class="input-label-animate inp-lab"
                                for="countryCode"
                                ><span class="input-label-content"
                                  >countryCode</span
                                ></label
                              >
                              <input
                                id="countryCode"
                                type="text"
                                formControlName="countryCode"
                                class="form-control input-field"
                                readonly
                              />
                            </div>
                          </div>
                        </div> -->
                        <div class="col-sm-12">
                          <div class="col-sm-12">
                            <div class="input-box">
                              <div
                                class="form-group"
                                [ngClass]="{
                                  'input-filled':
                                    invoiceForm['documentType'].value !== '' ||
                                    countryFocus,
                                }"
                              >
                                <label
                                  class="input-label-animate inp-lab"
                                  for="documentType"
                                  ><span class="input-label-content"
                                    >DocumentType</span
                                  ></label
                                >
                                <select
                                  id="documentType"
                                  (focus)="onFocus('documentType')"
                                  (blur)="onBlur('documentType')"
                                  type="text"
                                  formControlName="documentType"
                                  class="form-control input-field"
                                  [ngClass]="{
                                    'is-invalid error':
                                      submitted &&
                                      invoiceForm['documentType'].errors,
                                    valid:
                                      submitted &&
                                      !invoiceForm['documentType'].errors,
                                  }"
                                >
                                  @for (type of documentTypes(); track $index) {
                                    <option [ngValue]="type.value">
                                      {{ type.key | translate }}
                                    </option>
                                  }
                                </select>
                                @if (
                                  submitted &&
                                  invoiceForm["documentType"].errors
                                ) {
                                  <div>
                                    @if (
                                      invoiceForm["documentType"].errors[
                                        "required"
                                      ]
                                    ) {
                                      <div>Document Type is required</div>
                                    }
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <div class="input-box">
                            <div
                              class="form-group"
                              [ngClass]="{
                                'input-filled':
                                  invoiceForm['vatNumber'].value !== '' ||
                                  vatNumberFocus,
                              }"
                            >
                              <label
                                class="input-label-animate inp-lab"
                                for="vatNumber"
                                ><span class="input-label-content"
                                  >vatNumber</span
                                ></label
                              >
                              <input
                                id="vatNumber"
                                (focus)="onFocus('vatNumber')"
                                (blur)="onBlur('vatNumber')"
                                type="text"
                                formControlName="vatNumber"
                                class="form-control input-field"
                                [ngClass]="{
                                  'is-invalid error':
                                    submitted &&
                                    invoiceForm['vatNumber'].errors,
                                  valid:
                                    submitted &&
                                    !invoiceForm['vatNumber'].errors,
                                }"
                              />
                              @if (
                                submitted && invoiceForm["vatNumber"].errors
                              ) {
                                <div>
                                  @if (
                                    invoiceForm["vatNumber"].errors["required"]
                                  ) {
                                    <div>vatNumber is required</div>
                                  }
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <div class="input-box">
                            <div
                              class="form-group"
                              [ngClass]="{
                                'input-filled':
                                  invoiceForm['address'].value !== '' ||
                                  addressFocus,
                              }"
                            >
                              <label
                                class="input-label-animate inp-lab"
                                for="address"
                                ><span class="input-label-content"
                                  >address</span
                                ></label
                              >
                              <input
                                id="address"
                                (focus)="onFocus('address')"
                                (blur)="onBlur('address')"
                                type="text"
                                formControlName="address"
                                class="form-control input-field"
                                [ngClass]="{
                                  'is-invalid error':
                                    submitted && invoiceForm['address'].errors,
                                  valid:
                                    submitted && !invoiceForm['address'].errors,
                                }"
                              />
                              @if (submitted && invoiceForm["address"].errors) {
                                <div>
                                  @if (
                                    invoiceForm["address"].errors["required"]
                                  ) {
                                    <div>address is required</div>
                                  }
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <div class="input-box">
                            <div
                              class="form-group"
                              [ngClass]="{
                                'input-filled':
                                  invoiceForm['addressNumber'].value !== '' ||
                                  addressNumberFocus,
                              }"
                            >
                              <label
                                class="input-label-animate inp-lab"
                                for="addressNumber"
                                ><span class="input-label-content"
                                  >addressNumber</span
                                ></label
                              >
                              <input
                                id="addressNumber"
                                (focus)="onFocus('addressNumber')"
                                (blur)="onBlur('addressNumber')"
                                type="text"
                                formControlName="addressNumber"
                                class="form-control input-field"
                                [ngClass]="{
                                  'is-invalid error':
                                    submitted &&
                                    invoiceForm['addressNumber'].errors,
                                  valid:
                                    submitted &&
                                    !invoiceForm['addressNumber'].errors,
                                }"
                              />
                              @if (
                                submitted && invoiceForm["addressNumber"].errors
                              ) {
                                <div>
                                  @if (
                                    invoiceForm["addressNumber"].errors[
                                      "required"
                                    ]
                                  ) {
                                    <div>addressNumber is required</div>
                                  }
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <div class="input-box">
                            <div
                              class="form-group"
                              [ngClass]="{
                                'input-filled':
                                  invoiceForm['addressFloor'].value !== '' ||
                                  addressFloorFocus,
                              }"
                            >
                              <label
                                class="input-label-animate inp-lab"
                                for="addressFloor"
                                ><span class="input-label-content"
                                  >addressFloor</span
                                ></label
                              >
                              <input
                                id="addressFloor"
                                (focus)="onFocus('addressFloor')"
                                (blur)="onBlur('addressFloor')"
                                type="text"
                                formControlName="addressFloor"
                                class="form-control input-field"
                                [ngClass]="{
                                  'is-invalid error':
                                    submitted &&
                                    invoiceForm['addressFloor'].errors,
                                  valid:
                                    submitted &&
                                    !invoiceForm['addressFloor'].errors,
                                }"
                              />
                              @if (
                                submitted && invoiceForm["addressFloor"].errors
                              ) {
                                <div>
                                  @if (
                                    invoiceForm["addressFloor"].errors[
                                      "required"
                                    ]
                                  ) {
                                    <div>addressFloor is required</div>
                                  }
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <div class="input-box">
                            <div
                              class="form-group"
                              [ngClass]="{
                                'input-filled':
                                  invoiceForm['postalCode'].value !== '' ||
                                  postalCodeFocus,
                              }"
                            >
                              <label
                                class="input-label-animate inp-lab"
                                for="postalCode"
                                ><span class="input-label-content"
                                  >postalCode</span
                                ></label
                              >
                              <input
                                id="postalCode"
                                (focus)="onFocus('postalCode')"
                                (blur)="onBlur('postalCode')"
                                type="text"
                                formControlName="postalCode"
                                class="form-control input-field"
                                [ngClass]="{
                                  'is-invalid error':
                                    submitted &&
                                    invoiceForm['postalCode'].errors,
                                  valid:
                                    submitted &&
                                    !invoiceForm['postalCode'].errors,
                                }"
                              />
                              @if (
                                submitted && invoiceForm["postalCode"].errors
                              ) {
                                <div>
                                  @if (
                                    invoiceForm["postalCode"].errors["required"]
                                  ) {
                                    <div>postalCode is required</div>
                                  }
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <div class="input-box">
                            <div
                              class="form-group"
                              [ngClass]="{
                                'input-filled':
                                  invoiceForm['contactEmail'].value !== '' ||
                                  contactEmailFocus,
                              }"
                            >
                              <label
                                class="input-label-animate inp-lab"
                                for="contactEmail"
                                ><span class="input-label-content"
                                  >contactEmail</span
                                ></label
                              >
                              <input
                                id="contactEmail"
                                (focus)="onFocus('contactEmail')"
                                (blur)="onBlur('contactEmail')"
                                type="text"
                                formControlName="contactEmail"
                                class="form-control input-field"
                                [ngClass]="{
                                  'is-invalid error':
                                    submitted &&
                                    invoiceForm['contactEmail'].errors,
                                  valid:
                                    submitted &&
                                    !invoiceForm['contactEmail'].errors,
                                }"
                              />
                              @if (
                                submitted && invoiceForm["contactEmail"].errors
                              ) {
                                <div>
                                  @if (
                                    invoiceForm["contactEmail"].errors[
                                      "required"
                                    ]
                                  ) {
                                    <div>contactEmail is required</div>
                                  }
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <div class="input-box">
                            <div
                              class="form-group"
                              [ngClass]="{
                                'input-filled':
                                  invoiceForm['contactInformation'].value !==
                                    '' || contactInformationFocus,
                              }"
                            >
                              <label
                                class="input-label-animate inp-lab"
                                for="contactInformation"
                                ><span class="input-label-content"
                                  >contactInformation</span
                                ></label
                              >
                              <input
                                id="contactInformation"
                                (focus)="onFocus('contactInformation')"
                                (blur)="onBlur('contactInformation')"
                                type="text"
                                formControlName="contactInformation"
                                class="form-control input-field"
                                [ngClass]="{
                                  'is-invalid error':
                                    submitted &&
                                    invoiceForm['contactInformation'].errors,
                                  valid:
                                    submitted &&
                                    !invoiceForm['contactInformation'].errors,
                                }"
                              />
                              @if (
                                submitted &&
                                invoiceForm["contactInformation"].errors
                              ) {
                                <div>
                                  @if (
                                    invoiceForm["contactInformation"].errors[
                                      "required"
                                    ]
                                  ) {
                                    <div>contactInformation is required</div>
                                  }
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    <div class="row">
                      <div class="col-md-12">
                        <div class="input-box">
                          <p>
                            <label class="label_read_and_accept">
                              <span
                                [innerHtml]="
                                  'template.payment.information_data_protection'
                                    | translate
                                "
                              ></span
                              >&nbsp;
                              <!--TODO: Calcular href según idioma-->
                              <a
                                href="https://www.tmb.cat/en/personal-data-protection-policy#content-title-transport-users"
                                target="_blank"
                                class="color-secundary"
                                >{{
                                  "template.payment.information_data_protection_1"
                                    | translate
                                }}</a
                              >
                              {{
                                "template.payment.information_data_activities"
                                  | translate
                              }}
                              <!--TODO: Calcular href según idioma-->
                              <a
                                href="https://www.tmb.cat/en/registration-personal-data-processing-activities#24"
                                target="_blank"
                                class="color-secundary"
                              >
                                {{
                                  "template.payment.information_data_activities_1"
                                    | translate
                                }} </a
                              >.
                            </label>
                          </p>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="input-check-box">
                          <label class="label_read_and_accept">
                            <input
                              type="checkbox"
                              formControlName="acceptTerms"
                              class="form-check-input"
                              [ngClass]="{
                                'is-invalid error':
                                  submitted && f['acceptTerms'].errors,
                                valid: submitted && !f['acceptTerms'].errors,
                              }"
                            />
                            <span class="border-check" tabindex="17">
                              {{
                                "template.payment.read_and_accept_2" | translate
                              }}
                              <a
                                target="_blank"
                                class="color-secundary"
                                [routerLink]="termsAndConditionsUrl()"
                                >{{
                                  "template.payment.terms_and_conditions"
                                    | translate
                                }}</a
                              >
                              @if (submitted && f["acceptTerms"].errors) {
                                <div class="invalid-feedback">
                                  Accept Terms is required
                                </div>
                              }
                            </span>
                          </label>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="input-check-box">
                          <label class="label_read_and_accept">
                            <input
                              type="checkbox"
                              formControlName="extraInformation"
                              class="form-check-input"
                            />
                            <span class="border-check" tabindex="18">
                              {{
                                "template.payment.extra_information" | translate
                              }}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="pull-left">
                          <label class="label_read_and_accept country_info">
                            {{ "template.payment.country_info" | translate }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </form>
          </div>

          <div class="col-md-3 checkout-discounts">
            <hola-purchase-summary
              [showDiscountForm]="false"
              [showOrderSummary]="true"
              [showButtons]="true"
              (checkout)="pay()"
            ></hola-purchase-summary>
          </div>
        </div>
      </div>
    </div>
  </section>
}
