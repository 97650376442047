import { AbstractControl, ValidatorFn } from '@angular/forms';

export function documentNumberValidator(documentType: {
  (): AbstractControl;
}): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!(control && control.value)) {
      return null;
    }

    // Aplica validador sólo si es NIF/DNI/Libro de familia
    const documentTypeControl = documentType();
    if (
      !(
        documentTypeControl &&
        (documentTypeControl.value === 'NIF' ||
          documentTypeControl.value === 'DNI' ||
          documentTypeControl.value === 'NIE' ||
          documentTypeControl.value === 'CIF' ||
          documentTypeControl.value === 'FAMILY_BOOK')
      )
    ) {
      return null;
    }

    // Comprueba formato NIF
    if (
      documentTypeControl.value === 'NIF' ||
      documentTypeControl.value === 'DNI'
    ) {
      //
      const nifRegExp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
      if (
        (documentTypeControl.value === 'NIF' ||
          documentTypeControl.value === 'DNI') &&
        !new RegExp(nifRegExp).test(control.value)
      ) {
        return { invalid: true };
      }

      // Comprueba validez
      const str = control.value.toString().toUpperCase();
      const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
      const letter = str.substr(-1);
      const charIndex = parseInt(str.substr(0, 8)) % 23;
      if (validChars.charAt(charIndex) !== letter) {
        return { invalid: true };
      }
    }

    // Comprueba formato NIE
    if (documentTypeControl.value === 'NIE') {
      const nieRegExp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;

      if (
        documentTypeControl.value === 'NIE' &&
        !new RegExp(nieRegExp).test(control.value)
      ) {
        return { invalid: true };
      }

      // Comprueba validez
      const str = control.value
        .toString()
        .toUpperCase()
        .replace(/^[X]/, '0')
        .replace(/^[Y]/, '1')
        .replace(/^[Z]/, '2');

      const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
      const letter = str.substr(-1);
      const charIndex = parseInt(str.substr(0, 8)) % 23;
      if (validChars.charAt(charIndex) !== letter) {
        return { invalid: true };
      }
    }

    // Comprueba libro de familia
    if (documentTypeControl.value === 'FAMILY_BOOK') {
      const familyBookRegExp = /^[0-9]{7}[-][0-9]{2}$/i;
      if (
        documentTypeControl.value === 'FAMILY_BOOK' &&
        !new RegExp(familyBookRegExp).test(control.value)
      ) {
        return { invalid: true };
      }
    }

    // Comprueba formato CIF
    if (documentTypeControl.value === 'CIF') {
      // Expresión regular del CIF.
      const cifRegExp = /^[ABCDEFGHJNPQRSUVW]{1}[0-9]{7}[0-9A-J]$/i;
      if (
        documentTypeControl.value === 'CIF' &&
        !new RegExp(cifRegExp).test(control.value)
      ) {
        return { invalid: true };
      }
    }

    return null;
  };
}
