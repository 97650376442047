<section>
  <div class="full-width-top-banner detail-top-banner">
    <img
      class="ticket-shadow"
      src="/assets/images/shared/header_shadow.png"
      alt=""
    />
    <div class="legal-title-container">
      <h2 class="legal-title">
        {{ "template.legal_notice.title" | translate }}
      </h2>
    </div>
  </div>
</section>

<!-- INIT CONTENT MAIN TEXT -->
<section class="terms_and_conditions_section legal_section">
  <div class="container-fluid home-products-container">
    <div class="row col-home">
      <div class="col-sm-8">
        {{ "template.legal_notice.content" | translate }}
      </div>
    </div>
  </div>
</section>
