<div class="accordeon-tabs-block">
  <h3 class="boxed-title-tabs">
    <a id="product-extra-information">{{ title }}</a>
  </h3>
  @for (tab of tabs; track tab; let key = $index) {
    <div class="accordeon-tab">
      <span class="route {{ tab.title_class }}">{{ tab.title }}</span>
      <!-- <span class="arrow down"></span> -->
      <span class="ic-anim-down-close pull-right"></span>
    </div>
    <!-- end accordeon-tab -->
    <div class="accordeon-content" id="{{ 'acc' + key }}">
      <div class="slider-accordeon-block">
        <ul class="slider-accordeon">
          @for (image of images; track $index) {
            <li>
              <img src="{{ image.url }}" alt="{{ title }}" />
            </li>
          }
        </ul>
      </div>
      <!-- end slider-accordeon-block -->
      @if (tab.description !== "") {
        <p>{{ tab.description }}</p>
      }
      @if (tab.title_link !== "" && tab.link !== "") {
        <a [href]="tab.link" class="download-file-accordion" target="_blank">
          <span class="ico pdf-white"></span>{{ tab.title_link }}
        </a>
      }
    </div>
    <!-- end accordeon-content #acc-1 -->
  }
</div>
<!-- end accordeon-tabs-block -->
