<div class="add-ticket-box-block detail-people-content row">
  <div class="col-5" style="padding: 0">
    <span class="span_attr" style="margin: 3px 0">{{ product().name }}</span>
  </div>
  <div class="col-3 current-price-desk">
    @if (activePrice() > reducedPrice()) {
      <div class="current-price">
        <span>
          {{ activePrice() | customCurrency: locale() }}
        </span>
      </div>
    }
    <div class="current-red-price">
      <span>
        {{ reducedPrice() | customCurrency: locale() }}
      </span>
    </div>
  </div>
  <div class="add-ticket-box col-4">
    <button
      (click)="remove()"
      [disabled]="quantity() === 0"
      class="btn-subtract pdt"
    >
      <i class="fa fa-minus" aria-hidden="true"></i>
    </button>
    <div class="content-copy">
      <span class="quantity"
        ><span class="span_qty">{{ quantity() }}</span>
      </span>
    </div>
    <button (click)="add()" class="btn-add pdt">
      <i class="fa fa-plus" aria-hidden="true"></i>
    </button>
  </div>
  <!-- end add-ticket-box -->
</div>
<!-- end add-ticket-box-block -->
