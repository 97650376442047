import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Signal, computed, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject, map, of, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiStatus } from '../../shared/enums/api-status.enum';
import { AppLanguage } from '../../shared/enums/app-language.enum';
import { PageId } from '../../shared/enums/page-id.enum';
import { StrapiWidgetPagesService } from '../../shared/services/strapi-widget-pages.service';
import { Page } from '../models/page.interface';

interface PageState {
  page: Page | null;
  error: HttpErrorResponse | null;
  status: ApiStatus;
}

@Injectable({
  providedIn: 'root',
})
export class PageService {
  private strapiWidgetPagesService = inject(StrapiWidgetPagesService);

  private state = signal<PageState>({
    page: null,
    error: null,
    status: ApiStatus.Loading,
  });

  private historyState = signal<PageId[]>([]);
  private stateCache: Map<PageId, Signal<PageState>> = new Map([]);

  page = computed(() => this.state().page);
  error = computed(() => this.state().error);
  status = computed(() => this.state().status);
  history = computed(() => this.historyState());

  private load$ = new Subject<{ id: PageId; lang: AppLanguage }>();
  private page$ = this.load$.pipe(
    switchMap((data) => {
      const pageFromCache =
        environment.pageCache && this.stateCache.get(data.id)?.().page;
      if (pageFromCache) {
        console.log('Configuración de página obtenida desde caché');
        return of({
          id: data.id,
          page: pageFromCache,
          fromCache: true,
        });
      } else {
        console.log('Configuración de página obtenida desde la API');
        return this.strapiWidgetPagesService.loadPage(data.id, data.lang).pipe(
          map((pageFromApi) => ({
            id: data.id,
            page: pageFromApi,
            fromCache: false,
          })),
        );
      }
    }),
  );

  private addToHistory$ = new Subject<PageId>();

  constructor() {
    this.page$.pipe(takeUntilDestroyed()).subscribe({
      next: (data) => {
        this.state.update((state) => ({
          ...state,
          page: data.page,
          status: ApiStatus.Success,
        }));
        if (!data.fromCache) {
          this.stateCache.set(data.id, this.state);
        }
        return this.state;
      },
      error: (error) =>
        this.state.update((state) => ({
          ...state,
          error,
          status: ApiStatus.Error,
        })),
    });

    this.addToHistory$.pipe(takeUntilDestroyed()).subscribe((pageId) => {
      this.historyState.update((state) => [...state, pageId]);
    });
  }

  public loadPage(id: PageId, lang: AppLanguage) {
    this.load$.next({ id, lang });
  }

  public addToHistory(id: PageId) {
    if (id) {
      this.addToHistory$.next(id);
    }
  }
}
