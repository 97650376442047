import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, of, switchMap, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ProductReservationAvailabilityApiResponse } from '../models/product-reservation-availability.interface';
import {
  ProductReservationInfo,
  ProductReservationInfoApiResponse,
} from '../models/product-reservation-info.interface';
import { addMonths } from '../utils/date.utils';

@Injectable({
  providedIn: 'root',
})
export class ProductReservationApiService {
  private http = inject(HttpClient);

  private productReservationApiUrl = `${environment.clientApiUrl}/bookingService`;
  private availabilityApiUrl = `${environment.tmbcApiUrl}/bookingService`;
  private productReservationInfoApiUrl = (productCode: string) =>
    `${this.productReservationApiUrl}/product/${productCode}/reservationInfo`;

  private productReservationAvailabilityApiUrl = (
    code: string,
    route: string,
  ) => `${this.availabilityApiUrl}/${code}/route/${route}/availability`;

  private token!: string | null;

  loadInfo(
    productCode: string | null,
  ): Observable<ProductReservationInfoApiResponse> {
    if (!productCode) {
      return of({} as ProductReservationInfoApiResponse);
    }
    const httpParams = new HttpParams()
      .set('equipmentCode', environment.equipmentCode)
      .set('app_key', environment.appKey)
      .set('app_id', environment.appId);
    return this.http.get<ProductReservationInfoApiResponse>(
      this.productReservationInfoApiUrl(productCode),
      {
        params: httpParams,
      },
    );
  }

  // TODO refactorizar para eliminar la solicitud del token al recibir servicio nuevo
  loadAvailability(
    reservationInfo: ProductReservationInfo,
  ): Observable<ProductReservationAvailabilityApiResponse[]> {
    if (!reservationInfo.bookingServiceCode || !reservationInfo.routeCode) {
      return of([]);
    }
    const startDate = new Date();
    const endDate = addMonths(startDate, 3);
    const httpParams = new HttpParams()
      .set('startDate', startDate.toISOString())
      .set('endDate', endDate.toISOString());

    if (this.token) {
      return this.http.get<ProductReservationAvailabilityApiResponse[]>(
        this.productReservationAvailabilityApiUrl(
          reservationInfo.bookingServiceCode,
          reservationInfo.routeCode,
        ),
        {
          headers: { Authorization: `Bearer ${this.token}` },
          params: httpParams,
        },
      );
    } else {
      console.log('El token no existe');

      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      });
      const body = new HttpParams()
        .set('grant_type', 'client_credentials')
        .set('client_id', 'nic_ecommerce_no_residents_test')
        .set('client_secret', '86d37aca-8a2a-43e9-a8b1-f2f25bdeaff2');

      return this.http
        .post<{
          access_token: string;
          expires_in: number;
        }>(
          'https://auth.tmb.cat/auth/realms/internal/protocol/openid-connect/token',
          body,
          { headers },
        )
        .pipe(
          tap((response: { access_token: string; expires_in: number }) => {
            this.token = response['access_token'];
          }),
          switchMap(() => {
            const token = this.token;
            const headers = {
              Authorization: `Bearer ${token}`,
            };
            const startDate = new Date();
            const endDate = addMonths(startDate, 3);
            const httpParams = new HttpParams()
              .set('startDate', startDate.toISOString())
              .set('endDate', endDate.toISOString());

            return this.http.get<ProductReservationAvailabilityApiResponse[]>(
              this.productReservationAvailabilityApiUrl(
                reservationInfo.bookingServiceCode,
                reservationInfo.routeCode,
              ),
              { headers, params: httpParams },
            );
          }),
        );
    }
  }
}
