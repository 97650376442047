import { Injectable, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject, tap } from 'rxjs';
import { CartProduct } from '../models/cart-product.interface';
import { CartService } from './cart.service';

@Injectable({ providedIn: 'root' })
export class CartTempService {
  private cartService = inject(CartService);

  public products = signal<CartProduct[]>([]);

  private load$ = new Subject<void>();
  private add$ = new Subject<CartProduct>();
  private remove$ = new Subject<CartProduct>();
  private clear$ = new Subject<void>();

  constructor() {
    this.load$.subscribe(() => this.products.set([]));

    this.add$
      .pipe(
        takeUntilDestroyed(),
        tap((p) => console.log('Añadiendo producto al carrito temporal', p)),
      )
      .subscribe((product) =>
        this.products.update((state) =>
          [...state, product].sort((a, b) => +a.product.code - +b.product.code),
        ),
      );

    this.remove$
      .pipe(
        takeUntilDestroyed(),
        tap((p) => console.log('Eliminando producto del carrito temporal', p)),
      )
      .subscribe((product) =>
        this.products.update((state) => {
          const index = state.findIndex(
            (p) =>
              p.product.code === product.product.code &&
              p.reservationForm?.departTrip?.startDatetime ===
                product.reservationForm?.departTrip?.startDatetime &&
              p.reservationForm?.returnTrip?.startDatetime ===
                product.reservationForm?.returnTrip?.startDatetime &&
              p.reservationForm?.tagCode === product.reservationForm?.tagCode,
          );
          return state.slice(0, index).concat(state.slice(index + 1));
        }),
      );

    this.clear$
      .pipe(
        takeUntilDestroyed(),
        tap(() => console.log('Vaciando carrito temporal')),
      )
      .subscribe(() => this.products.set([]));
  }

  load(): void {
    this.load$.next();
  }
  add(product: CartProduct): void {
    this.add$.next(product);
  }
  remove(product: CartProduct): void {
    this.remove$.next(product);
  }
  clear(): void {
    this.clear$.next();
  }

  store(): void {
    this.cartService.store(this.products());
    this.clear();
  }
}
