@if (configuration().title !== "") {
  <section>
    <div class="full-width-top-banner detail-top-banner">
      <img
        class="ticket-shadow"
        src="/assets/images/shared/header_shadow.png"
        alt=""
      />
      <div class="faq-title-container">
        <h2 class="title-sitemap">{{ configuration().title }}</h2>
      </div>
    </div>
  </section>

  <!-- INIT CONTENT MAIN TEXT -->
  <section class="about_section">
    <div class="container-fluid home-products-container">
      <div class="row col-home">
        <div class="col-sm-12">
          <h2 class="subtitle-about">{{ configuration().title }}</h2>
        </div>
      </div>
      <div class="row col-home">
        <div
          class="col-sm-8 content-about"
          [innerHTML]="configuration().content"
        ></div>
      </div>
    </div>
  </section>
}
