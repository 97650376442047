import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
  inject,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';
import { CustomCurrencyPipe } from '../../../../pipes/custom-currency.pipe';
import { LanguageService } from '../../../../services/language.service';
import { VerifiedOpinionsService } from '../../../../services/verified-opinions.service';
import { GlobalReferenceService } from '../../../../services/window-reference.service';

@Component({
  selector: 'hola-opiniones-verificadas-store-valoration',
  standalone: true,
  imports: [TranslateModule, CustomCurrencyPipe],
  templateUrl: './opiniones-verificadas-store-valoration.component.html',
  styleUrl: './opiniones-verificadas-store-valoration.component.scss',
})
export class OpinionesVerificadasStoreValorationComponent
  implements AfterViewInit
{
  private readonly languageService = inject(LanguageService);
  private readonly verifiedOpinionsService = inject(VerifiedOpinionsService);
  private readonly globalReferenceService = inject(GlobalReferenceService);

  public locale = this.languageService.locale;
  /*
  private storeValorationUrl$ = toObservable(
    this.verifiedOpinionsService.siteValorationUrl,
  );
*/
  private storeValorationScript$ = toObservable(
    this.verifiedOpinionsService.siteValorationScript,
  );

  // Inicializar variables para los atributos
  public classAttr: string | undefined;
  public idAttr: string | undefined;
  public dataJsurlAttr: string | undefined;

  @ViewChild('opinionesContainer', { static: true })
  opinionesContainer!: ElementRef;

  constructor(
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: object,
    private cd: ChangeDetectorRef,
  ) {}

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.storeValorationScript$.pipe().subscribe((storeValorationScript) => {
        if (storeValorationScript) {
          // storeValorationScript => url <script></script>
          const scriptSrcMatch = storeValorationScript.match(
            /<script[^>]*src="([^"]+)"[^>]*>/,
          );
          const scriptSrc = scriptSrcMatch ? scriptSrcMatch[1] : '';
          // storeValorationScript => <meta>
          const metaMatch = storeValorationScript.match(/<meta[^>]*>/);
          const metaTag = metaMatch ? metaMatch[0] : '';

          // Utilizar una expresión regular para encontrar todos los atributos y sus valores
          const regex = /([\w-]+)=["']([^"']*)["']/g;
          let match;

          // Iterar sobre las coincidencias de la expresión regular
          while ((match = regex.exec(metaTag)) !== null) {
            switch (match[1]) {
              case 'class':
                this.classAttr = match[2];
                break;
              case 'id':
                this.idAttr = match[2];
                break;
              case 'data-jsurl':
                this.dataJsurlAttr = match[2];
                break;
              default:
                console.log(`Atributo ${match[1]} no manejado`);
            }
          }

          // anulamos el objecto global netreviewsWidget19 para que opiniones verificadas se lo encuentre undefined y así lo vuelva a crear
          // esto hace que funcione correctamente cuando navegamos de vuelta a la home.

          // se tendria que mejorar ya que ahora depende de un objecto nombrado que hemos visto en código de opiniones verificadas y puede cambiar

          this.globalReferenceService.window.netreviewsWidget19 = null;

          // Mostrar los valores de las variables
          console.log('class:', this.classAttr);
          console.log('id:', this.idAttr);
          console.log('data-jsurl:', this.dataJsurlAttr);

          console.log('Meta tag:', metaTag);
          console.log('Script src:', scriptSrc);

          const script1 = this.renderer.createElement('script');
          script1.src = scriptSrc;

          script1.async = true;
          this.renderer.appendChild(
            this.opinionesContainer.nativeElement,
            script1,
          );
        }
      });
    }
  }
}
