@if (this.reservationInfo() && this.availability().length > 0) {
  <form [formGroup]="reservationForm">
    @if (showTagSelect()) {
      <div class="row">
        <div class="col-12">
          <label class="detail-label" for="input-1">
            {{ "template.cart.select_language" | translate }}
          </label>
        </div>
        <div class="col-12">
          <select
            formControlName="tagCode"
            class="detail-input detail-select input-field booking-form"
            tabindex="5"
          >
            <option value="">
              {{ "template.product.booking.route_all" | translate }}
            </option>
            @for (label of labels(); track $index) {
              <option [ngValue]="label.code">
                {{ label.language?.name }}
              </option>
            }
          </select>
          <span id="product-text-places-left"></span>
        </div>
      </div>
    }
    <div class="row">
      <div class="col-12">
        <label class="detail-label" for="input-1">
          {{ "template.cart.select_booking_date" | translate }}
        </label>
      </div>
      <div class="col-12">
        <div id="calendar">
          <mat-form-field>
            <mat-label>DD/MM/YYYY</mat-label>
            <input
              matInput
              readonly
              [matDatepicker]="picker"
              [matDatepickerFilter]="availabilityFilter"
              class="datepicker detail-input detail-datepicker input-field booking-form"
              formControlName="date"
              (click)="picker.open()"
            />
            <mat-datepicker-toggle matIconSuffix [for]="picker"
              ><mat-icon matDatepickerToggleIcon
                ><img
                  src="/assets/images/shared/calendar.svg"
                  alt="Calendar" /></mat-icon
            ></mat-datepicker-toggle>
            <mat-datepicker
              #picker
              [dateClass]="dispoDateClass"
            ></mat-datepicker>
          </mat-form-field>
          <!--
          <select
            formControlName="date"
            class="datepicker detail-input detail-datepicker input-field booking-form"
          >
            @for (date of dates(); track $index) {
              <option [ngValue]="date">
                {{ date | date: "dd/MM/yyyy" }}
              </option>
            }
          </select>
          -->
        </div>
        <div id="calendar-progress" class="hidden">
          <span></span>
          <div class="">
            <div class="input-item">
              <div class="content-box"><div class="loader"></div></div>
            </div>
          </div>
        </div>
        <span id="product-text-places-left"></span>
      </div>
      @if (showCircularTripSelect()) {
        <div class="col-12 col-lg-6">
          <div>
            <label class="detail-label" for="input-1">
              {{ "template.cart.select_departure_time" | translate }}
            </label>
          </div>
          <div class="booking-departure-product departure">
            <select
              formControlName="departTrip"
              class="input-field booking-form detail-input detail-hour"
            >
              <option [ngValue]="null" disabled>--:--</option>
              @for (trip of circularTrips(); track $index) {
                <option [ngValue]="trip">
                  {{ trip.startDatetime | date: "HH:mm" }}
                  {{ trip.tagValue?.language?.name }}
                </option>
              }
            </select>
          </div>
        </div>
      } @else {
        <div class="col-12 col-lg-6">
          <div>
            <label class="detail-label" for="input-1">
              {{ "template.cart.select_departure_time" | translate }}
            </label>
          </div>
          <div class="booking-departure-product departure">
            <select
              formControlName="departTrip"
              class="input-field booking-form detail-input detail-hour"
            >
              <option [ngValue]="null" disabled>--:--</option>
              @for (trip of departTrips(); track $index) {
                <option [ngValue]="trip">
                  {{ trip.startDatetime | date: "HH:mm" }}
                  {{ trip.tagValue?.language?.name }}
                </option>
              }
            </select>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <div>
            <label class="detail-label" for="input-1">
              {{ "template.cart.select_return_time" | translate }}
            </label>
          </div>
          <div class="booking-departure-product">
            <select
              formControlName="returnTrip"
              class="input-field booking-form detail-input detail-hour"
            >
              <option [ngValue]="null" disabled>--:--</option>
              @for (trip of returnTrips(); track $index) {
                <option [ngValue]="trip">
                  {{ trip.startDatetime | date: "HH:mm" }}
                  {{ trip.tagValue?.language?.name }}
                </option>
              }
            </select>
          </div>
        </div>
      }
    </div>
  </form>
}
