@if (configuration().title) {
  <!-- Promo APP -->

  <section class="promo_app">
    <div class="container-fluid promo-app-container">
      <div class="col-home col-app-container">
        <div class="row">
          <div class="col-lg-5">
            <div class="col-sm-12">
              <h1 class="promo_app_title">{{ configuration().title }}</h1>
            </div>
            <div class="col-sm-12">
              <div class="promo_app_description">
                {{ configuration().text }}
              </div>
            </div>
            <div class="col-sm-12">
              @if (configuration().android_link) {
                <a href="{{ configuration().android_link }}" target="_blank"
                  ><img
                    alt="ANDROID"
                    src="/assets/images/promo-app-widget/google-play-badge.png"
                /></a>
              }
              @if (configuration().ios_link) {
                <a href="{{ configuration().ios_link }}" target="_blank"
                  ><img
                    alt="IOS"
                    src="/assets/images/promo-app-widget/download-on-the-app-store-apple-1.png"
                /></a>
              }
            </div>
            <div style="clear: both"></div>
          </div>
          <div class="col-lg-1"></div>
          <div class="col-lg-6" style="text-align: center; position: relative">
            <img
              class="promo-app-img-background"
              alt=""
              src="/assets/images/promo-app-widget/group.png"
            />
            <img
              class="promo-app-img-front"
              alt=""
              src="/assets/images/promo-app-widget/clay-mockup-3-x.png"
            />
            <div style="clear: both"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Promo APP -->
}
