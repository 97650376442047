import { Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BlogWidgetConfiguration } from './models/blog-widget-configuration.interface';

@Component({
  selector: 'hola-blog-widget',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './blog-widget.component.html',
  styleUrl: './blog-widget.component.scss',
})
export class BlogWidgetComponent {
  configuration = input.required<BlogWidgetConfiguration>();
}
