<!--TODO: revisar función para ocultar aviso, puede haber varios avisos y al ocultar uno solo debe ocultarse ese y no los demás-->
@if (isVisible() && configuration().title !== "") {
  <div class="onlydesk">
    <div class="detail-container container-disable-message">
      <div class="row col-detail">
        <div class="col-sm-12">
          <div class="disable-message" [ngClass]="configuration().type">
            <div class="col-2 col-sm-2 col-md-1">
              <div class="info-product-ico"></div>
            </div>
            <div class="col-10 col-sm-10 col-md-11">
              <div class="disable-message-title">
                {{ configuration().title }}
              </div>
              <div class="disable-message-text">
                {{ configuration().description }}
              </div>
              <button
                type="button"
                class="close closeAvis"
                data-dismiss="modal"
                aria-hidden="true"
                (click)="hide()"
              >
                &times;
              </button>
            </div>
            <div style="clear: both"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="onlymob">
    <div class="detail-container container-fluid">
      <div class="row col-detail">
        <div class="col-sm-12">
          <div class="disable-message" [ngClass]="configuration().type">
            <div class="row">
              <div class="col-1 col-sm-1 col-md-1">
                <div class="info-product-ico"></div>
              </div>
              <div class="col-10 col-sm-10 col-md-11">
                <div class="disable-message-title">
                  {{ configuration().title }}
                </div>
                <div class="disable-message-text">
                  {{ configuration().description }}
                </div>
                <button
                  type="button"
                  class="close closeAvis"
                  data-dismiss="modal"
                  aria-hidden="true"
                  (click)="hide()"
                >
                  &times;
                </button>
              </div>
              <div style="clear: both"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
