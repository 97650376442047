import { NgClass } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NavigationService } from '../../shared/services/navigation.service';
import { RecoverWidgetConfiguration } from './models/recover-widget-configuration.interface';

@Component({
  selector: 'hola-recover-widget',
  standalone: true,
  imports: [TranslateModule, ReactiveFormsModule, NgClass, RouterModule],

  templateUrl: './recover-widget.component.html',
  styleUrl: './recover-widget.component.scss',
})
export class RecoverWidgetComponent {
  private readonly formBuilder = inject(FormBuilder);
  private readonly navigationService = inject(NavigationService);

  public termsAndConditionsUrl = this.navigationService.termsAndConditionsUrl;
  public configuration = input.required<RecoverWidgetConfiguration>();
  public submitted = false;
  public form: FormGroup = new FormGroup({
    email: new FormControl(''),
    acceptTerms: new FormControl(false),
  });
  public emailFocus: boolean = false;
  constructor() {
    this.initForm();
  }

  public recover(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    console.log('recover action');
  }

  public onBlur(item: string) {
    switch (item) {
      case 'email':
        this.emailFocus = false;
        break;
      default:
        console.warn(`Unhandled item: ${item}`);
    }
  }

  public onFocus(item: string) {
    switch (item) {
      case 'email':
        this.emailFocus = true;
        break;
      default:
        console.warn(`Unhandled item: ${item}`);
    }
  }
  private initForm(): void {
    this.form = this.formBuilder.group(
      {
        email: ['', Validators.required],
      },
      {
        validators: [],
      },
    );
  }
  public get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
}
