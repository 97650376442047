import { Component, input } from '@angular/core';
import { PromoWidgetConfiguration } from './models/promo-widget-configuration.interface';

@Component({
  selector: 'hola-promo-widget',
  standalone: true,
  imports: [],
  templateUrl: './promo-widget.component.html',
  styleUrl: './promo-widget.component.scss',
})
export class PromoWidgetComponent {
  configuration = input.required<PromoWidgetConfiguration>();
}
