import { Component, input } from '@angular/core';
import { MoreInformationWidgetConfiguration } from './models/more-information-widget-configuration.interface';

@Component({
  selector: 'hola-more-information-widget',
  standalone: true,
  imports: [],
  templateUrl: './more-information-widget.component.html',
  styleUrl: './more-information-widget.component.scss',
})
export class MoreInformationWidgetComponent {
  configuration = input.required<MoreInformationWidgetConfiguration>();
}
