import { Injectable, computed, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject, switchMap, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CatalogProductType } from '../enums/catalog-product-type.enum';
import { CartSummaryProduct } from '../models/cart-summary-product.interface';
import { getDeviceInformation } from '../models/device-information.interface';
import { Discount } from '../models/discount.interface';
import {
  CustomerInformation,
  Order,
  OrderInvoice,
  OrderItem,
  OrderItemDetail,
} from '../models/order.interface';
import { CartService } from './cart.service';
import { CatalogService } from './catalog.service';
import { DiscountApiService } from './discount-api.service';
import { LanguageService } from './language.service';
import { HppDataRedsys, OrderApiService } from './order-api.service';

enum OrderType {
  Venda = 'VENDA',
  Devolucio = 'DEVOLUCIO',
}

enum OrderPaymentModeCode {
  TargetaBancaria = '1',
  Transferencia = '2',
  Efectiu = '3',
  PagamentAjornat = '4',
}

enum OrderItemDetailType {
  BookingDate = 'BOOKING_DATE',
  BookingTag = 'BOOKING_TAG',
  ReturnBookingDate = 'RETURN_BOOKING_DATE',
}

export interface DiscountApplied {
  discountCalculationType: string;
  discountCode: string;
  discountName: string;
  discountType: string;
}

interface OrderState {
  tempOrder: Order | null;
  discountCodes: string[];
  validDiscountCodes: string[];
  invalidDiscountCodes: string[];
  discountsApplied: DiscountApplied[];
  order: Order | null;
  hppData: HppDataRedsys | null;
}
@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private orderApiService = inject(OrderApiService);
  private discountApiService = inject(DiscountApiService);
  private languageService = inject(LanguageService);
  private cartService = inject(CartService);
  private catalogService = inject(CatalogService);

  private products = computed(() =>
    this.cartService.cartSummaryProducts().filter((p) => p.quantity > 0),
  );

  private state = signal<OrderState>({
    tempOrder: null,
    discountCodes: [],
    invalidDiscountCodes: [],
    validDiscountCodes: [],
    discountsApplied: [],
    order: null,
    hppData: null,
  });

  public tempOrder = computed(() => this.state().tempOrder);
  public discountCodes = computed(() => this.state().discountCodes);
  public invalidDiscountCodes = computed(
    () => this.state().invalidDiscountCodes,
  );
  public validDiscountCodes = computed(() => this.state().validDiscountCodes);
  public discountsApplied = computed(() => this.state().discountsApplied);
  public order = computed(() => this.state().order);
  public hppData = computed(() => this.state().hppData);

  public discounts = computed(
    () =>
      this.tempOrder()
        ?.items.map((item) =>
          item.itemDiscounts.map(
            (itemDiscount) =>
              ({
                productCode: item.productCode,
                productName: this.catalogService.getCatalogProduct(
                  item?.productCode,
                ).code,
                discountName: itemDiscount.discountName,
                discountCode: itemDiscount.discountCode,
                discountAmount: itemDiscount.finalAmount,
                discountPercentage: itemDiscount.discountValue,
                quantity: itemDiscount.quantity,
              }) as Discount,
          ),
        )
        .flat() || [],
  );

  private applyDiscountCode$ = new Subject<string>();
  private discount$ = this.applyDiscountCode$.pipe(
    tap((code) =>
      this.state.update((state) => ({
        ...state,
        discountCodes: [...state.discountCodes, code],
      })),
    ),
    switchMap((discountCode) =>
      this.discountApiService.checkDiscounts(
        [discountCode],
        this.createTempOrder(),
      ),
    ),
  );

  private createOrder$ = new Subject<void>();
  private order$ = this.createOrder$.pipe(
    switchMap(() =>
      this.orderApiService
        .createOrder(this.tempOrder())
        .pipe(tap((order) => this.loadHppData$.next(order))),
    ),
  );

  private loadHppData$ = new Subject<Order>();
  private hppData$ = this.loadHppData$.pipe(
    switchMap((order) => this.orderApiService.loadHppData(order)),
  );

  constructor() {
    this.discount$.pipe(takeUntilDestroyed()).subscribe({
      next: (data) => {
        this.state.update((state) => ({
          ...state,
          tempOrder: data.order,
          discountCodes: data.discountCoupons,
          invalidDiscountCodes: data.notValidDiscountCoupons,
          validDiscountCodes: data.discountCoupons.filter(
            (code) => !data.notValidDiscountCoupons.includes(code),
          ),
          discountsApplied: data.discountsApplied,
        }));
      },
      // TODO DAVID controlamos errores?
      error: () =>
        this.state.update((state) => ({
          ...state,
          tempOrder: null,
        })),
    });

    this.order$.pipe(takeUntilDestroyed()).subscribe({
      next: (data) => {
        this.state.update((state) => ({
          ...state,
          order: data,
        }));
      },
      // TODO DAVID controlamos errores?
      error: () =>
        this.state.update((state) => ({
          ...state,
          order: null,
        })),
    });

    this.hppData$.pipe(takeUntilDestroyed()).subscribe({
      next: (data) => {
        this.state.update((state) => ({
          ...state,
          hppData: data,
        }));
      },
      // TODO DAVID controlamos errores?
      error: () =>
        this.state.update((state) => ({
          ...state,
          hppData: null,
        })),
    });
  }

  public create(): void {
    console.log('crea orden');
    this.createOrder$.next();
  }

  public reset(): void {
    this.state().order = null;
    this.state().hppData = null;
  }

  public applyCustomerInformation(customerInfo: CustomerInformation): void {
    this.updateOrderCustomerInformation(customerInfo);
  }

  public applyOrderInvoice(orderInvoice: OrderInvoice): void {
    this.updateOrderInvoice(orderInvoice);
  }

  public applyDiscount(code: string): void {
    this.applyDiscountCode$.next(code);
  }

  private createTempOrder(): Order {
    const items = this.getOrderItems(this.products());
    return {
      deviceInformation: getDeviceInformation(),
      equipmentCode: environment.equipmentCode,
      orderDatetime: '',
      orderLanguage: this.languageService.language().toLocaleUpperCase(),
      orderType: OrderType.Venda,
      paymentModeCode: OrderPaymentModeCode.TargetaBancaria,
      invoiceRequested: false,
      items: items,
      productAmount: this.getOrderFinalAmount(items),
      finalAmount: this.getOrderFinalAmount(items),
      discountAmount: 0,
      customerInformation: {} as CustomerInformation,
    } as Order;
  }

  private getOrderItems(
    cartSummaryProducts: CartSummaryProduct[],
  ): OrderItem[] {
    return cartSummaryProducts.map((product) => this.parseOrderItem(product));
  }

  private parseOrderItem(cartSummaryProduct: CartSummaryProduct): OrderItem {
    return {
      productCode: cartSummaryProduct.product.code,
      productAmount:
        cartSummaryProduct.activePrice * cartSummaryProduct.quantity,
      productPrice: cartSummaryProduct.activePrice,
      quantity: cartSummaryProduct.quantity,
      itemDiscounts: [],
      taxAmount: 0,
      taxTypeCode: '',
      discountAmount: 0,
      finalAmount: cartSummaryProduct.activePrice * cartSummaryProduct.quantity,
      itemDetails: this.getOrderItemDetails(cartSummaryProduct),
      isPack:
        cartSummaryProduct.product.type === CatalogProductType.PackProduct,
    };
  }

  private getOrderItemDetails(
    cartSummaryProduct: CartSummaryProduct,
  ): OrderItemDetail[] {
    return [...Array(cartSummaryProduct.quantity)]
      .map((v, index) =>
        [
          {
            itemNumber: index + 1,
            type: OrderItemDetailType.BookingDate,
            value: cartSummaryProduct.departDate || '',
          },
          {
            itemNumber: index + 1,
            type: OrderItemDetailType.ReturnBookingDate,
            value: cartSummaryProduct.returnDate || '',
          },
          {
            itemNumber: index + 1,
            type: OrderItemDetailType.BookingTag,
            value: cartSummaryProduct.tagCode || '',
          },
        ].filter((detail) => detail.value !== ''),
      )
      .flat();
  }

  private getOrderFinalAmount(items: OrderItem[]): number {
    return items.reduce((total, item) => total + item.finalAmount, 0);
  }

  private updateOrderCustomerInformation(
    customerInformation: CustomerInformation,
  ): void {
    this.state.update((state) => ({
      ...state,
      tempOrder: state.tempOrder
        ? { ...state.tempOrder, customerInformation }
        : null,
    }));
  }
  private updateOrderInvoice(orderInvoice: OrderInvoice): void {
    this.state.update((state) => ({
      ...state,
      tempOrder: state.tempOrder
        ? { ...state.tempOrder, invoiceRequest: orderInvoice }
        : null,
    }));
  }
}
