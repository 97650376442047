import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppLanguage } from '../enums/app-language.enum';
import { Country } from '../models/country.interface';
import { StrapiCountryData } from '../models/strapi.interface';
import { StrapiService } from './strapi.service';

@Injectable({
  providedIn: 'root',
})
export class StrapiCountriesService extends StrapiService<StrapiCountryData> {
  private countriesApiUrl = (locale: AppLanguage) =>
    `${environment.strapiUrl}/countries?locale=${locale}`;

  public loadCountries(locale = AppLanguage.EN): Observable<Country[]> {
    return this.getAll(`${this.countriesApiUrl(locale)}`).pipe(
      map((strapiCountries) => this.mapCountries(strapiCountries)),
    );
  }

  private mapCountries(strapiCountries: StrapiCountryData[]): Country[] {
    return strapiCountries.map((strapiCountryData) =>
      this.mapCountry(strapiCountryData),
    );
  }

  private mapCountry(strapiCountryData: StrapiCountryData): Country {
    return {
      name: strapiCountryData.attributes.name,
      codeAlfa2: strapiCountryData.attributes.codeAlfa2,
      codeAlfa3: strapiCountryData.attributes.codeAlfa3,
    };
  }
}
