import { inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'sanitize',
  standalone: true,
})
export class SanitizePipe implements PipeTransform {
  private readonly sanitizerService = inject(DomSanitizer);

  transform(url: string) {
    return this.sanitizerService.bypassSecurityTrustResourceUrl(url);
  }
}
