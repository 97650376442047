import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  PLATFORM_ID,
  ViewChild,
  computed,
  effect,
  inject,
  input,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { filter, map, take } from 'rxjs';
import { i18nRoutes } from '../../../assets/i18n/routes.i18n';
import { ShimmerPlaceholderComponent } from '../../shared/components/shimmer-placeholder/shimmer-placeholder.component';
import { PageId } from '../../shared/enums/page-id.enum';
import { ProductReservationTripType } from '../../shared/enums/product-reservation-trip-type.enum';
import { GtmDataLayerService } from '../../shared/gtm/gtm.service';
import { CartProduct } from '../../shared/models/cart-product.interface';
import { CatalogParentProduct } from '../../shared/models/catalog.interface';
import { PageResolverParams } from '../../shared/models/page-params.interface';
import { ProductReservationForm } from '../../shared/models/product-reservation-form.interface';
import { SuperProduct } from '../../shared/models/super-product.interface';
import { CustomCurrencyPipe } from '../../shared/pipes/custom-currency.pipe';
import { CartTempService } from '../../shared/services/cart-temp.service';
import { LanguageService } from '../../shared/services/language.service';
import { NavigationService } from '../../shared/services/navigation.service';
import { SelectProductService } from '../../shared/services/select-product.service';
import { ProductModuleAccordionComponent } from './components/product-module-accordion/product-module-accordion.component';
import { ProductModuleBoxesComponent } from './components/product-module-boxes/product-module-boxes.component';
import { ProductModuleSliderComponent } from './components/product-module-slider/product-module-slider.component';
import { ProductReservationComponent } from './components/product-reservation/product-reservation.component';
import { ProductSelectComponent } from './components/product-select/product-select.component';
import { ProductSummaryComponent } from './components/product-summary/product-summary.component';
import { ProductDetailWidgetConfiguration } from './models/product-detail-widget-configuration.interface';

@Component({
  selector: 'hola-product-detail-widget',
  standalone: true,
  imports: [
    ProductSelectComponent,
    ProductReservationComponent,
    ProductSummaryComponent,
    TranslateModule,
    CustomCurrencyPipe,
    ProductModuleSliderComponent,
    ProductModuleBoxesComponent,
    ProductModuleAccordionComponent,
    ShimmerPlaceholderComponent,
  ],
  templateUrl: './product-detail-widget.component.html',
  styleUrl: './product-detail-widget.component.scss',
})
export class ProductDetailWidgetComponent {
  @ViewChild('content') content!: ElementRef;

  public configuration = input.required<ProductDetailWidgetConfiguration>();
  public isBrowser!: boolean;

  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);
  private readonly selectProductService = inject(SelectProductService);
  private readonly cartTempService = inject(CartTempService);
  private readonly languageService = inject(LanguageService);
  private readonly navigationService = inject(NavigationService);
  private readonly gtmDataLayerService = inject(GtmDataLayerService);

  public locale = this.languageService.locale;
  AVIS: { title: string; text: string; type: string } | undefined;

  public superProduct = computed(
    () =>
      this.selectProductService.selectedSuperProduct() || ({} as SuperProduct),
  );
  public parentProducts =
    this.selectProductService.selectedSuperProductCatalogParentProducts;
  public variantProducts =
    this.selectProductService.selectedCatalogParentProductVariants;
  public selectedParentProduct =
    this.selectProductService.selectedCatalogParentProduct;

  public reservationInfo = this.selectProductService.reservationInfo;
  public reservationForm = signal({} as ProductReservationForm);

  public addToCartProducts = computed(() =>
    !this.reservationInfo()?.bookingServiceCode ||
    (this.reservationInfo() &&
      this.reservationForm().departTrip &&
      (this.reservationForm().departTrip.tripType ===
        ProductReservationTripType.Circular ||
        this.reservationForm().returnTrip))
      ? this.variantProducts().map(
          (v): CartProduct => ({
            superProduct: this.superProduct(),
            parentProduct: this.selectedParentProduct(),
            product: v,
            reservationInfo: this.reservationInfo(),
            reservationForm: this.reservationForm(),
          }),
        )
      : [],
  );

  public totalActivePrice = computed(() =>
    this.cartTempService
      .products()
      .filter((cartProduct) =>
        this.variantProducts().find((v) => v.code === cartProduct.product.code),
      )
      .reduce((acc: number, curr) => acc + (curr.product.activePrice || 0), 0),
  );

  public totalReducedPrice = computed(() =>
    this.cartTempService
      .products()
      .filter((cartProduct) =>
        this.variantProducts().find((v) => v.code === cartProduct.product.code),
      )
      .reduce((acc: number, curr) => acc + (curr.product.reducedPrice || 0), 0),
  );

  // TODO definir tipo de viaje a nivel de reserva de producto
  // showAddToCart = computed(
  //   () =>
  //     !this.reservationInfo()?.bookingServiceCode ||
  //     (this.reservationInfo() && this.reservationForm().departTrip),
  // );

  private selectedSuperProduct$ = toObservable(this.superProduct);

  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    effect(() =>
      console.log('prueba superproducto module box', this.superProduct()),
    );
    this.isBrowser = isPlatformBrowser(this.platformId);

    // Inicializamos el producto en base a los parámetros de la ruta.
    this.route.data
      .pipe(
        takeUntilDestroyed(),
        map((data) => data['params'] as PageResolverParams),
      )
      .subscribe((params) => {
        if (params.ticketName) {
          this.initProduct(params.ticketName);
        }
      });

    // Gtm - productDetail
    this.selectedSuperProduct$
      .pipe(
        filter((superProduct) => superProduct !== null),
        take(1),
      )
      .subscribe((superProduct) =>
        this.gtmDataLayerService.productDetail(superProduct),
      );
  }

  public initProduct(ticketName: string): void {
    this.cartTempService.load();
    this.selectProductService.selectSuperProduct(ticketName);
  }

  public selectParentProduct(product: CatalogParentProduct | null): void {
    this.selectProductService.selectCatalogParentProduct(product?.code || null);
  }

  public saveCart(): void {
    this.cartTempService.store();
  }

  public checkout(): void {
    this.saveCart();
    // TODO servicio navegación por idioma
    this.router.navigate([
      i18nRoutes[PageId.Cart][this.languageService.language()][0],
    ]);
  }

  public navigateToHomePage(): void {
    this.navigationService.navigateToPage(PageId.Home);
  }

  public scrollToTop() {
    if (this.content && this.content.nativeElement) {
      this.content.nativeElement.scrollIntoView({
        behavior: 'smooth', // Desplazamiento suave
        block: 'start', // Alinea el elemento al inicio de la vista
      });
    } else {
      console.warn('El elemento de contenido no está disponible.');
    }
  }
}
