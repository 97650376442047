import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { filter, take } from 'rxjs';
import { i18nRoutes } from '../../../assets/i18n/routes.i18n';
import { OrderDiscountsApplyComponent } from '../../shared/components/purchase-summary/components/order-discounts-apply/order-discounts-apply.component';
import { PurchaseSummaryComponent } from '../../shared/components/purchase-summary/purchase-summary.component';
import { PageId } from '../../shared/enums/page-id.enum';
import { GtmDataLayerService } from '../../shared/gtm/gtm.service';
import { CartProduct } from '../../shared/models/cart-product.interface';
import { CartSummaryProduct } from '../../shared/models/cart-summary-product.interface';
import { CartSummaryProductsGroup } from '../../shared/models/cart-summary-products-group.interface';
import { SuperProduct } from '../../shared/models/super-product.interface';
import { CustomCurrencyPipe } from '../../shared/pipes/custom-currency.pipe';
import { CartService } from '../../shared/services/cart.service';
import { LanguageService } from '../../shared/services/language.service';
import { NavigationService } from '../../shared/services/navigation.service';
import { OrderService } from '../../shared/services/order.service';
import { CartWidgetConfiguration } from './models/cart-widget-configuration.interface';

@Component({
  selector: 'hola-cart-widget',
  standalone: true,
  imports: [
    CustomCurrencyPipe,
    CurrencyPipe,
    DatePipe,
    TranslateModule,
    RouterModule,
    OrderDiscountsApplyComponent,
    PurchaseSummaryComponent,
  ],
  templateUrl: './cart-widget.component.html',
  styleUrl: './cart-widget.component.scss',
})
export class CartWidgetComponent {
  configuration = input.required<CartWidgetConfiguration>();

  private readonly router = inject(Router);
  private readonly languageService = inject(LanguageService);
  private readonly orderService = inject(OrderService);
  private readonly navigationService = inject(NavigationService);
  private readonly cartService = inject(CartService);
  private readonly gtmDataLayerService = inject(GtmDataLayerService);

  public appLanguage = this.languageService.language;
  public locale = this.languageService.locale;
  public homeUrl = this.navigationService.homeUrl;
  public cartUrl = this.navigationService.cartUrl;
  public productsQuantity = this.cartService.productsQuantity;
  public cartSummaryProductsGroups = this.cartService.cartSummaryProductsGroups;

  private cartProducts$ = toObservable(this.cartService.products);

  constructor() {
    // Genera la orden al entrar al carrito
    this.orderService.applyDiscount('');
    // Gtm Checkout 1
    this.cartProducts$
      .pipe(
        filter((cartProducts) => cartProducts.length > 0),
        take(1),
      )
      .subscribe((cartProducts) =>
        this.gtmDataLayerService.checkout(cartProducts, 1, 'cart'),
      );
  }

  public navigateToHomePage(): void {
    this.navigationService.navigateToPage(PageId.Home);
  }

  public navigateToGroup(
    cartSummaryProductsGroup: CartSummaryProductsGroup,
  ): void {
    const superProduct =
      cartSummaryProductsGroup.products[0].superProduct || ({} as SuperProduct);
    this.navigationService.navigateToSuperProduct(superProduct);
  }

  public add(cartSummaryProduct: CartSummaryProduct): void {
    this.cartService.add({
      superProduct: cartSummaryProduct.superProduct,
      parentProduct: cartSummaryProduct.parentProduct,
      product: cartSummaryProduct.product,
      reservationInfo: cartSummaryProduct.reservationInfo,
      reservationForm: cartSummaryProduct.reservationForm,
    });
    this.orderService.applyDiscount('');
  }
  public remove(cartSummaryProduct: CartSummaryProduct): void {
    this.cartService.remove({
      superProduct: cartSummaryProduct.superProduct,
      parentProduct: cartSummaryProduct.parentProduct,
      product: cartSummaryProduct.product,
      reservationInfo: cartSummaryProduct.reservationInfo,
      reservationForm: cartSummaryProduct.reservationForm,
    });
    this.orderService.applyDiscount('');
  }
  public deleteGroup(cartSummaryProducts: CartSummaryProduct[]): void {
    const cartProducts: CartProduct[] = cartSummaryProducts.map((p) => ({
      superProduct: p.superProduct,
      parentProduct: p.parentProduct,
      product: p.product,
      reservationForm: p.reservationForm,
      reservationInfo: p.reservationInfo,
    }));
    this.cartService.delete(cartProducts);
    this.orderService.applyDiscount('');
  }

  public checkout(): void {
    console.log('language', this.languageService.language());
    this.router.navigate([
      i18nRoutes[PageId.Checkout][this.languageService.language()][0],
    ]);
  }
}
