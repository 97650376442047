import { Injectable, computed, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { Subject, combineLatest, map, switchMap, tap } from 'rxjs';
import {
  CatalogParentProduct,
  CatalogProduct,
} from '../models/catalog.interface';
import { ProductReservationAvailability } from '../models/product-reservation-availability.interface';
import { ProductReservationInfo } from '../models/product-reservation-info.interface';
import { ProductReservationLabel } from '../models/product-reservation-label.interface';
import { SuperProduct } from '../models/super-product.interface';
import { toAppLanguage } from '../utils/language.util';
import { CatalogService } from './catalog.service';
import { LanguageService } from './language.service';
import { ProductReservationApiService } from './product-reservation-api.service';
import { SuperProductService } from './super-product.service';

interface SelectProductState {
  selectedSuperProduct: SuperProduct | null;
  selectedSuperProductCatalogParentProducts: CatalogParentProduct[];
  selectedCatalogParentProduct: CatalogParentProduct | null;
  selectedCatalogParentProductVariants: CatalogProduct[];
  selectedSuperProductFirstVariantCode: string | null;
  selectedProductReservationInfo: ProductReservationInfo | null;
  selectedProductAvailability: ProductReservationAvailability[];
  translatedSelectedProductLabels: ProductReservationLabel[];
}

@Injectable({
  providedIn: 'root',
})
export class SelectProductService {
  private superProductService = inject(SuperProductService);
  private catalogService = inject(CatalogService);
  private productReservationService = inject(ProductReservationApiService);
  private languageService = inject(LanguageService);

  private state = signal<SelectProductState>({
    selectedSuperProduct: null,
    selectedSuperProductCatalogParentProducts: [],
    selectedCatalogParentProduct: null,
    selectedCatalogParentProductVariants: [],
    selectedSuperProductFirstVariantCode: null,
    selectedProductReservationInfo: null,
    selectedProductAvailability: [],
    translatedSelectedProductLabels: [],
  });

  public selectedSuperProduct = computed(
    () => this.state().selectedSuperProduct,
  );
  public selectedSuperProductCatalogParentProducts = computed(
    () => this.state().selectedSuperProductCatalogParentProducts,
  );
  public selectedCatalogParentProduct = computed(
    () => this.state().selectedCatalogParentProduct,
  );
  public selectedCatalogParentProductVariants = computed(
    () => this.state().selectedCatalogParentProductVariants,
  );
  public selectedSuperProductFirstVariantCode = computed(
    () => this.state().selectedSuperProductFirstVariantCode,
  );

  // public productsWithoutVariants = computed(() => this.state().variants);

  // public selectedCatalogParentProductVariants = computed(() =>
  //   this.selectedSuperProductParentProducts().length > 0
  //     ? this.state().selectedCatalogParentProductVariants
  //     : this.productsWithoutVariants(),
  // );

  private superProducts$ = toObservable(this.superProductService.superProducts);

  private selectSuperProduct$ = new Subject<string>();
  private selectedSuperProduct$ = combineLatest([
    this.selectSuperProduct$,
    this.superProducts$,
  ]).pipe(
    map(
      ([slug, superProducts]) =>
        superProducts.filter((superProduct) => superProduct.slug === slug)[0],
    ),
  );

  private selectedSubProductCodes$ = this.selectedSuperProduct$.pipe(
    map((superProduct) => superProduct?.subProducts),
  );

  private catalogParentProducts$ = toObservable(
    this.catalogService.parentProducts,
  );

  private selectedSuperProductCatalogParentProducts$ = combineLatest([
    this.selectedSubProductCodes$,
    this.catalogParentProducts$,
  ]).pipe(
    map(([codes, products]) =>
      products.filter((product) => codes?.includes(product.code)),
    ),
  );

  private selectCatalogParentProduct$ = new Subject<string | null>();
  private selectedCatalogParentProduct$ = combineLatest([
    this.selectCatalogParentProduct$,
    this.selectedSuperProductCatalogParentProducts$,
  ]).pipe(
    map(([code, products]) => products.filter((p) => p.code === code)?.[0]),
  );

  private selectedCatalogParentProductVariantsCodes$ =
    this.selectedCatalogParentProduct$.pipe(
      map((product) => product?.products.map((p) => p.code) || []),
    );

  private catalogProducts$ = toObservable(this.catalogService.variantProducts);
  private selectedCatalogParentProductVariants$ = combineLatest([
    this.selectedCatalogParentProductVariantsCodes$,
    this.catalogProducts$,
  ]).pipe(
    map(([productVariantsCodes, products]) =>
      products.filter((p) => productVariantsCodes.includes(p.code)),
    ),
  );

  private selectedSuperProductFirstVariantCode$ = combineLatest([
    this.selectedSuperProductCatalogParentProducts$,
    this.selectedCatalogParentProductVariants$,
  ]).pipe(
    map(
      ([parentProducts, variantsProducts]) =>
        parentProducts[0]?.products[0]?.code || variantsProducts[0]?.code,
    ),
  );

  private loadProductReservationInfo$ =
    this.selectedSuperProductFirstVariantCode$.pipe(
      switchMap((code) => this.productReservationService.loadInfo(code)),
    );

  private productReservationInfo$ = combineLatest([
    this.loadProductReservationInfo$,
    this.selectedSuperProduct$,
  ]).pipe(
    map(
      ([reservationInfo, product]) =>
        ({
          routeCode: product?.routeCode,
          bookingServiceCode: reservationInfo?.bookingServiceCode,
          reservationType: reservationInfo?.reservationType,
          labels: reservationInfo?.labels?.map(
            (label) =>
              ({
                code: label.code,
                languages: label.language,
              }) as ProductReservationLabel,
          ),
        }) as ProductReservationInfo,
    ),
  );

  private productAvailability$ = this.productReservationInfo$.pipe(
    switchMap((reservationInfo) =>
      this.productReservationService.loadAvailability(reservationInfo).pipe(
        map((availability) =>
          availability.filter(
            (a) =>
              a.bookingServiceTripsAvailable?.filter(
                (trips) =>
                  new Date(trips.startDatetime).getTime() >
                  new Date(a.serviceDate).getTime(),
              ).length > 0,
          ),
        ),
        map((availability) =>
          availability.map(
            (a) =>
              ({
                date: a.serviceDate,
                trips: a.bookingServiceTripsAvailable,
                routeType: a.routeType,
              }) as ProductReservationAvailability,
          ),
        ),
      ),
    ),
  );

  private language$ = toObservable(this.languageService.language);
  private translatedSelectedProductLabels$ = combineLatest([
    this.language$,
    this.productReservationInfo$,
  ]).pipe(
    map(([appLanguage, reservationInfo]) =>
      reservationInfo?.labels?.map((label) => ({
        ...label,
        language: label.languages.filter(
          (language) => toAppLanguage(language.languageCode) === appLanguage,
        )[0],
      })),
    ),
  );

  public reservationInfo = computed(
    () => this.state().selectedProductReservationInfo,
  );
  public availability = computed(
    () => this.state().selectedProductAvailability,
  );
  public labels = computed(() => this.state().translatedSelectedProductLabels);

  constructor() {
    this.selectedSuperProduct$
      .pipe(takeUntilDestroyed())
      .subscribe((selectedSuperProduct) => {
        this.state.update((state) => ({ ...state, selectedSuperProduct }));
      });

    this.selectedSuperProductCatalogParentProducts$
      .pipe(
        takeUntilDestroyed(),
        tap((catalogParentProducts) => {
          if (catalogParentProducts.length === 1) {
            this.selectCatalogParentProduct(catalogParentProducts[0]?.code);
          } else {
            this.selectCatalogParentProduct(null);
          }
        }),
      )
      .subscribe((selectedSuperProductCatalogParentProducts) => {
        this.state.update(
          (state) =>
            ({
              ...state,
              selectedSuperProductCatalogParentProducts,
            }) as SelectProductState,
        );
      });

    this.selectedCatalogParentProduct$
      .pipe(takeUntilDestroyed())
      .subscribe((selectedCatalogParentProduct) => {
        this.state.update(
          (state) =>
            ({
              ...state,
              selectedCatalogParentProduct,
            }) as SelectProductState,
        );
      });

    this.selectedCatalogParentProductVariants$
      .pipe(takeUntilDestroyed())
      .subscribe((selectedCatalogParentProductVariants) => {
        this.state.update(
          (state) =>
            ({
              ...state,
              selectedCatalogParentProductVariants,
            }) as SelectProductState,
        );
      });

    this.selectedSuperProductFirstVariantCode$
      .pipe(takeUntilDestroyed())
      .subscribe((selectedSuperProductFirstVariantCode) =>
        this.state.update((state) => ({
          ...state,
          selectedSuperProductFirstVariantCode,
        })),
      );

    this.productReservationInfo$
      .pipe(takeUntilDestroyed())
      .subscribe((selectedProductReservationInfo) =>
        this.state.update((state) => ({
          ...state,
          selectedProductReservationInfo,
        })),
      );

    this.productAvailability$
      .pipe(takeUntilDestroyed())
      .subscribe((selectedProductAvailability) =>
        this.state.update((state) => ({
          ...state,
          selectedProductAvailability,
        })),
      );

    this.translatedSelectedProductLabels$
      .pipe(takeUntilDestroyed())
      .subscribe((translatedSelectedProductLabels) =>
        this.state.update((state) => ({
          ...state,
          translatedSelectedProductLabels,
        })),
      );
  }

  public selectSuperProduct(slug: string): void {
    this.selectSuperProduct$.next(slug);
  }

  public selectCatalogParentProduct(code: string | null): void {
    this.selectCatalogParentProduct$.next(code);
  }
}
