import { Component, computed, inject, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { StrapiSuperProductsGroupCode } from '../../shared/enums/strapi-super-products-group-code.enum';
import { SuperProductsGroupProductType } from '../../shared/enums/super-products-group-product-type.enum';
import { HolaProduct } from '../../shared/models/hola-product.interface';
import { SuperProduct } from '../../shared/models/super-product.interface';
import { LanguageService } from '../../shared/services/language.service';
import { NavigationService } from '../../shared/services/navigation.service';
import { SuperProductService } from '../../shared/services/super-product.service';
import { SuperProductsGroupService } from '../../shared/services/super-products-group.service';
import { SitemapWidgetConfiguration } from './models/sitemap-widget-configuration.interface';

@Component({
  selector: 'hola-sitemap-widget',
  standalone: true,
  imports: [TranslateModule, RouterModule],
  templateUrl: './sitemap-widget.component.html',
  styleUrl: './sitemap-widget.component.scss',
})
export class SitemapWidgetComponent {
  public configuration = input.required<SitemapWidgetConfiguration>();
  private readonly navigationService = inject(NavigationService);
  private readonly languageService = inject(LanguageService);
  private readonly superProductService = inject(SuperProductService);
  private readonly superProductsGroupService = inject(
    SuperProductsGroupService,
  );

  public faqUrl = this.navigationService.faqUrl;
  public recoverUrl = this.navigationService.recoverUrl;
  public termsAndConditionsUrl = this.navigationService.termsAndConditionsUrl;
  public contactUrl = this.navigationService.contactUrl;
  public aboutUrl = this.navigationService.aboutUrl;
  public legalUrl = this.navigationService.legalUrl;
  public sitemapUrl = this.navigationService.sitemapUrl;
  public transportDealsLandingUrl =
    this.navigationService.transportDealsLandingUrl;
  public ticketsUrl = this.navigationService.ticketsUrl;

  public appLanguage = this.languageService.language;
  public urlHolaBcnBlog = `https://blog.holabarcelona.com/ + ${this.appLanguage()}`;

  /* TODO: Habrá que cambiar la agrupación de productos para que use la de sitemap cuando exista */
  public superProductsGroup = computed(
    () =>
      this.superProductsGroupService
        .superProductsGroups()
        .filter(
          (superProductsGroups) =>
            superProductsGroups.code === StrapiSuperProductsGroupCode.Home,
        )[0],
  );
  public superProductsGroupProducts = computed(
    () =>
      this.superProductsGroup()?.products.filter((product) =>
        this.superProductService
          .superProducts()
          .find(
            (p) =>
              product.code === p.code ||
              product.type === SuperProductsGroupProductType.SuperProductGroup,
          ),
      ) || [],
  );

  public products = computed(() =>
    this.superProductsGroupProducts().map((p) => {
      const product =
        p.type === SuperProductsGroupProductType.SuperProduct
          ? this.superProductService.getSuperProductByCode(p.code)
          : this.superProductsGroupService.getSuperProductsGroupByCode(p.code);
      return {
        title: product.title,
        description: product.description,
        imageUrl: product.mainImageUrl,
        activePrice: product.activePrice,
        reducedPrice: product.reducedPrice,
        onClick:
          p.type === SuperProductsGroupProductType.SuperProduct
            ? () =>
                this.navigationService.navigateByUrl(
                  `/${this.languageService.language()}/tickets/${(product as SuperProduct).slug}`,
                )
            : () =>
                this.navigationService.navigateToSuperProductsGroup(
                  p.code as StrapiSuperProductsGroupCode,
                ),
      } as HolaProduct;
    }),
  );
}
