import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'hola-shimmer-placeholder',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './shimmer-placeholder.component.html',
  styleUrl: './shimmer-placeholder.component.scss',
})
export class ShimmerPlaceholderComponent implements OnInit {
  // Array para almacenar las cajas generadas aleatoriamente
  boxes: { width: string; height: string }[] = [];

  ngOnInit(): void {
    this.generateRandomBoxes(10); // Generar 10 cajas aleatorias
  }

  // Función para generar cajas aleatorias
  generateRandomBoxes(count: number): void {
    this.boxes.push({ width: '100%', height: '20px' });
    for (let i = 0; i < count; i++) {
      const randomType = Math.random() > 0.5 ? 'small' : 'large'; // Determinar aleatoriamente el tipo de caja
      if (randomType === 'small') {
        this.boxes.push({ width: '100%', height: '20px' });
      } else {
        this.boxes.push({ width: '100%', height: '200px' });
      }
    }
    this.boxes.push({ width: '100%', height: '200px' });
  }
}
