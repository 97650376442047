import { computed, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { combineLatest, Subject, switchMap } from 'rxjs';
import { Country } from '../models/country.interface';
import { LanguageService } from './language.service';
import { StrapiCountriesService } from './strapi-countries.service';

interface CountriesState {
  countries: Country[];
  updated: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  private strapiCountriesService = inject(StrapiCountriesService);
  private languageService = inject(LanguageService);

  private state = signal<CountriesState>({
    countries: [],
    updated: null,
  });

  private load$ = new Subject<void>();
  private changeLanguage$ = toObservable(this.languageService.language);

  public countries = computed(() => this.state().countries);

  public codesAlfa2 = computed(() =>
    this.state().countries.map((c) => c.codeAlfa2),
  );
  public codesAlfa3 = computed(() =>
    this.state().countries.map((c) => c.codeAlfa3),
  );
  public names = computed(() => this.state().countries.map((c) => c.name));

  constructor() {
    combineLatest([this.changeLanguage$, this.load$])
      .pipe(
        switchMap(([language]) =>
          this.strapiCountriesService.loadCountries(language),
        ),
        takeUntilDestroyed(),
      )
      .subscribe((countries) =>
        this.state.update((state) => ({
          ...state,
          countries,
          updated: new Date().toISOString(),
        })),
      );
  }

  public load(): void {
    if (!this.state().updated) {
      this.load$.next();
    }
  }
}
