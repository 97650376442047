import { Component, input } from '@angular/core';
import { LegalAdviceSignupWidgetConfiguration } from './models/legal-advice-signup-widget-configuration.interface';

@Component({
  selector: 'hola-legal-advice-signup-widget',
  standalone: true,
  imports: [],
  templateUrl: './legal-advice-signup-widget.component.html',
  styleUrl: './legal-advice-signup-widget.component.scss',
})
export class LegalAdviceSignupWidgetComponent {
  configuration = input.required<LegalAdviceSignupWidgetConfiguration>();
}
