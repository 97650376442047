import { DatePipe } from '@angular/common';
import { Component, computed, effect, inject } from '@angular/core';
import { CartProduct } from '../../../../shared/models/cart-product.interface';
import { CartSummaryProduct } from '../../../../shared/models/cart-summary-product.interface';
import { CustomCurrencyPipe } from '../../../../shared/pipes/custom-currency.pipe';
import { CartTempService } from '../../../../shared/services/cart-temp.service';
import { LanguageService } from '../../../../shared/services/language.service';
import { SelectProductService } from '../../../../shared/services/select-product.service';

@Component({
  selector: 'hola-product-summary',
  standalone: true,
  imports: [DatePipe, CustomCurrencyPipe],
  templateUrl: './product-summary.component.html',
  styleUrl: './product-summary.component.scss',
})
export class ProductSummaryComponent {
  private readonly cartTempService = inject(CartTempService);
  private readonly selectProductService = inject(SelectProductService);
  private readonly languageService = inject(LanguageService);

  public locale = this.languageService.locale;

  public tempCartProducts = computed(() =>
    this.cartTempService
      .products()
      .filter((cartProduct) =>
        this.selectProductService
          .selectedCatalogParentProductVariants()
          ?.find((product) => product.code === cartProduct.product.code),
      ),
  );
  public tempCartSummaryProducts = computed(() =>
    this.tempCartProducts().reduce(
      (acc: CartSummaryProduct[], cartProduct: CartProduct) => {
        const item = acc.find(
          (a) =>
            a.product?.code === cartProduct?.product?.code &&
            a.reservationForm?.departTrip?.tagCode ===
              cartProduct.reservationForm?.departTrip?.tagCode &&
            a.reservationForm?.departTrip?.startDatetime ===
              cartProduct.reservationForm?.departTrip?.startDatetime &&
            a.reservationForm?.returnTrip?.tagCode ===
              cartProduct.reservationForm?.returnTrip?.tagCode &&
            a.reservationForm?.returnTrip?.startDatetime ===
              cartProduct.reservationForm?.returnTrip?.startDatetime,
        );
        if (item) {
          item.quantity++;
          item.activePrice = item.product.activePrice * item.quantity;
        } else {
          const newItem: CartSummaryProduct = {
            superProduct: cartProduct.superProduct,
            parentProduct: cartProduct.parentProduct,
            product: cartProduct.product,
            reservationInfo: cartProduct.reservationInfo,
            reservationForm: cartProduct.reservationForm,
            quantity: 1,
            activePrice: cartProduct.product.activePrice,
            reducedPrice: cartProduct.product.reducedPrice,
            tagCode: cartProduct.reservationForm?.departTrip?.tagCode || null,
            departDate:
              cartProduct.reservationForm?.departTrip?.startDatetime || null,
            returnDate:
              cartProduct.reservationForm?.returnTrip?.startDatetime || null,
          };
          acc.push(newItem);
        }
        return acc;
      },
      [],
    ),
  );

  public productsPerDateGrouped = computed(() =>
    this.tempCartSummaryProducts().reduce(
      (
        acc: { [key: string]: CartSummaryProduct[] },
        curr: CartSummaryProduct,
      ) => {
        const key = `${curr.departDate || ''} ${curr.returnDate || ''} ${curr.tagCode || ''}`;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(curr);
        return acc;
      },
      {},
    ),
  );

  public productsPerDate = computed(() =>
    Array.from(
      Object.entries(this.productsPerDateGrouped()),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([key, value]) => value,
    ),
  );

  constructor() {
    
  }
}
