import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CustomCurrencyPipe } from '../../pipes/custom-currency.pipe';
import { CartMenuVisibilityService } from '../../services/cart-menu-visibility.service';
import { CartService } from '../../services/cart.service';
import { LanguageService } from '../../services/language.service';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'hola-cart-menu',
  standalone: true,
  imports: [RouterModule, TranslateModule, CustomCurrencyPipe],

  templateUrl: './cart-menu.component.html',
  styleUrl: './cart-menu.component.scss',
})
export class CartMenuComponent {
  private readonly cartService = inject(CartService);
  private readonly cartMenuVisibilityService = inject(
    CartMenuVisibilityService,
  );
  private readonly navigationService = inject(NavigationService);
  private readonly languageService = inject(LanguageService);

  public locale = this.languageService.locale;

  public homeUrl = this.navigationService.homeUrl;
  public cartUrl = this.navigationService.cartUrl;
  public isVisible = this.cartMenuVisibilityService.isVisible;
  public productsQuantity = this.cartService.productsQuantity;
  public cartSummaryProductsGroups = this.cartService.cartSummaryProductsGroups;
  public orderActivePrice = this.cartService.orderActivePrice;
  public orderReducedPrice = this.cartService.orderReducedPrice;

  public hide(): void {
    this.cartMenuVisibilityService.hide();
  }
}
