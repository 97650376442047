import { Component, input } from '@angular/core';
import { UnsubscribeMessageWidgetConfiguration } from './models/unsubscribe-message-widget-configuration.interface';

@Component({
  selector: 'hola-unsubscribe-message-widget',
  standalone: true,
  imports: [],
  templateUrl: './unsubscribe-message-widget.component.html',
  styleUrl: './unsubscribe-message-widget.component.scss',
})
export class UnsubscribeMessageWidgetComponent {
  configuration = input.required<UnsubscribeMessageWidgetConfiguration>();
}
