import { Component, input } from '@angular/core';
import { FreeHtmlWidgetConfiguration } from './models/free-html-widget-configuration.interface';

@Component({
  selector: 'hola-free-html-widget',
  standalone: true,
  imports: [],
  templateUrl: './free-html-widget.component.html',
  styleUrl: './free-html-widget.component.scss',
})
export class FreeHtmlWidgetComponent {
  configuration = input.required<FreeHtmlWidgetConfiguration>();
}
