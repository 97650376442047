import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Signal,
  computed,
  effect,
  inject,
  model,
  signal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  provideNativeDateAdapter,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { CustomDateAdapter } from '../../../../shared/config/custom-date-adapter';
import { ProductReservationRouteType } from '../../../../shared/enums/product-reservation-route-type.enum';
import { ProductReservationTripType } from '../../../../shared/enums/product-reservation-trip-type.enum';
import { ProductReservationType } from '../../../../shared/enums/product-reservation-type.enum';
import {
  BookingServiceAvailableTrip,
  ProductReservationAvailability,
} from '../../../../shared/models/product-reservation-availability.interface';
import { ProductReservationForm } from '../../../../shared/models/product-reservation-form.interface';
import { LanguageService } from '../../../../shared/services/language.service';
import { SelectProductService } from '../../../../shared/services/select-product.service';
import { toAppLanguage } from '../../../../shared/utils/language.util';

// Diferencia de tiempo mínima elegible para los viajes de vuelta con respecto al viaje de ida seleccionado.
const minTimeReturnTripDiff = 1 * 60 * 60 * 1000;
/*
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
*/
@Component({
  selector: 'hola-product-reservation',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DatePipe,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
  ],
  templateUrl: './product-reservation.component.html',
  styleUrl: './product-reservation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    provideNativeDateAdapter(),
  ],
})
export class ProductReservationComponent {
  // private productReservationService = inject(ProductReservationService);
  private selectProductService = inject(SelectProductService);
  private formBuilder = inject(FormBuilder);
  private languageService = inject(LanguageService);

  reservation = model.required<ProductReservationForm>();
  reservationForm!: FormGroup;

  reservationInfo = this.selectProductService.reservationInfo;
  availability = this.selectProductService.availability;
  labels = this.selectProductService.labels;

  reservationType = computed(() => this.reservationInfo()?.reservationType);

  selectedTagCode!: Signal<string>;
  selectedDate!: Signal<string>;
  selectedDateFormated = computed(
    () => new Date(this.selectedDate()).toISOString().slice(0, -5) + 'Z',
  );
  selectedDepartTrip!: Signal<BookingServiceAvailableTrip>;
  selectedReturnTrip!: Signal<BookingServiceAvailableTrip>;

  availabilitiesByTagCode = computed(() =>
    this.selectedTagCode()
      ? this.availability()
          ?.map(
            (a): ProductReservationAvailability => ({
              ...a,
              trips: a.trips.filter(
                (t) =>
                  t.startDatetime &&
                  (!this.selectedTagCode() ||
                    t.tagCode === this.selectedTagCode()),
              ),
            }),
          )
          .filter((a) => a.trips.length > 0)
      : this.availability(),
  );

  // antes de comparar convertimos la fecha devuelta por el calendario a isostring y le quitamos los milis
  availabilitesByDate = computed(() =>
    this.selectedDate()
      ? this.availabilitiesByTagCode()?.filter(
          (a) => a.date === this.selectedDateFormated(),
        )
      : this.availabilitiesByTagCode(),
  );

  dates = computed(() => this.availabilitiesByTagCode()?.map((a) => a.date));
  trips = computed(() =>
    this.availabilitesByDate()
      ?.map((a) =>
        a.trips.map(
          (t): BookingServiceAvailableTrip => ({
            ...t,
            tagValue: this.labels()?.filter(
              (l) =>
                l.code === t.tagCode &&
                l.language &&
                toAppLanguage(l.language.languageCode) ===
                  this.languageService.language(),
            )[0],
          }),
        ),
      )
      .flat(),
  );
  tripsWithoutLanguageDuplicates = computed(() =>
    this.trips().reduce(
      (
        acc: BookingServiceAvailableTrip[],
        curr: BookingServiceAvailableTrip,
      ) =>
        acc.find((trip) => curr.tagCode && trip.tagCode === curr.tagCode)
          ? acc
          : [...acc, curr],
      [],
    ),
  );
  circularTrips = computed(() =>
    this.tripsWithoutLanguageDuplicates()?.filter(
      (t) => t.tripType === ProductReservationTripType.Circular,
    ),
  );
  departTrips = computed(() =>
    this.tripsWithoutLanguageDuplicates()?.filter(
      (t) => t.tripType === ProductReservationTripType.Depart,
    ),
  );
  returnTrips = computed(() =>
    this.tripsWithoutLanguageDuplicates()?.filter(
      (t) =>
        t.tripType === ProductReservationTripType.Return &&
        new Date(t.startDatetime).getTime() >=
          new Date(this.selectedDepartTrip()?.startDatetime).getTime() +
            minTimeReturnTripDiff,
    ),
  );

  showTripSelect = computed(
    () =>
      this.isDateSelected() &&
      this.availabilitesByDate()?.length > 0 &&
      this.reservationType() === ProductReservationType.MultipleTime,
  );
  showCircularTripSelect = computed(
    () =>
      this.availabilitesByDate()?.[0]?.routeType ===
      ProductReservationRouteType.OneWay,
  );
  showTagSelect = computed(() => this.labels()?.length > 0);

  isDateSelected = signal<boolean>(false);

  constructor() {
    this.initReservationForm();
    effect(() => console.log('availability: ', this.availability()));
    /*
    const today = new Date();
    const threeMonthLater = new Date();
    threeMonthLater.setMonth(today.getMonth() + 3);
    while (today <= threeMonthLater) {
      this.disableDates.push(new Date(today));
      today.setDate(today.getDate() + 1);
    }

    this.datesAvailables(this.dates());
    this.bsConfig = {
      showWeekNumbers: false,
      dateInputFormat: 'DD/MM/YYYY',
    };
    console.log('ENTRAA');
    console.log('jaume', this.bsConfig);
    */
  }
  /*
  datesAvailables(dates: string[]) {
    dates.forEach((date) => {
      const dateToRemove = new Date(date);
      this.disableDates = this.disableDates.filter(
        (date) => date.getDate() !== dateToRemove.getDate(),
      );
    });
  }
  */
  initReservationForm(): void {
    this.reservationForm = this.formBuilder.group({
      tagCode: ['', Validators.required],
      date: ['', Validators.required],
      departTrip: ['', Validators.required],
      returnTrip: [''],
    });

    this.reservationForm.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe((form) => {
        console.log('valueCHange', form);
        this.reservation.set({
          tagCode: form.tagCode,
          date: form.date,
          departTrip: form.departTrip,
          returnTrip: form.returnTrip,
        });
      });

    this.reservationForm.controls['tagCode'].valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.reservationForm.controls['date'].reset();
      });

    this.reservationForm.controls['date'].valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe((value) => {
        this.isDateSelected.set(value);
        this.reservationForm.controls['departTrip'].reset();
        this.reservationForm.controls['returnTrip'].reset();
      });

    this.reservationForm.controls['departTrip'].valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.reservationForm.controls['returnTrip'].reset();
      });

    this.selectedTagCode = toSignal(
      this.reservationForm.controls['tagCode'].valueChanges,
    );
    this.selectedDate = toSignal(
      this.reservationForm.controls['date'].valueChanges,
    );
    this.selectedDepartTrip = toSignal(
      this.reservationForm.controls['departTrip'].valueChanges,
    );
    this.selectedReturnTrip = toSignal(
      this.reservationForm.controls['returnTrip'].valueChanges,
    );
  }
  /*
  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  };
*/

  availabilityFilter = (d: Date | null): boolean => {
    const day = d?.getTime() || new Date().getTime();
    return (
      this.availability().filter(
        (availability) => new Date(availability.date).getTime() === day,
      )?.length > 0
    );
  };

  dispoDateClass = (d: Date): string => {
    const dateTime = d?.getTime() || new Date().getTime();
    console.log('date class', dateTime, d, this.availability());
    if (
      this.availability().filter(
        (availability) =>
          new Date(availability.date).getTime() === dateTime &&
          availability.trips.reduce(
            (total, trip) => total + trip.quantityAvailable,
            0,
          ) <= 20,
      )?.length > 0
    ) {
      return 'low-dispo';
    } else {
      return '';
    }
  };
}
