<iframe
  allowpaymentrequest="true"
  #redsysIFrame
  name="redsysIFrame"
  width="100%"
  height="800px"
  (load)="onLoadRedsysIFrame()"
>
</iframe>

<!-- Formulario para redsys (oculto) -->
<form
  #redsysPaymentForm
  id="redsysPaymentForm"
  name="redsysPaymentForm"
  [formGroup]="redsysForm"
  [action]="redsysUrl"
  method="POST"
  target="redsysIFrame"
>
  <input
    type="hidden"
    formControlName="Ds_SignatureVersion"
    name="Ds_SignatureVersion"
    id="Ds_SignatureVersion"
  />
  <input
    type="hidden"
    formControlName="Ds_MerchantParameters"
    name="Ds_MerchantParameters"
    id="Ds_MerchantParameters"
  />
  <input
    type="hidden"
    formControlName="Ds_Signature"
    name="Ds_Signature"
    id="Ds_Signature"
  />
</form>
