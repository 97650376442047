<section>
  <div class="detail-top-banner-noimg">
    <h2 class="title-contact">
      {{ "template.contact.page_title" | translate }}
    </h2>
    <p class="intro-contact">
      {{ "template.contact.contact_intro" | translate }}
    </p>
  </div>
</section>

<section class="contact_section">
  <div class="container-fluid home-products-container">
    <div class="row col-home">
      <div class="col-sm-12 col-md-6">
        <div class="contact-block">
          <div class="row">
            <div class="col-sm-12 col-md-4">
              <span class="ico phone"></span>
            </div>
            <div class="col-sm-12 col-md-8 phone-cont">
              <a rel="nofollow" href="tel:+34934655313" target="_blank">
                <b class="phone-title">{{
                  "template.contact.phone" | translate
                }}</b>
                <span class="phone-txt"
                  >+34 93 465 53 13 <br />
                  {{ "template.contact.call_info" | translate }}</span
                >
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="contact-block">
          <div class="row">
            <div class="col-sm-12 col-md-4">
              <span class="ico fb"></span>
            </div>
            <div class="col-sm-12 col-md-8 phone-cont">
              <a
                href="https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Ft%2Fholabarcelona.travelsolution"
                target="_blank"
              >
                <b class="phone-title">{{
                  "template.contact.facebook" | translate
                }}</b>
                <span class="phone-txt"
                  >{{ "template.contact.facebook_info" | translate }} <br />
                  <b
                    >{{ "template.contact.chat" | translate
                    }}<i class="fa fa-chevron-right"></i></b
                ></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="contact_section">
  <div class="container">
    <div class="row mrb50">
      <div class="col-md-12 col-lg-6 offset-lg-3">
        @if (succesMessage) {
          <div class="row">
            <div class="col-sm-12">
              <div class="input-box" style="padding-bottom: 0">
                <h3 class="title">
                  {{ "template.contact.contact_form" | translate }}
                </h3>
                <div class="success-message">
                  <span class="ico check-success"></span>
                  <span>{{ succesMessage }}</span>
                </div>
                @if (contactDisabled) {
                  <p class="intro">
                    {{ "template.contact.contact_intro" | translate }}
                  </p>
                } @else {
                  <p class="intro">
                    {{ "template.contact.contact_disabled" | translate }}
                  </p>
                }
              </div>
            </div>
          </div>
        } @else {
          <form [formGroup]="form" (ngSubmit)="contact()">
            <div class="row">
              <div class="col-sm-12">
                <div class="input-box" style="padding-bottom: 0">
                  <h3 class="title">
                    {{ "template.contact.contact_form" | translate }}
                  </h3>
                  @if (contactDisabled) {
                    <p class="intro">
                      {{ "template.contact.contact_intro" | translate }}
                    </p>
                  } @else {
                    <p class="intro">
                      {{ "template.contact.contact_disabled" | translate }}
                    </p>
                  }
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="input-box">
                  <div
                    class="form-group"
                    [ngClass]="{
                      'input-filled': f['name'].value !== '' || nameFocus
                    }"
                  >
                    <label class="input-label-animate inp-lab" for="name"
                      ><span class="input-label-content">{{
                        "template.contact.first_name" | translate
                      }}</span></label
                    >
                    <input
                      (focus)="onFocus('name')"
                      (blur)="onBlur('name')"
                      id="name"
                      type="text"
                      formControlName="name"
                      class="form-control input-field"
                      [ngClass]="{
                        'is-invalid error': submitted && f['name'].errors,
                        valid: submitted && !f['name'].errors
                      }"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="input-box">
                  <div
                    class="form-group"
                    [ngClass]="{
                      'input-filled':
                        f['surname1'].value !== '' || surname1Focus
                    }"
                  >
                    <label class="input-label-animate inp-lab" for="surname1"
                      ><span class="input-label-content">{{
                        "template.contact.last_name_1" | translate
                      }}</span></label
                    >
                    <input
                      (focus)="onFocus('surname1')"
                      (blur)="onBlur('surname1')"
                      id="surname1"
                      type="text"
                      formControlName="surname1"
                      class="form-control input-field"
                      [ngClass]="{
                        'is-invalid error': submitted && f['surname1'].errors,
                        valid: submitted && !f['surname1'].errors
                      }"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="input-box">
                  <div
                    class="form-group"
                    [ngClass]="{
                      'input-filled':
                        f['surname2'].value !== '' || surname2Focus
                    }"
                  >
                    <label class="input-label-animate inp-lab" for="surname2"
                      ><span class="input-label-content">{{
                        "template.contact.last_name_2" | translate
                      }}</span></label
                    >
                    <input
                      (focus)="onFocus('surname2')"
                      (blur)="onBlur('surname2')"
                      id="surname2"
                      type="text"
                      formControlName="surname2"
                      class="form-control input-field"
                      [ngClass]="{
                        'is-invalid error': submitted && f['surname2'].errors,
                        valid: submitted && !f['surname2'].errors
                      }"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="input-box">
                  <div
                    class="form-group"
                    [ngClass]="{
                      'input-filled':
                        f['language'].value !== '' || languageFocus
                    }"
                  >
                    <label
                      class="input-label-animate inp-lab"
                      for="customerType"
                      ><span class="input-label-content">
                        {{ "template.contact.language_title" | translate }}
                      </span></label
                    >
                    <select
                      id="language"
                      (focus)="onFocus('language')"
                      (blur)="onBlur('language')"
                      type="text"
                      formControlName="language"
                      class="form-control input-field"
                      [ngClass]="{
                        'is-invalid error': submitted && f['language'].errors,
                        valid: submitted && !f['language'].errors
                      }"
                    >
                      @for (locale of locales(); track $index) {
                        <option [value]="locale">
                          {{ locale.toLowerCase() }}
                        </option>
                      }
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="input-box">
                  <div
                    class="form-group"
                    [ngClass]="{
                      'input-filled': f['country'].value !== '' || countryFocus
                    }"
                  >
                    <label class="input-label-animate inp-lab" for="country"
                      ><span class="input-label-content">
                        {{ "template.contact.country_title" | translate }}
                      </span></label
                    >
                    <select
                      id="country"
                      (focus)="onFocus('country')"
                      (blur)="onBlur('country')"
                      type="text"
                      formControlName="country"
                      class="form-control input-field"
                      [ngClass]="{
                        'is-invalid error': submitted && f['country'].errors,
                        valid: submitted && !f['country'].errors
                      }"
                    >
                      @for (country of countries(); track $index) {
                        <option [value]="country.codeAlfa2">
                          {{ country.name }}
                        </option>
                      }
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="input-box">
                  <div
                    class="form-group"
                    [ngClass]="{
                      'input-filled': f['email'].value !== '' || emailFocus
                    }"
                  >
                    <label class="input-label-animate inp-lab" for="email"
                      ><span class="input-label-content">{{
                        "template.voucher_recover.mail" | translate
                      }}</span></label
                    >
                    <input
                      (focus)="onFocus('email')"
                      (blur)="onBlur('email')"
                      id="email"
                      type="text"
                      formControlName="email"
                      class="form-control input-field"
                      [ngClass]="{
                        'is-invalid error': submitted && f['email'].errors,
                        valid: submitted && !f['email'].errors
                      }"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="input-box">
                  <div
                    class="form-group"
                    [ngClass]="{
                      'input-filled':
                        f['shortDescription'].value !== '' ||
                        shortDescriptionFocus
                    }"
                  >
                    <label
                      class="input-label-animate inp-lab"
                      for="shortDescription"
                      ><span class="input-label-content">{{
                        "template.contact.title" | translate
                      }}</span></label
                    >
                    <input
                      (focus)="onFocus('shortDescription')"
                      (blur)="onBlur('shortDescription')"
                      id="shortDescription"
                      type="text"
                      formControlName="shortDescription"
                      class="form-control input-field"
                      [ngClass]="{
                        'is-invalid error':
                          submitted && f['shortDescription'].errors,
                        valid: submitted && !f['shortDescription'].errors
                      }"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="input-box">
                  <div
                    class="form-group"
                    [ngClass]="{
                      'input-filled':
                        f['longDescription'].value !== '' ||
                        longDescriptionFocus
                    }"
                  >
                    <label
                      class="input-label-animate inp-lab"
                      for="longDescription"
                      ><span class="input-label-content">{{
                        "template.contact.content" | translate
                      }}</span></label
                    >
                    <textarea
                      (focus)="onFocus('longDescription')"
                      (blur)="onBlur('longDescription')"
                      id="longDescription"
                      type="text"
                      formControlName="longDescription"
                      class="form-control input-field"
                      [ngClass]="{
                        'is-invalid error':
                          submitted && f['longDescription'].errors,
                        valid: submitted && !f['longDescription'].errors
                      }"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="input-box">
                  <h3 class="attachments" id="add-file">
                    {{ "template.contact.attachments" | translate }}
                    <i class="fa fa-plus"></i>
                  </h3>
                  <p class="attachments_info">
                    {{
                      "template.contact.attachments_advice_formats" | translate
                    }}
                    {{ "template.contact.attachments_advice_size" | translate }}
                  </p>
                  <span class="input-item">
                    <div id="files-wrapper">
                      <div class="file-item">
                        <!--TODO: Adjuntar archivo-->
                        <input type="file" />
                        <span class="filename"></span>
                        <button class="remove-file pull-right">X</button>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="input-box">
                  <!--TODO: Tal y como están estructuradas las etiquetas no me vale el innerHtml para interpretar los htmls de la etiqueta "template.payment.information_data_protection" -->
                  <p class="label_read_and_accept">
                    {{
                      "template.payment.information_data_protection" | translate
                    }}
                    <!--TODO: Calcular href según idioma-->
                    <a
                      href="https://www.tmb.cat/en/personal-data-protection-policy#content-title-transport-users"
                      target="_blank"
                      class="color-secundary"
                      >{{
                        "template.payment.information_data_protection_1"
                          | translate
                      }}</a
                    >
                    {{
                      "template.payment.information_data_activities" | translate
                    }}
                    <!--TODO: Calcular href según idioma-->
                    <a
                      href="https://www.tmb.cat/en/registration-personal-data-processing-activities#24"
                      target="_blank"
                      class="color-secundary"
                      >{{
                        "template.payment.information_data_activities_1"
                          | translate
                      }}</a
                    >.
                  </p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="input-check-box">
                  <label class="label_read_and_accept">
                    <input
                      type="checkbox"
                      formControlName="extraInformation"
                      class="form-check-input"
                    />
                    <span class="border-check" tabindex="18">
                      {{ "template.payment.extra_information" | translate }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="input-check-box pull-right" style="clear: both">
                  <button
                    type="submit"
                    class="button-pay btn-secundary cursor-not-allowed red ch-btn-checkout"
                  >
                    {{ "template.contact.submit" | translate }}
                  </button>
                  <!--TODO: Captcha-->
                </div>
              </div>
            </div>

            @if (errorMessage) {
              <div class="row">
                <div class="col-sm-12">
                  <span class="alert alert-danger"> {{ errorMessage }} </span>
                </div>
              </div>
            }
          </form>
        }
      </div>
    </div>
  </div>
</section>
