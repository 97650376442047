import { Component, Input, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SuperProduct } from '../../../../shared/models/super-product.interface';
import { LanguageService } from '../../../../shared/services/language.service';
import { AccordionImage } from './models/accordion-images.interface';
import { AccordionTab } from './models/accordion-tabs.interface';

@Component({
  selector: 'hola-product-module-accordion',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './product-module-accordion.component.html',
  styleUrl: './product-module-accordion.component.scss',
})
export class ProductModuleAccordionComponent {
  @Input() superProduct!: SuperProduct;
  private readonly languageService = inject(LanguageService);

  public locale = this.languageService.locale;
  /* TODO: Obtener datos del producto*/
  public title = 'TITULO';
  public tabs: AccordionTab[] = [
    {
      title_class: 'class1',
      title: 'Title 1',
      description: 'Description for title 1',
      title_link: 'Title Link 1',
      link: 'Link 1',
    },
    {
      title_class: 'class2',
      title: 'Title 2',
      description: 'Description for title 2',
      title_link: 'Title Link 2',
      link: 'Link 2',
    },
  ];
  public images: AccordionImage[] = [
    {
      url: 'https://pro.static.holabarcelona.com/images/1587/resize/275/406/5.jpg',
      key: '1',
    },
    {
      url: 'https://pro.static.holabarcelona.com/images/1592/resize/275/406/5.jpg',
      key: '2',
    },
  ];
}
