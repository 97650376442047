import { Component, computed, inject, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CustomCurrencyPipe } from '../../shared/pipes/custom-currency.pipe';
import { LanguageService } from '../../shared/services/language.service';
import { SuperProductService } from '../../shared/services/super-product.service';
import { ProductCardWidgetConfiguration } from './models/product-card-widget-configuration.interface';

@Component({
  selector: 'hola-product-card-widget',
  standalone: true,
  imports: [TranslateModule, CustomCurrencyPipe],
  templateUrl: './product-card-widget.component.html',
  styleUrl: './product-card-widget.component.scss',
})
export class ProductCardWidgetComponent {
  public readonly configuration =
    input.required<ProductCardWidgetConfiguration>();
  private readonly languageService = inject(LanguageService);

  private readonly superProductService = inject(SuperProductService);
  public readonly locale = this.languageService.locale;

  public superProducts = computed(() =>
    this.configuration().productCodes.map((code) =>
      this.superProductService.getSuperProductByCode(code),
    ),
  );
}
