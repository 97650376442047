@if (configuration().android !== "" || configuration().ios !== "") {
  <div class="app-buttons-block">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-sm-7 col-app-buttons">
          @if (configuration().android !== "") {
            <a href="{{ configuration().android }}" target="_blank"
              ><img
                alt="ANDROID"
                src="/assets/images/promo-app-widget/google-play-badge.png"
            /></a>
          }
          @if (configuration().ios !== "") {
            <a href="{{ configuration().ios }}" target="_blank"
              ><img
                alt="IOS"
                src="/assets/images/promo-app-widget/download-on-the-app-store-apple-1.png"
            /></a>
          }
        </div>
      </div>
    </div>
  </div>
}
