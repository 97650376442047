import { Component, Signal, computed, inject, input } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';
import { filter, take } from 'rxjs';
import { ApiStatus } from '../../shared/enums/api-status.enum';
import { StrapiSuperProductsGroupCode } from '../../shared/enums/strapi-super-products-group-code.enum';
import { SuperProductsGroupProductType } from '../../shared/enums/super-products-group-product-type.enum';
import { GtmDataLayerService } from '../../shared/gtm/gtm.service';
import { HolaProduct } from '../../shared/models/hola-product.interface';
import { SuperProduct } from '../../shared/models/super-product.interface';
import { SuperProductsGroupProduct } from '../../shared/models/super-products-group-product.interface';
import { SuperProductsGroup } from '../../shared/models/super-products-group.interface';
import { CustomCurrencyPipe } from '../../shared/pipes/custom-currency.pipe';
import { LanguageService } from '../../shared/services/language.service';
import { NavigationService } from '../../shared/services/navigation.service';
import { SuperProductService } from '../../shared/services/super-product.service';
import { SuperProductsGroupService } from '../../shared/services/super-products-group.service';
import { HomeProductsWidgetConfiguration } from './models/home-products-widget-configuration.interface';

@Component({
  selector: 'hola-home-products-widget',
  standalone: true,
  imports: [CustomCurrencyPipe, TranslateModule],
  templateUrl: './home-products-widget.component.html',
  styleUrl: './home-products-widget.component.scss',
})
export class HomeProductsWidgetComponent {
  public configuration = input.required<HomeProductsWidgetConfiguration>();

  private readonly languageService = inject(LanguageService);
  private readonly superProductService = inject(SuperProductService);
  private readonly superProductsGroupService = inject(
    SuperProductsGroupService,
  );
  private readonly navigationService = inject(NavigationService);
  private readonly gtmDataLayerService = inject(GtmDataLayerService);

  public ApiStatus = ApiStatus;
  public locale = this.languageService.locale;

  public labelDiscount = '';
  public superProductsGroup: Signal<SuperProductsGroup> = computed(() =>
    this.superProductsGroupService.getSuperProductsGroupByCode(
      StrapiSuperProductsGroupCode.Home,
    ),
  );

  public superProductsGroupProducts: Signal<SuperProductsGroupProduct[]> =
    computed(
      () =>
        this.superProductsGroup()?.products?.filter((product) =>
          this.superProductService
            .superProducts()
            .find(
              (p) =>
                product.code === p.code ||
                product.type ===
                  SuperProductsGroupProductType.SuperProductGroup,
            ),
        ) || [],
    );

  public products: Signal<HolaProduct[]> = computed(() =>
    this.superProductsGroupProducts().map((p, index) => {
      const product =
        p.type === SuperProductsGroupProductType.SuperProduct
          ? this.superProductService.getSuperProductByCode(p.code)
          : this.superProductsGroupService.getSuperProductsGroupByCode(p.code);
      return {
        code: product.code,
        title: product.title,
        description: product.description,
        shortDescription: product.shortDescription,
        imageUrl: product.mainImageUrl,
        analyticsId: product.analyticsId,
        analyticsName: product.analyticsName,
        analyticsCategory: product.analyticsCategory,
        activePrice: product.activePrice,
        reducedPrice: product.reducedPrice,
        fixDiscount: product.fixDiscount,
        percentageDiscount: product.percentageDiscount,
        active: product.active,
        visible: product.visible,
        onClick:
          p.type === SuperProductsGroupProductType.SuperProduct
            ? () => {
                this.gtmDataLayerService.productClick(
                  product as SuperProduct,
                  StrapiSuperProductsGroupCode.Home,
                  index,
                );
                this.navigationService.navigateByUrl(
                  `/${this.languageService.language()}/tickets/${(product as SuperProduct).slug}`,
                );
              }
            : // this.navigationService.navigateByUrl(
              //   `/${this.languageService.language()}/tickets/${(product as SuperProduct).slug}`,
              // )
              () =>
                this.navigationService.navigateToSuperProductsGroup(
                  p.code as StrapiSuperProductsGroupCode,
                ),
      };
    }),
  );

  private products$ = toObservable(this.products);

  constructor() {
    this.products$
      .pipe(
        filter((p) => p?.length > 0),
        take(1),
      )
      .subscribe((products) =>
        this.gtmDataLayerService.impressions(
          products,
          StrapiSuperProductsGroupCode.Home,
        ),
      );
  }
}
