<div class="col-sm-12">
  <h2
    class="discover-item-box collapsed"
    data-bs-toggle="collapse"
    data-bs-target="#discounts_list"
    aria-expanded="false"
    aria-controls="#discounts_list"
  >
    *{{ "template.cart.discount.concepts_title" | translate }}
    <span class="ic-anim-down-close"></span>
  </h2>
</div>
<div class="container">
  <!-- <div class="container js-extra-info-container"> -->
  <div id="discounts_list" class="col-sm-12 disc-extra-info-box collapse">
    @for (discountConcept of itemDiscountConcepts(); track $index) {
      <div class="row row-discount-code code-valid ch-product-item">
        <div class="col-sm-12 col-md-12 col-lg-12 apply-discount">
          <h2 class="ch-list-discount-name">
            <!--
                TODO: Mirar como son los translates con parametros
              -->
          </h2>
          <span>
            {{ "template.cart.coupon.code_applied" | translate }}
            <b style="color: #229fff">
              {{ discountConcept.discountName }}
            </b>
          </span>

          @for (concept of discountConcept.productConcepts; track $index) {
            <div>
              <span>- {{ concept.percentage * 100 }} %</span>
              (
              <span style="color: #d9027d">
                {{ concept.amount }}
              </span>
              )
              {{ "template.cart.discount.global_discount" | translate }}
            </div>
          }
        </div>
      </div>
    }
  </div>
</div>
