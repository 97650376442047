import { NgClass } from '@angular/common';
import { Component, input, signal } from '@angular/core';
import { AvisWidgetConfiguration } from './models/avis-widget-configuration.interface';

@Component({
  selector: 'hola-avis-widget',
  standalone: true,
  imports: [NgClass],
  templateUrl: './avis-widget.component.html',
  styleUrl: './avis-widget.component.scss',
})
export class AvisWidgetComponent {
  configuration = input.required<AvisWidgetConfiguration>();
  isVisible = signal(true);

  hide() {
    this.isVisible.set(false);
  }
}
