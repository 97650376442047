@if (isVisible()) {
  <button class="overlay" (click)="hide()" aria-label="Close modal"></button>
  <div class="modal">
    <button class="close-btn" (click)="hide()">X</button>
    <!-- Aquí puedes poner el contenido de tu modal -->

    <div
      class="modal right"
      id="modal-cart"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel2"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            @if (productsQuantity() === 0) {
              <div class="row titulos-carrito">
                <div class="col">
                  <h4 class="modal-title" id="myModalLabel2">
                    {{ "template.cart.menu.title" | translate }}
                  </h4>
                  <h5 class="modal-title" id="myModalLabel2">
                    {{ "template.cart.menu.empty_cart" | translate }}
                  </h5>
                  <h6 class="modal-title" id="myModalLabel2">
                    {{ "template.cart.menu.empty_cart_subtitle" | translate }}
                  </h6>
                </div>
              </div>
              <!-- row-totales -->
              <div class="row row-total">
                <div class="col">
                  <a
                    [routerLink]="homeUrl()"
                    (click)="hide()"
                    class="btn-block btn btn-outline-primary continue_shopping"
                    >{{ "template.cart.menu.buy_now" | translate }}</a
                  >
                </div>
              </div>
              <!-- row-totales -->
            } @else {
              <div class="row titulos-carrito">
                <div class="col">
                  <h4 class="modal-title" id="myModalLabel2">
                    {{ "template.cart.title" | translate }}
                  </h4>
                  @if (productsQuantity() === 0) {
                    <span class="productos-anyadidos"
                      >{{ productsQuantity() }}
                      {{ "template.cart.menu.products" | translate }}
                    </span>
                  } @else {
                    <span class="productos-anyadidos"
                      >{{ productsQuantity() }}
                      {{ "template.cart.menu.product" | translate }}
                    </span>
                  }
                </div>
              </div>
              <!-- TODO: Dar vueltas a los productos -->
              @for (
                cartSummaryProductsGroup of cartSummaryProductsGroups();
                track $index
              ) {
                <div class="row row-producto">
                  <div class="col-5 col-sm-5 col-md-5 col-lg-5">
                    <img
                      class="img-producto img-fluid"
                      alt="Producto"
                      src="/assets/images/home-widget/5.jpg"
                    />
                  </div>
                  <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                    <span class="titulo-producto">
                      {{ cartSummaryProductsGroup.name }}
                    </span>
                    @for (
                      cartSummaryProduct of cartSummaryProductsGroup.products;
                      track $index
                    ) {
                      <span class="linea-producto">
                        <span class="cantidad"
                          >{{ cartSummaryProduct.quantity }}
                          {{ cartSummaryProduct.product.name }}</span
                        >
                        <span class="precio">{{
                          cartSummaryProduct.reducedPrice
                            | customCurrency: locale()
                        }}</span>
                      </span>
                    }
                  </div>
                </div>
              }

              <!-- TODO: calcular totales -->
              <div class="row row-total">
                <div class="col-5 col-sm-5 col-md-5 col-lg-5">
                  <h3>{{ "template.cart.total" | translate }}</h3>
                </div>
                <div class="col-6 col-sm-6 col-md-6 col-lg-6 container-precios">
                  <span class="precio-total">
                    {{ orderReducedPrice() | customCurrency: locale() }}
                  </span>
                  @if (orderActivePrice() > orderReducedPrice()) {
                    <span class="precio-total-tachado">
                      {{ orderActivePrice() | customCurrency: locale() }}
                    </span>
                  }
                </div>
                <div class="col">
                  <a
                    [routerLink]="homeUrl()"
                    (click)="hide()"
                    class="btn-block btn btn-outline-primary continue_shopping"
                    >{{ "template.cart.continue_shopping" | translate }}</a
                  >
                  <a
                    rel="“nofollow”"
                    [routerLink]="cartUrl()"
                    (click)="hide()"
                    class="btn-block btn btn-primary"
                    >{{ "template.cart.checkout" | translate }}</a
                  >
                </div>
              </div>
              <!-- row-totales -->
            }
          </div>
        </div>
        <!-- modal-content -->
      </div>
      <!-- modal-dialog -->
    </div>
    <!-- modal carrito-->
  </div>
}
