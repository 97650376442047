import { environment } from '../../../environments/environment';
import { AboutWidgetConfigurationApi } from '../../page-widget/about-widget/models/about-widget-configuration-api.interface';
import { AboutWidgetConfiguration } from '../../page-widget/about-widget/models/about-widget-configuration.interface';
import { AppDownloadButtonsWidgetConfigurationApi } from '../../page-widget/app-download-buttons-widget/models/app-download-buttons-widget-configuration-api.interface';
import { AppDownloadButtonsWidgetConfiguration } from '../../page-widget/app-download-buttons-widget/models/app-download-buttons-widget-configuration.interface';
import { AvisWidgetConfigurationApi } from '../../page-widget/avis-widget/models/avis-widget-configuration-api.interface';
import { AvisWidgetConfiguration } from '../../page-widget/avis-widget/models/avis-widget-configuration.interface';
import { BlogWidgetConfigurationApi } from '../../page-widget/blog-widget/models/blog-widget-configuration-api.interface';
import { BlogWidgetConfiguration } from '../../page-widget/blog-widget/models/blog-widget-configuration.interface';
import { CartWidgetConfigurationApi } from '../../page-widget/cart-widget/models/cart-widget-configuration-api.interface';
import { CartWidgetConfiguration } from '../../page-widget/cart-widget/models/cart-widget-configuration.interface';
import { CheckoutWidgetConfigurationApi } from '../../page-widget/checkout-widget/models/checkout-widget-configuration-api.interface';
import { CheckoutWidgetConfiguration } from '../../page-widget/checkout-widget/models/checkout-widget-configuration.interface';
import { ContactWidgetConfigurationApi } from '../../page-widget/contact-widget/models/contact-widget-configuration-api.interface';
import { ContactWidgetConfiguration } from '../../page-widget/contact-widget/models/contact-widget-configuration.interface';
import { CookiesWidgetConfigurationApi } from '../../page-widget/cookies-widget/models/cookies-widget-configuration-api.interface';
import { CookiesWidgetConfiguration } from '../../page-widget/cookies-widget/models/cookies-widget-configuration.interface';
import { DiscoverCatalunyaWidgetConfigurationApi } from '../../page-widget/discover-catalunya-widget/models/discover-catalunya-widget-configuration-api.interface';
import { DiscoverCatalunyaWidgetConfiguration } from '../../page-widget/discover-catalunya-widget/models/discover-catalunya-widget-configuration.interface';
import { DiscoverWidgetConfigurationApi } from '../../page-widget/discover-widget/models/discover-widget-configuration-api.interface';
import { DiscoverWidgetConfiguration } from '../../page-widget/discover-widget/models/discover-widget-configuration.interface';
import { FaqWidgetConfigurationApi } from '../../page-widget/faq-widget/models/faq-widget-configuration-api.interface';
import { FaqWidgetConfigurationTabApi } from '../../page-widget/faq-widget/models/faq-widget-configuration-tab-api.interface';
import { FaqWidgetConfiguration } from '../../page-widget/faq-widget/models/faq-widget-configuration.interface';
import { FooterWidgetConfigurationApi } from '../../page-widget/footer-widget/models/footer-widget-configuration-api.interface';
import { FooterWidgetConfiguration } from '../../page-widget/footer-widget/models/footer-widget-configuration.interface';
import { FreeHtmlWidgetConfigurationApi } from '../../page-widget/free-html-widget/models/free-html-widget-configuration-api.interface';
import { FreeHtmlWidgetConfiguration } from '../../page-widget/free-html-widget/models/free-html-widget-configuration.interface';
import { GeneralAvisWidgetConfigurationApi } from '../../page-widget/general-avis-widget/models/general-avis-widget-configuration-api.interface';
import { GeneralAvisWidgetConfiguration } from '../../page-widget/general-avis-widget/models/general-avis-widget-configuration.interface';
import { HeaderPrincipalImageWidgetConfigurationApi } from '../../page-widget/header-principal-image-widget/models/header-principal-image-widget-configuration-api.interface';
import { HeaderPrincipalImageWidgetConfiguration } from '../../page-widget/header-principal-image-widget/models/header-principal-image-widget-configuration.interface';
import { HeaderWidgetConfigurationApi } from '../../page-widget/header-widget/models/header-widget-configuration-api.interface';
import { HeaderWidgetConfiguration } from '../../page-widget/header-widget/models/header-widget-configuration.interface';
import { HomeIntroWidgetConfigurationApi } from '../../page-widget/home-intro-widget/models/home-intro-widget-configuration-api.interface';
import { HomeIntroWidgetConfiguration } from '../../page-widget/home-intro-widget/models/home-intro-widget-configuration.interface';
import { HomeProductsWidgetConfigurationApi } from '../../page-widget/home-products-widget/models/home-products-widget-configuration-api.interface';
import { HomeProductsWidgetConfiguration } from '../../page-widget/home-products-widget/models/home-products-widget-configuration.interface';
import { InfoAlertWidgetConfigurationApi } from '../../page-widget/info-alert-widget/models/info-alert-widget-configuration-api.interface';
import { InfoAlertWidgetConfiguration } from '../../page-widget/info-alert-widget/models/info-alert-widget-configuration.interface';
import { LegalAdviceSignupWidgetConfigurationApi } from '../../page-widget/legal-advice-signup-widget/models/legal-advice-signup-widget-configuration-api.interface';
import { LegalAdviceSignupWidgetConfiguration } from '../../page-widget/legal-advice-signup-widget/models/legal-advice-signup-widget-configuration.interface';
import { LegalWidgetConfigurationApi } from '../../page-widget/legal-widget/models/legal-widget-configuration-api.interface';
import { LegalWidgetConfiguration } from '../../page-widget/legal-widget/models/legal-widget-configuration.interface';
import { LinkCardsWidgetConfigurationApi } from '../../page-widget/link-cards-widget/models/link-cards-widget-configuration-api.interface';
import { LinkCardsWidgetConfiguration } from '../../page-widget/link-cards-widget/models/link-cards-widget-configuration.interface';
import { MenuWidgetConfigurationApi } from '../../page-widget/menu-widget/models/menu-widget-configuration-api.interface';
import { MenuWidgetConfiguration } from '../../page-widget/menu-widget/models/menu-widget-configuration.interface';
import { MoreInformationWidgetConfigurationApi } from '../../page-widget/more-information-widget/models/more-information-widget-configuration-api.interface';
import { MoreInformationWidgetConfiguration } from '../../page-widget/more-information-widget/models/more-information-widget-configuration.interface';
import { MouteAmbHolaWidgetConfigurationApi } from '../../page-widget/moute-amb-hola-widget/models/moute-amb-hola-widget-configuration-api.interface';
import { MouteAmbHolaWidgetConfiguration } from '../../page-widget/moute-amb-hola-widget/models/moute-amb-hola-widget-configuration.interface';
import { OpinionesVerificadasCarrouselWidgetConfigurationApi } from '../../page-widget/opiniones-verificadas-carrousel-widget/models/opiniones-verificadas-carrousel-widget-configuration-api.interface';
import { OpinionesVerificadasCarrouselWidgetConfiguration } from '../../page-widget/opiniones-verificadas-carrousel-widget/models/opiniones-verificadas-carrousel-widget-configuration.interface';
import { PackWidgetConfigurationApi } from '../../page-widget/pack-widget/models/pack-widget-configuration-api.interface';
import { PackWidgetConfiguration } from '../../page-widget/pack-widget/models/pack-widget-configuration.interface';
import { ProductCardWidgetConfigurationApi } from '../../page-widget/product-card-widget/models/product-card-widget-configuration-api.interface';
import { ProductCardWidgetConfiguration } from '../../page-widget/product-card-widget/models/product-card-widget-configuration.interface';
import { ProductDetailWidgetConfigurationApi } from '../../page-widget/product-detail-widget/models/product-detail-widget-configuration-api.interface';
import { ProductDetailWidgetConfiguration } from '../../page-widget/product-detail-widget/models/product-detail-widget-configuration.interface';
import { PromoAppWidgetConfigurationApi } from '../../page-widget/promo-app-widget/models/promo-app-widget-configuration-api.interface';
import { PromoAppWidgetConfiguration } from '../../page-widget/promo-app-widget/models/promo-app-widget-configuration.interface';
import { PromoWidgetConfigurationApi } from '../../page-widget/promo-widget/models/promo-widget-configuration-api.interface';
import { PromoWidgetConfiguration } from '../../page-widget/promo-widget/models/promo-widget-configuration.interface';
import { PromotionWidgetConfigurationApi } from '../../page-widget/promotion-widget/models/promotion-widget-configuration-api.interface';
import { PromotionWidgetConfiguration } from '../../page-widget/promotion-widget/models/promotion-widget-configuration.interface';
import { RecoverWidgetConfigurationApi } from '../../page-widget/recover-widget/models/recover-widget-configuration-api.interface';
import { RecoverWidgetConfiguration } from '../../page-widget/recover-widget/models/recover-widget-configuration.interface';
import { SectionIntroWidgetConfigurationApi } from '../../page-widget/section-intro-widget/models/section-intro-widget-configuration-api.interface';
import { SectionIntroWidgetConfiguration } from '../../page-widget/section-intro-widget/models/section-intro-widget-configuration.interface';
import { ShowInfoWidgetConfigurationApi } from '../../page-widget/show-info-widget/models/show-info-widget-configuration-api.interface';
import { ShowInfoWidgetConfiguration } from '../../page-widget/show-info-widget/models/show-info-widget-configuration.interface';
import { SightseeingWidgetConfigurationApi } from '../../page-widget/sightseeing-widget/models/sightseeing-widget-configuration-api.interface';
import { SightseeingWidgetConfiguration } from '../../page-widget/sightseeing-widget/models/sightseeing-widget-configuration.interface';
import { SitemapWidgetConfigurationApi } from '../../page-widget/sitemap-widget/models/sitemap-widget-configuration-api.interface';
import { SitemapWidgetConfiguration } from '../../page-widget/sitemap-widget/models/sitemap-widget-configuration.interface';
import { SliderWidgetConfigurationApi } from '../../page-widget/slider-widget/models/slider-widget-configuration-api.interface';
import { SliderWidgetConfiguration } from '../../page-widget/slider-widget/models/slider-widget-configuration.interface';
import { TermsAndConditionsWidgetConfigurationApi } from '../../page-widget/terms-and-conditions-widget/models/terms-and-conditions-widget-configuration-api.interface';
import { TermsAndConditionsWidgetConfiguration } from '../../page-widget/terms-and-conditions-widget/models/terms-and-conditions-widget-configuration.interface';
import { ThanksWidgetConfigurationApi } from '../../page-widget/thanks-widget/models/thanks-widget-configuration-api.interface';
import { ThanksWidgetConfiguration } from '../../page-widget/thanks-widget/models/thanks-widget-configuration.interface';
import { TransportDealsLandingWidgetConfigurationApi } from '../../page-widget/transport-deals-landing-widget/models/transport-deals-landing-widget-configuration-api.interface';
import { TransportDealsLandingWidgetConfiguration } from '../../page-widget/transport-deals-landing-widget/models/transport-deals-landing-widget-configuration.interface';
import { UnsubscribeMessageWidgetConfigurationApi } from '../../page-widget/unsubscribe-message-widget/models/unsubscribe-message-widget-configuration-api.interface';
import { UnsubscribeMessageWidgetConfiguration } from '../../page-widget/unsubscribe-message-widget/models/unsubscribe-message-widget-configuration.interface';
import { UnsubscribeWidgetConfigurationApi } from '../../page-widget/unsubscribe-widget/models/unsubscribe-widget-configuration-api.interface';
import { UnsubscribeWidgetConfiguration } from '../../page-widget/unsubscribe-widget/models/unsubscribe-widget-configuration.interface';
import { AppLanguage } from '../../shared/enums/app-language.enum';
import { WidgetImageResize } from '../models/widget-image-resize.interface';

export function transformAboutWidgetConfiguration(
  configuration: AboutWidgetConfigurationApi,
  language: AppLanguage,
): AboutWidgetConfiguration {
  return {
    title: configuration.title[language],
    content: configuration.content[language],
  };
}
export function transformAppDownloadButtonsWidgetConfiguration(
  configuration: AppDownloadButtonsWidgetConfigurationApi,
  language: AppLanguage,
): AppDownloadButtonsWidgetConfiguration {
  return {
    android: configuration.android[language],
    ios: configuration.ios[language],
  };
}
export function transformAvisWidgetConfiguration(
  configuration: AvisWidgetConfigurationApi,
  language: AppLanguage,
): AvisWidgetConfiguration {
  return {} as AvisWidgetConfiguration;
}
export function transformBlogWidgetConfiguration(
  configuration: BlogWidgetConfigurationApi,
  language: AppLanguage,
): BlogWidgetConfiguration {
  return {} as BlogWidgetConfiguration;
}
export function transformCartWidgetConfiguration(
  configuration: CartWidgetConfigurationApi,
  language: AppLanguage,
): CartWidgetConfiguration {
  return {} as CartWidgetConfiguration;
}
export function transformCheckoutWidgetConfiguration(
  configuration: CheckoutWidgetConfigurationApi,
  language: AppLanguage,
): CheckoutWidgetConfiguration {
  return {} as CheckoutWidgetConfiguration;
}
export function transformContactWidgetConfiguration(
  configuration: ContactWidgetConfigurationApi,
  language: AppLanguage,
): ContactWidgetConfiguration {
  return {} as ContactWidgetConfiguration;
}
export function transformCookiesWidgetConfiguration(
  configuration: CookiesWidgetConfigurationApi,
  language: AppLanguage,
): CookiesWidgetConfiguration {
  return {} as CookiesWidgetConfiguration;
}
export function transformDiscoverWidgetConfiguration(
  configuration: DiscoverWidgetConfigurationApi,
  language: AppLanguage,
): DiscoverWidgetConfiguration {
  return {} as DiscoverWidgetConfiguration;
}
export function transformDiscoverCatalunyaWidgetConfiguration(
  configuration: DiscoverCatalunyaWidgetConfigurationApi,
  language: AppLanguage,
): DiscoverCatalunyaWidgetConfiguration {
  return {} as DiscoverCatalunyaWidgetConfiguration;
}
export function transformFaqWidgetConfiguration(
  configuration: FaqWidgetConfigurationApi,
  language: AppLanguage,
  imageResize: WidgetImageResize,
): FaqWidgetConfiguration {
  const faqTabsApiResponse: FaqWidgetConfigurationTabApi[] = [];
  for (let index = 0; index < +configuration.numTabs; index++) {
    const faqTabApiResponse = configuration[`tab${index + 1}`];
    if (faqTabApiResponse) {
      faqTabsApiResponse.push(faqTabApiResponse);
    }
  }
  // TODO refactorizar para evitar código duplicado
  const imageIds = configuration.imageIds.split(',');
  const images = imageIds
    .filter((id) => id)
    .map((id) => ({
      id,
      url: getImageUrl(+id, imageResize),
    }));
  console.log('============ WIDGET FAQ', language, configuration, {
    imageIds,
    title: configuration.title[language],
    headerTitle: configuration.headerTitle[language],
    footerTitle: configuration.footertitle[language],
    footerSubtitle: configuration.footersubtitle[language],
    footerButton: configuration.footerbutton[language],
    buttonPath: configuration.buttonpath[language],
    numTabs: +configuration.numTabs,
  });

  return {
    imageIds,
    title: configuration.title[language],
    headerTitle: configuration.headerTitle[language],
    footerTitle: configuration.footertitle[language],
    footerSubtitle: configuration.footersubtitle[language],
    footerButton: configuration.footerbutton[language],
    buttonPath: configuration.buttonpath[language],
    numTabs: +configuration.numTabs,
    tabs: faqTabsApiResponse.map((t) => ({
      title: t.title[language],
      description: t.description[language],
      linkText: t.linktext[language],
      path: t.path[language],
    })),
    images,
  };
}
export function transformFooterWidgetConfiguration(
  configuration: FooterWidgetConfigurationApi,
  language: AppLanguage,
): FooterWidgetConfiguration {
  return {} as FooterWidgetConfiguration;
}
export function transformFreeHtmlWidgetConfiguration(
  configuration: FreeHtmlWidgetConfigurationApi,
  language: AppLanguage,
): FreeHtmlWidgetConfiguration {
  return {} as FreeHtmlWidgetConfiguration;
}
export function transformGeneralAvisWidgetConfiguration(
  configuration: GeneralAvisWidgetConfigurationApi,
  language: AppLanguage,
): GeneralAvisWidgetConfiguration {
  return {} as GeneralAvisWidgetConfiguration;
}
export function transformHeaderWidgetConfiguration(
  configuration: HeaderWidgetConfigurationApi,
  language: AppLanguage,
): HeaderWidgetConfiguration {
  return {} as HeaderWidgetConfiguration;
}
export function transformHeaderPrincipalImageWidgetConfiguration(
  configuration: HeaderPrincipalImageWidgetConfigurationApi,
  language: AppLanguage,
  imageResize: WidgetImageResize,
): HeaderPrincipalImageWidgetConfiguration {
  const imageIds = configuration.imageIds.split(',');
  const images = imageIds
    .filter((id) => id)
    .map((id) => ({
      id,
      url: getImageUrl(+id, imageResize),
    }));

  return {
    title: configuration.title[language],
    description: configuration.description[language],
    button_text: configuration.button_text[language],
    images,
  };
}
export function transformHomeIntroWidgetConfiguration(
  configuration: HomeIntroWidgetConfigurationApi,
  language: AppLanguage,
): HomeIntroWidgetConfiguration {
  return {
    title: configuration.title[language],
    description: configuration.description[language],
  };
}
export function transformHomeProductsWidgetConfiguration(
  configuration: HomeProductsWidgetConfigurationApi,
  language: AppLanguage,
): HomeProductsWidgetConfiguration {
  return {} as HomeProductsWidgetConfiguration;
}
export function transformInfoAlertWidgetConfiguration(
  configuration: InfoAlertWidgetConfigurationApi,
  language: AppLanguage,
): InfoAlertWidgetConfiguration {
  return {} as InfoAlertWidgetConfiguration;
}
export function transformLegalWidgetConfiguration(
  configuration: LegalWidgetConfigurationApi,
  language: AppLanguage,
): LegalWidgetConfiguration {
  return {} as LegalWidgetConfiguration;
}
export function transformLegalAdviceSignupWidgetConfiguration(
  configuration: LegalAdviceSignupWidgetConfigurationApi,
  language: AppLanguage,
): LegalAdviceSignupWidgetConfiguration {
  return {} as LegalAdviceSignupWidgetConfiguration;
}
export function transformLinkCardsWidgetConfiguration(
  configuration: LinkCardsWidgetConfigurationApi,
  language: AppLanguage,
  imageResize: WidgetImageResize,
): LinkCardsWidgetConfiguration {
  const imageIds = configuration.imageIds.split(',');
  const images = imageIds
    .filter((id) => id)
    .map((id) => ({
      id,
      url: getImageUrl(+id, imageResize),
    }));

  return {
    images,
    title: configuration.title[language],
    text: configuration.text[language],
    card_1_title: configuration.card_1_title[language],
    card_1_link_text: configuration.card_1_link_text[language],
    card_1_link: configuration.card_1_link[language],
    card_2_title: configuration.card_2_title[language],
    card_2_link_text: configuration.card_2_link_text[language],
    card_2_link: configuration.card_2_link[language],
    card_3_title: configuration.card_3_title[language],
    card_3_link_text: configuration.card_3_link_text[language],
    card_3_link: configuration.card_3_link[language],
  };
}
export function transformMenuWidgetConfiguration(
  configuration: MenuWidgetConfigurationApi,
  language: AppLanguage,
): MenuWidgetConfiguration {
  return {} as MenuWidgetConfiguration;
}
export function transformMoreInformationWidgetConfiguration(
  configuration: MoreInformationWidgetConfigurationApi,
  language: AppLanguage,
): MoreInformationWidgetConfiguration {
  return {} as MoreInformationWidgetConfiguration;
}
export function transformMouteAmbHolaWidgetConfiguration(
  configuration: MouteAmbHolaWidgetConfigurationApi,
  language: AppLanguage,
  imageResize: WidgetImageResize,
): MouteAmbHolaWidgetConfiguration {
  const imageIds = configuration.imageIds.split(',');
  const images = imageIds
    .filter((id) => id)
    .map((id) => ({
      id,
      url: getImageUrl(+id, imageResize),
    }));
  return {
    title: configuration.title[language],
    description: configuration.description[language],
    button_link: configuration.button_link[language],
    button_text: configuration.button_text[language],
    links_title: configuration.links_title[language],
    link_1: configuration.link_1[language],
    link_2: configuration.link_1[language],
    link_3: configuration.link_1[language],
    link_text_1: configuration.link_1[language],
    link_text_2: configuration.link_1[language],
    link_text_3: configuration.link_1[language],
    images,
  };
}
export function transformOpinionesVerificadasCarrouselWidgetConfiguration(
  configuration: OpinionesVerificadasCarrouselWidgetConfigurationApi,
  language: AppLanguage,
): OpinionesVerificadasCarrouselWidgetConfiguration {
  return {} as OpinionesVerificadasCarrouselWidgetConfiguration;
}
export function transformPackWidgetConfiguration(
  configuration: PackWidgetConfigurationApi,
  language: AppLanguage,
): PackWidgetConfiguration {
  return {} as PackWidgetConfiguration;
}
export function transformProductDetailWidgetConfiguration(
  configuration: ProductDetailWidgetConfigurationApi,
  language: AppLanguage,
): ProductDetailWidgetConfiguration {
  return {} as ProductDetailWidgetConfiguration;
}
export function transformProductCardWidgetConfiguration(
  configuration: ProductCardWidgetConfigurationApi,
  language: AppLanguage,
  imageResize: WidgetImageResize,
): ProductCardWidgetConfiguration {
  const imageIds = configuration.imageIds.split(',');
  const images = imageIds
    .filter((id) => id)
    .map((id) => ({
      id,
      url: getImageUrl(+id, imageResize),
    }));
  return {
    productCodes: [configuration.product1, configuration.product2].filter(
      (p) => p,
    ),
    images,
  } as ProductCardWidgetConfiguration;
}
export function transformPromoWidgetConfiguration(
  configuration: PromoWidgetConfigurationApi,
  language: AppLanguage,
): PromoWidgetConfiguration {
  return {} as PromoWidgetConfiguration;
}
export function transformPromoAppWidgetConfiguration(
  configuration: PromoAppWidgetConfigurationApi,
  language: AppLanguage,
): PromoAppWidgetConfiguration {
  return {
    title: configuration.title[language],
    text: configuration.text[language],
    android_link: configuration.android_link[language],
    ios_link: configuration.ios_link[language],
  };
}
export function transformPromotionWidgetConfiguration(
  configuration: PromotionWidgetConfigurationApi,
  language: AppLanguage,
  imageResize: WidgetImageResize,
): PromotionWidgetConfiguration {
  // TODO refactorizar para evitar código duplicado
  const imageIds = configuration.imageIds.split(',');
  const images = imageIds
    .filter((id) => id)
    .map((id) => ({
      id,
      url: getImageUrl(+id, imageResize),
    }));
  return {
    imageIds,
    images,
    text: configuration.text[language],
    title: configuration.title[language],
  };
}
export function transformRecoverWidgetConfiguration(
  configuration: RecoverWidgetConfigurationApi,
  language: AppLanguage,
): RecoverWidgetConfiguration {
  return {} as RecoverWidgetConfiguration;
}
export function transformSectionIntroWidgetConfiguration(
  configuration: SectionIntroWidgetConfigurationApi,
  language: AppLanguage,
): SectionIntroWidgetConfiguration {
  return {
    title: configuration.title[language],
    description: configuration.description[language],
  };
}
export function transformShowInfoWidgetConfiguration(
  configuration: ShowInfoWidgetConfigurationApi,
  language: AppLanguage,
): ShowInfoWidgetConfiguration {
  return {} as ShowInfoWidgetConfiguration;
}
export function transformSightseeingWidgetConfiguration(
  configuration: SightseeingWidgetConfigurationApi,
  language: AppLanguage,
): SightseeingWidgetConfiguration {
  return {} as SightseeingWidgetConfiguration;
}
export function transformSliderWidgetConfiguration(
  configuration: SliderWidgetConfigurationApi,
  language: AppLanguage,
): SliderWidgetConfiguration {
  return {} as SliderWidgetConfiguration;
}
export function transformSitemapWidgetConfiguration(
  configuration: SitemapWidgetConfigurationApi,
  language: AppLanguage,
): SitemapWidgetConfiguration {
  return {} as SitemapWidgetConfiguration;
}
export function transformTermsAndConditionsWidgetConfiguration(
  configuration: TermsAndConditionsWidgetConfigurationApi,
  language: AppLanguage,
): TermsAndConditionsWidgetConfiguration {
  return {} as TermsAndConditionsWidgetConfiguration;
}
export function transformThanksWidgetConfiguration(
  configuration: ThanksWidgetConfigurationApi,
  language: AppLanguage,
): ThanksWidgetConfiguration {
  return {} as ThanksWidgetConfiguration;
}
export function transformTransportDealsLandingWidgetConfiguration(
  configuration: TransportDealsLandingWidgetConfigurationApi,
  language: AppLanguage,
): TransportDealsLandingWidgetConfiguration {
  return {} as TransportDealsLandingWidgetConfiguration;
}
export function transformUnsubscribeWidgetConfiguration(
  configuration: UnsubscribeWidgetConfigurationApi,
  language: AppLanguage,
): UnsubscribeWidgetConfiguration {
  return {} as UnsubscribeWidgetConfiguration;
}
export function transformUnsubscribeMessageWidgetConfiguration(
  configuration: UnsubscribeMessageWidgetConfigurationApi,
  language: AppLanguage,
): UnsubscribeMessageWidgetConfiguration {
  return {} as UnsubscribeMessageWidgetConfiguration;
}

export function getImageUrl(id: number, resize: WidgetImageResize): string {
  // Aqui hay que llamar a strapi, obtener el id de la trabla de traduccion y generar url imagen strapi
  return `${environment.widgetImageApiUrl}/images/${id}/resize/${resize.height}/${resize.width}/5.jpg`;
}
