import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  inject,
  input,
  output,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { PageId } from '../../enums/page-id.enum';
import { GtmDataLayerService } from '../../gtm/gtm.service';
import { CartService } from '../../services/cart.service';
import { NavigationService } from '../../services/navigation.service';
import { HppDataRedsys } from '../../services/order-api.service';

@Component({
  selector: 'hola-redsys-iframe',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './redsys-iframe.component.html',
  styleUrl: './redsys-iframe.component.scss',
})
export class RedsysIframeComponent implements AfterViewInit {
  public readonly redsysUrl: string = environment.redsysUrl;

  @ViewChild('redsysIFrame', { static: true })
  redsysIFrameElement!: ElementRef<HTMLIFrameElement>;

  @ViewChild('redsysPaymentForm', { static: true })
  redsysPaymentFormElement!: ElementRef<HTMLFormElement>;

  // public redsysIFrameElement =
  //   viewChild.required<ElementRef<HTMLIFrameElement>>('redsysIframe');

  // public redsysPaymentFormElement =
  //   viewChild.required<HTMLFormElement>('redsysPaymentForm');

  public hppData = input.required<HppDataRedsys>();
  public paymentCompleted = output<void>();

  private readonly navigationService = inject(NavigationService);
  private readonly formBuilder = inject(FormBuilder);
  private readonly cartService = inject(CartService);
  private readonly gtmDataLayerService = inject(GtmDataLayerService);

  public redsysForm!: FormGroup;
  public isRedsysPaymentFormSubmitted!: boolean;

  constructor() {
    this.initRedsysForm();
  }

  public ngAfterViewInit(): void {
    if (!this.isRedsysPaymentFormSubmitted) {
      this.redsysForm.patchValue(this.hppData());
      this.redsysPaymentFormElement.nativeElement.submit();
      this.isRedsysPaymentFormSubmitted = true;

      // Gtm Checkout 3
      this.gtmDataLayerService.checkout(
        this.cartService.products(),
        3,
        'datos',
      );
    }
  }

  // Este metodo es llamado con el load() del iframe (ver html). Es invocado cada vez que el iframe cambiar de url.
  // Cuando llegue a una de nuestro dominio es que el pago ha finalizado (bien o mal).
  public onLoadRedsysIFrame(): void {
    try {
      const iFrameHref =
        this.redsysIFrameElement.nativeElement.contentWindow?.location.href;
      if (iFrameHref) {
        // La url OK es de la forma https://pre2.holabarcelona.com/{lang}/pagament-ok?orderId={orderCode}&flow={buyFlow}
        // La url KO es de la forma https://pre2.holabarcelona.com/{lang}/pagament-ko
        // Parseamos la url para obtener la ruta y navegamos
        const targetPage =
          iFrameHref.split('/')[4].split('?')[0] === 'pagament-ok'
            ? PageId.Thanks
            : PageId.Error;
        this.navigationService.navigateToPage(targetPage);
      }
    } catch (e) {
      console.log('iframe error', e);
    }
  }

  private initRedsysForm(): void {
    this.redsysForm = this.formBuilder.group({
      Ds_SignatureVersion: [null],
      Ds_MerchantParameters: [null],
      Ds_Signature: [null],
    });
  }
}
