<div class="col-home box-des">
  @if (superProduct().moduleBoxTitle) {
    <h3 class="box-title-tabs">{{ superProduct().moduleBoxTitle }}</h3>
  }
  @for (box of superProduct().moduleBoxes; track $index) {
    @if (box.enabled === true) {
      <!--@if (tab.images.length > 0) {-->
      <div class="col-12 col-sm-6 col-md-6 col-lg-6 content-col-home lbox">
        <div class="featured-box">
          <div class="info-tag">
            @if (box.tag !== "") {
              <span>{{ box.tag | translate }}</span>
            } @else {
              <span>{{ "template.product.detail.guide" | translate }}</span>
            }
          </div>
          <div class="img-over-block">
            <div class="slider-container">
              <div class="slider-wrapper">
                <div
                  class="slider-slide"
                  [ngStyle]="{
                    transform: 'translateX(-' + currentSlideIndex * 100 + '%)',
                  }"
                >
                  @for (image of box.images; track $index) {
                    <img
                      [src]="image"
                      alt=""
                      [ngClass]="{ active: true }"
                      class="img-slider"
                    />
                  }
                </div>
              </div>
              <!-- Puntos de navegación -->
              <div class="slider-dots">
                @for (image of box.images; track $index) {
                  <span
                    class="dot"
                    [ngClass]="{ active: $index === currentSlideIndex }"
                    (click)="goToSlide($index)"
                    (keyup)="goToSlide($index)"
                    [tabindex]="$index"
                  >
                  </span>
                }
              </div>
            </div>
          </div>
          <span class="feature-caption-box title-box">{{ box.title }}</span>
          <div class="blank-line"></div>
          <div class="blank-line"></div>
          <div class="info-tag">
            @if (box.tag !== "") {
              <span>{{ box.tag | translate }}</span>
            } @else {
              <span>{{ "template.product.detail.guide" | translate }}</span>
            }
          </div>
          <a [href]="box.link" target="_blank"
            ><span class="btn-download"
              >{{ "template.product.detail.download" | translate }}
              <i class="fa fa-download"></i></span
          ></a>
        </div>
        <!-- end featured-box-large -->
      </div>
      <!-- end featured-box-large -->
    }
  }
  <!--}-->
  <div style="clear: both"></div>
</div>
<!-- end boxes-tabs-block -->

<div class="col-home box-mob">
  @if (superProduct().moduleBoxTitle) {
    <h3 class="box-title-tabs">{{ superProduct().moduleBoxTitle }}</h3>
  }
  <div id="sliderModuleBox" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      @for (box of superProduct().moduleBoxes; track $index) {
        @if (box.enabled === true) {
          <div class="carousel-item">
            <div class="featured-box">
              <div class="info-tag">
                @if (box.tag !== "") {
                  <span>{{ box.tag | translate }}</span>
                } @else {
                  <span>{{ "template.product.detail.guide" | translate }}</span>
                }
              </div>
              <div class="slider-wrapper">
                <div
                  class="slider-slide"
                  [ngStyle]="{
                    transform: 'translateX(-' + currentSlideIndex * 100 + '%)',
                  }"
                >
                  @for (image of box.images; track $index) {
                    <img
                      [src]="image"
                      alt=""
                      [ngClass]="{ active: true }"
                      class="img-slider"
                    />
                  }
                </div>
              </div>
              <span class="feature-caption-box title-box">{{ box.title }}</span>
              <div class="blank-line"></div>
              <div class="blank-line"></div>
              <div class="info-tag">
                @if (box.tag !== "") {
                  <span>{{ box.tag | translate }}</span>
                } @else {
                  <span>{{ "template.product.detail.guide" | translate }}</span>
                }
              </div>
              <a [href]="box.link" target="_blank"
                ><span class="btn-download"
                  >{{ "template.product.detail.download" | translate }}
                  <i class="fa fa-download"></i></span
              ></a>
            </div>
            <!-- end featured-box-large -->
          </div>
          <!-- end featured-box-large -->
        }
      }
    </div>
  </div>
  <div style="clear: both"></div>
</div>
