import { Component, input } from '@angular/core';
import { ShowInfoWidgetConfiguration } from './models/show-info-widget-configuration.interface';

@Component({
  selector: 'hola-show-info-widget',
  standalone: true,
  imports: [],
  templateUrl: './show-info-widget.component.html',
  styleUrl: './show-info-widget.component.scss',
})
export class ShowInfoWidgetComponent {
  configuration = input.required<ShowInfoWidgetConfiguration>();
}
