import { Component, input } from '@angular/core';
import { InfoAlertWidgetConfiguration } from './models/info-alert-widget-configuration.interface';

@Component({
  selector: 'hola-info-alert-widget',
  standalone: true,
  imports: [],
  templateUrl: './info-alert-widget.component.html',
  styleUrl: './info-alert-widget.component.scss',
})
export class InfoAlertWidgetComponent {
  configuration = input.required<InfoAlertWidgetConfiguration>();
}
