import { AfterViewInit, Component, inject, input } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { SafeHtml } from '@angular/platform-browser';
import { SanitizePipe } from '../../shared/pipes/sanitize.pipe';
import { VerifiedOpinionsService } from '../../shared/services/verified-opinions.service';
import { OpinionesVerificadasCarrouselWidgetConfiguration } from './models/opiniones-verificadas-carrousel-widget-configuration.interface';

@Component({
  selector: 'hola-opiniones-verificadas-carrousel-widget',
  standalone: true,
  imports: [SanitizePipe],
  templateUrl: './opiniones-verificadas-carrousel-widget.component.html',
  styleUrl: './opiniones-verificadas-carrousel-widget.component.scss',
})
export class OpinionesVerificadasCarrouselWidgetComponent
  implements AfterViewInit
{
  configuration =
    input.required<OpinionesVerificadasCarrouselWidgetConfiguration>();
  private readonly verifiedOpinionsService = inject(VerifiedOpinionsService);

  private opinionesCarrouselScript$ = toObservable(
    this.verifiedOpinionsService.siteValorationCarrousel,
  );

  public safeStoreValorationCarrousel: SafeHtml | undefined = undefined;
  public iframeId: string | null = '';
  public iframeSrc: string = '';

  ngAfterViewInit() {
    this.opinionesCarrouselScript$.pipe().subscribe((iframeSrc) => {
      if (iframeSrc) {
        const idMatch = iframeSrc.match(/id=['"]([^'"]+)['"]/);
        const srcMatch = iframeSrc.match(/src=['"]([^'"]+)['"]/);

        if (srcMatch && srcMatch.length > 0) {
          console.log('estamos en el after view init', srcMatch);
          this.iframeId = idMatch ? idMatch[1] : null;
          this.iframeSrc = srcMatch[1];
        }
      }
    });
  }
}
