import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  PLATFORM_ID,
  effect,
  inject,
  input,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SuperProduct } from '../../../../shared/models/super-product.interface';
import { LanguageService } from '../../../../shared/services/language.service';

@Component({
  selector: 'hola-product-module-boxes',
  standalone: true,
  imports: [TranslateModule, CommonModule],
  templateUrl: './product-module-boxes.component.html',
  styleUrl: './product-module-boxes.component.scss',
})
export class ProductModuleBoxesComponent implements AfterViewInit, OnDestroy {
  //@Input() superProduct!: SuperProduct;
  public superProduct = input<SuperProduct>({} as SuperProduct);

  private readonly languageService = inject(LanguageService);

  public locale = this.languageService.locale;
  public currentSlideIndex = 0;
  private autoSlideInterval: ReturnType<typeof setInterval> | null = null;

  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    effect(() => console.log('module boxes', this.superProduct()));
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.startAutoSlide();
    }
  }

  startAutoSlide() {
    this.autoSlideInterval = setInterval(() => {
      this.nextSlide();
    }, 5000); // Cambia de imagen cada 5 segundos
  }

  ngOnDestroy() {
    this.stopAutoSlide();
  }

  stopAutoSlide() {
    if (this.autoSlideInterval) {
      clearInterval(this.autoSlideInterval);
    }
  }

  nextSlide() {
    console.log('next slide');
    let sliderImagesUrlsLength: number | undefined = 0;
    if (this.superProduct()) {
      sliderImagesUrlsLength = this.superProduct()?.sliderImagesUrls.length;
    }
    this.currentSlideIndex =
      (this.currentSlideIndex + 1) % sliderImagesUrlsLength!;
  }

  goToSlide(index: number) {
    this.currentSlideIndex = index;
  }
}
