import { Component, input } from '@angular/core';
import { LegalWidgetConfiguration } from './models/legal-widget-configuration.interface';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'hola-legal-widget',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './legal-widget.component.html',
  styleUrl: './legal-widget.component.scss',
})
export class LegalWidgetComponent {
  configuration = input.required<LegalWidgetConfiguration>();
}
