import { Component, input } from '@angular/core';
import { HomeIntroWidgetConfiguration } from './models/home-intro-widget-configuration.interface';

@Component({
  selector: 'hola-home-intro-widget',
  standalone: true,
  imports: [],
  templateUrl: './home-intro-widget.component.html',
  styleUrl: './home-intro-widget.component.scss',
})
export class HomeIntroWidgetComponent {
  configuration = input.required<HomeIntroWidgetConfiguration>();
}
