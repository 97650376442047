<section>
  <div
    class="full-width-top-banner detail-top-banner"
    style="
      background: url(/assets/images/sitemap-widget/sitemap.png) center center /
        cover no-repeat;
    "
  >
    <img
      class="ticket-shadow"
      src="/assets/images/shared/header_shadow.png"
      alt=""
    />
    <div class="faq-title-container">
      <h2 class="title-sitemap">{{ "template.sitemap.title" | translate }}</h2>
    </div>
  </div>
</section>
<section class="sitemap_section">
  <div class="container-fluid home-products-container">
    <div class="row col-home">
      <div class="col-sm-12">
        <h3 class="subtitle-sitemap">
          <a href="#">{{ "template.move_with_hb.title" | translate }}</a>
        </h3>
      </div>
    </div>
    <div class="row col-home">
      <div class="col-sm-12">
        <h3 class="subtitle-sitemap">
          <a href="#">{{
            "template.best_mobility_options.title" | translate
          }}</a>
        </h3>
      </div>
    </div>
    <div class="row list-arrow-dotted col-home">
      @for (product of products(); track $index) {
        <div class="col-sm-6 link-sitemap">
          <a
            tabindex="0"
            (click)="product?.onClick()"
            (keydown)="product?.onClick()"
            >{{ product.title }}</a
          >
        </div>
      }
    </div>
    <div class="row col-home">
      <div class="col-sm-12">
        <h3 class="subtitle-sitemap">
          <a href="#">{{ "template.app_hola_barcelona.title" | translate }}</a>
        </h3>
      </div>
    </div>
    <div class="row col-home">
      <div class="col-sm-12">
        <h3 class="subtitle-sitemap">
          <a href="{{ urlHolaBcnBlog }}" target="_blank">{{
            "template.header.menu.blog" | translate
          }}</a>
        </h3>
      </div>
    </div>
    <div class="row col-home">
      <div class="col-sm-12">
        <h3 class="subtitle-sitemap">
          {{ "template.sitemap.help" | translate }}
        </h3>
      </div>
    </div>
    <div class="row list-arrow-dotted col-home">
      <div class="col-sm-6 link-sitemap">
        <a [routerLink]="faqUrl()">{{ "template.faq.title" | translate }}</a>
      </div>
      <div class="col-sm-6 link-sitemap">
        <a [routerLink]="recoverUrl()">{{
          "template.voucher_recover.title" | translate
        }}</a>
      </div>
      <div class="col-sm-6 link-sitemap">
        <a [routerLink]="termsAndConditionsUrl()">{{
          "template.terms_and_conditions.title" | translate
        }}</a>
      </div>
      <div class="col-sm-6 link-sitemap">
        <a [routerLink]="contactUrl()">{{
          "template.contact.page_title" | translate
        }}</a>
      </div>
    </div>
    <div class="row col-home">
      <div class="col-sm-12">
        <h3 class="subtitle-sitemap">
          {{ "template.sitemap.more_info" | translate }}
        </h3>
      </div>
    </div>
    <div class="row list-arrow-dotted col-home">
      <div class="col-sm-6 link-sitemap">
        <a [routerLink]="aboutUrl()">{{
          "template.about_us.title" | translate
        }}</a>
      </div>
      <div class="col-sm-6 link-sitemap">
        <a [routerLink]="legalUrl()">{{
          "template.legal_notice.title" | translate
        }}</a>
      </div>
      <div class="col-sm-6 link-sitemap">
        <a
          target="_blank"
          href="{{ 'template.privacy_policy.link' | translate }}"
          >{{ "template.privacy_policy.title" | translate }}</a
        >
      </div>
      <div class="col-sm-6 link-sitemap">
        <a
          target="_blank"
          href="{{
            'template.footer.customer_service.cookies_link' | translate
          }}"
          >{{ "template.static_cookies.title" | translate }}</a
        >
      </div>
      <!--TODO: Enlace cookiebot-->
      <div class="col-sm-6 link-sitemap">
        <a href="#">{{
          "template.footer.customer_service.cookies_manager" | translate
        }}</a>
      </div>
    </div>
  </div>
</section>
