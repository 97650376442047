import { Component, input } from '@angular/core';
import { Translation } from '../../shared/models/translation.interface';
import { LinkCardsWidgetConfiguration } from './models/link-cards-widget-configuration.interface';

@Component({
  selector: 'hola-link-cards-widget',
  standalone: true,
  imports: [],
  templateUrl: './link-cards-widget.component.html',
  styleUrl: './link-cards-widget.component.scss',
})
export class LinkCardsWidgetComponent {
  // Array del 1 al 3
  numbers = [1, 2, 3];

  configuration = input.required<LinkCardsWidgetConfiguration>();

  // Método para obtener las variables dinámicamente
  getVariable(
    cardNum: number,
    type: string,
  ): string | Translation | { id: string; url: string }[] {
    const key = `card_${cardNum}_${type}` as keyof LinkCardsWidgetConfiguration;
    return this.configuration()[key];
  }
  getImage(cardNum: number): string {
    return (
      'url(' +
      this.configuration().images[cardNum - 1].url +
      ') center center / cover no-repeat'
    );
  }
}
