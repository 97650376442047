<section>
  <div class="checkout-top-banner">
    <div class="checkout-top-banner-container">
      <a class="navbar-brand" [routerLink]="homeUrl()"
        ><img
          src="/assets/images/shared/checkout_logo.png"
          alt=""
          class="img-producto img-fluid"
      /></a>
      <h1>{{ "template.cart.header.title" | translate }}</h1>
    </div>
  </div>
</section>

<section>
  <div class="checkout-breadcrumb">
    <a class="navbar-brand" [routerLink]="cartUrl()"
      ><i class="fa fa-caret-left"></i>
      {{ "template.cart.header.return" | translate }}</a
    >
  </div>
</section>

<section>
  <div class="checkout-steps">
    <div>
      <h1 class="active">
        <ng-container>1</ng-container>
        <span class="subtext">
          {{ "template.cart.header.step1" | translate }}
        </span>
        <i class="step-icon fa fa-ticket"></i>
      </h1>
    </div>
    <div class="separator active"></div>
    <div>
      <h1 class="active">
        <ng-container>2</ng-container>
        <span class="subtext"
          >{{ "template.cart.header.step2" | translate }}
        </span>
        <i class="step-icon fa-solid fa-pencil"></i>
      </h1>
    </div>
    <div class="separator active"></div>
    <div>
      <h1 class="active">
        <ng-container>3</ng-container>
        <span class="subtext">
          {{ "template.cart.header.step3" | translate }}
        </span>
        <i class="step-icon fa fa-check"></i>
      </h1>
    </div>
  </div>
  <div style="clear: both"></div>
</section>

<section>
  <div class="checkout-title">
    <h1>{{ "template.cart.header.step3" | translate }}</h1>
  </div>
</section>

<!-- CHECKOUT STEP-3 CONFIRMATION BLOCK -->
<section class="section-checkout-container">
  <div class="container-fluid checkout-container">
    <div class="row">
      <div class="col-sm-12">
        <div class="row ch-headers">
          <div class="col-sm-9">
            <h2>{{ "template.cart.header.step3" | translate }}</h2>
          </div>
          <div class="col-sm-3 ch-mh">
            <h2>{{ "template.cart.header.your_purchase" | translate }}</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8">
          <div class="row">
            <div class="col-sm-12 confirmed-message-block">
              <i class="fa fa-check big-success-check" aria-hidden="true"></i>
              <h1 class="ch-thanks-title">
                {{ "template.confirmation.confirm_payment" | translate }}
              </h1>
              <!--TODO: revisar traducciones con parámetros-->
              <!--<p>{{ 'template.confirmation.voucher_thanks' | trans({ '%order%': order.billingNumber, '%mail%': order.email }) | raw }}</p>-->
              <h3 class="ch-thanks-title-email">
                {{ "template.confirmation.contact_details" | translate }}
              </h3>
              <p class="ch-thanks-email">EMAIL</p>
            </div>
          </div>

          @for (cartSummaryProducts of products(); track $index) {
            <div
              class="row ch-product-item item"
              style="border-bottom: none !important"
            >
              <div class="col-sm-12 col-lg-2">
                <img
                  class="img-producto img-fluid"
                  alt="Producto"
                  src="/assets/images/home-widget/5.jpg"
                />
                <h2 class="ch-product-name-mob">
                  {{
                    cartSummaryProducts[0].parentProduct?.name ||
                      "Productos sin padre"
                  }}
                </h2>
              </div>

              <div class="col-sm-10 ch-mob-container">
                <div class="row">
                  <div class="col-sm-12">
                    <h2 class="ch-product-name">
                      {{
                        cartSummaryProducts[0].parentProduct?.name ||
                          "Productos sin padre"
                      }}
                    </h2>
                  </div>
                  <div class="col-sm-12">
                    @if (cartSummaryProducts[0].departDate) {
                      <p>
                        {{
                          "template.product.booking.service_date" | translate
                        }}
                        <span class="bold">{{
                          cartSummaryProducts[0].departDate | date: "dd/MM/yyyy"
                        }}</span>
                      </p>
                      <p>
                        {{ "template.cart.booking_departure_hour" | translate }}
                        <span class="bold">
                          {{
                            cartSummaryProducts[0].departDate | date: "HH:mm:ss"
                          }}</span
                        >
                      </p>
                    }
                    @if (cartSummaryProducts[0].returnDate) {
                      <p>
                        {{ "template.cart.booking_return_hour" | translate }}
                        <span class="bold">
                          {{
                            cartSummaryProducts[0].returnDate | date: "HH:mm:ss"
                          }}</span
                        >
                      </p>
                    }
                    @if (cartSummaryProducts[0].tagCode) {
                      <p>
                        {{
                          "template.product.booking.selected_booking_route"
                            | translate
                        }}
                        <span class="bold">{{
                          cartSummaryProducts[0].tagCode
                        }}</span>
                      </p>
                    }
                  </div>
                  <div class="col-sm-10">
                    @for (
                      cartSummaryProduct of cartSummaryProducts;
                      track $index
                    ) {
                      <div class="add-ticket-box-block row">
                        <div class="col-4 col-lg-6 ch-attribute">
                          <!--TODO: Aquí debería ir solo el atributo (ejemplo: Adulto, Senior, etc)-->
                          {{ cartSummaryProduct.product.name }}
                        </div>
                        <div class="col-6 col-lg-4 add-ticket-box cart">
                          <div class="content-copy">
                            <span class="quantity"
                              >{{ cartSummaryProduct.quantity }}
                            </span>
                          </div>
                        </div>

                        <div class="col-2">
                          <span class="current-price">{{
                            cartSummaryProduct.activePrice
                              | customCurrency: locale()
                          }}</span>
                        </div>
                      </div>
                    }
                  </div>
                  <div class="col-2 ch-product-detail">
                    <div class="ch-product-detail-container">
                      <!--TODO: Aquí debe ir el precio total-->
                      <span class="price-tag">50€</span>
                      <span class="reduced-price-tag">45€</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }

          <!--TODO: OPINIONES VERIFICADAS BUTTON BLOCK -->
        </div>
        <div class="col-lg-4 checkout-discounts thanks-resume">
          <hola-purchase-summary
            [showDiscountForm]="false"
            [showOrderSummary]="true"
            [showButtons]="false"
          ></hola-purchase-summary>

          <div class="ch-discounts-separator row"></div>
          <p class="ch-remember">
            {{ "template.confirmation.remember_voucher" | translate }}
          </p>
          <!--TODO: dar vueltas a los vouchers-->
          <div class="row ch-resume-item-row">
            <div class="reference-block" style="margin-bottom: 10px">
              <div class="item-voucher">
                <!--TODO: Imprimir QR-->
                <p>VOUCHER QR/CODE</p>
              </div>
              <div class="ch-button-voucher">
                <a class="btn-secundary red" target="_blank" href="#">{{
                  "template.confirmation.voucher_download" | translate
                }}</a>
              </div>
            </div>
          </div>
          <!-- end ch-resume-item-row -->
          <div class="ch-discounts-separator row"></div>
          <!--TODO: crear componenete PROMO APP -->
        </div>
      </div>
    </div>
  </div>
</section>
