<form [formGroup]="discountForm">
  <div id="form-discount-code" class="row row-discount-code">
    <div class="col-sm-12 apply-discount ch-apply-discount-title">
      <h2>{{ "template.cart.coupon.title" | translate }}</h2>
    </div>

    <div class="col-sm-12 apply-discount">
      <div class="input-box input-box-code">
        <input class="input-field" type="text" formControlName="discount" />
        <button class="btn-apply" (click)="apply()">
          <span>
            {{ "template.cart.coupon.code_apply_mobile" | translate }}
          </span>
        </button>
        <div
          id="text-discount-applied-lg"
          class="visible-lg-* text-discount-applied"
        >
          <span>{{ "template.cart.coupon.code_applied" | translate }}</span>
        </div>
        <!--TODO: Gestion errores descuentos-->
        <small class="code-invalid-text">{{
          "template.cart.coupon.code_invalid" | translate
        }}</small>
        <small class="code-invalid-repeated-text">{{
          "template.cart.coupon.code_invalid_repeated" | translate
        }}</small>
        <small class="code-invalid-already-used-text">{{
          "template.cart.coupon.code_invalid_already_used" | translate
        }}</small>
        <small class="code-cannot-be-used-in-cart-text">{{
          "template.cart.coupon.code_cannot_be_used_in_cart" | translate
        }}</small>
        <small class="code-conflict-with-other-codes-text">{{
          "template.cart.coupon.code_conflict_with_other_codes" | translate
        }}</small>
      </div>
      <div
        id="text-discount-applied-sm"
        class="hidden-lg text-discount-applied"
      >
        <span>{{ "template.cart.coupon.code_applied" | translate }}</span>
      </div>
    </div>
  </div>
</form>
