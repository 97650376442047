import { Component, input } from '@angular/core';
import { SightseeingWidgetConfiguration } from './models/sightseeing-widget-configuration.interface';

@Component({
  selector: 'hola-sightseeing-widget',
  standalone: true,
  imports: [],
  templateUrl: './sightseeing-widget.component.html',
  styleUrl: './sightseeing-widget.component.scss',
})
export class SightseeingWidgetComponent {
  configuration = input.required<SightseeingWidgetConfiguration>();
}
