<div class="content-only-slide marginBottom40 product-slider">
  @if (superProduct.moduleSliderTitle) {
    <h3 class="boxed-title-tabs">{{ superProduct.moduleSliderTitle }}</h3>
  }

  <div class="slider-container">
    <!-- Imágenes -->
    <div class="slider-wrapper">
      <div
        class="slider-slide"
        [ngStyle]="{
          transform: 'translateX(-' + currentSlideIndex * 100 + '%)',
        }"
      >
        @for (image of superProduct.sliderImagesUrls; track $index) {
          <img
            [src]="image"
            alt=""
            [ngClass]="{ active: true }"
            class="img-slider"
          />
        }
      </div>
    </div>

    <!-- Flechas de navegación -->
    <button class="slider-nav prev" (click)="prevSlide()" (keyup)="prevSlide()">
      <span class="carousel-control-prev-icon" aria-hidden="true"
        ><b
          class="fa fa-chevron-left fa-chevron-left-extra"
          aria-hidden="true"
        ></b
      ></span>
    </button>
    <button class="slider-nav next" (click)="nextSlide()" (keyup)="nextSlide()">
      <span class="carousel-control-next-icon" aria-hidden="true"
        ><b
          class="fa fa-chevron-right fa-chevron-right-extra"
          aria-hidden="true"
        ></b
      ></span>
    </button>

    <!-- Puntos de navegación -->
    <div class="slider-dots">
      @for (image of superProduct.sliderImagesUrls; track $index) {
        <span
          class="dot"
          [ngClass]="{ active: $index === currentSlideIndex }"
          (click)="goToSlide($index)"
          (keyup)="goToSlide($index)"
          [tabindex]="$index"
        >
        </span>
      }
    </div>
  </div>
</div>
<div style="clear: both"></div>
