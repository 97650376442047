import { NgClass } from '@angular/common';

import { Component, computed, inject, input } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Locale } from '../../shared/enums/locale.enum';
import { CountriesService } from '../../shared/services/countries.service';
import { NavigationService } from '../../shared/services/navigation.service';
import { ContactWidgetConfiguration } from './models/contact-widget-configuration.interface';

@Component({
  selector: 'hola-contact-widget',
  standalone: true,
  imports: [TranslateModule, NgClass, ReactiveFormsModule],
  templateUrl: './contact-widget.component.html',
  styleUrl: './contact-widget.component.scss',
})
export class ContactWidgetComponent {
  private readonly formBuilder = inject(FormBuilder);
  private readonly navigationService = inject(NavigationService);
  private readonly countriesService = inject(CountriesService);

  public countries = computed(() => this.countriesService.countries());
  public configuration = input.required<ContactWidgetConfiguration>();
  public termsAndConditionsUrl = this.navigationService.termsAndConditionsUrl;
  public contactDisabled = false;
  public succesMessage = false;
  public errorMessage = false;
  public submitted = false;
  public form: FormGroup = new FormGroup({
    name: new FormControl(''),
    surname1: new FormControl(''),
    surname2: new FormControl(''),
    language: new FormControl(''),
    country: new FormControl(''),
    email: new FormControl(''),
    shortDescription: new FormControl(''),
    longDescription: new FormControl(''),
  });

  public locales = computed(() => Object.values(Locale));
  public nameFocus: boolean = false;
  public surname1Focus: boolean = false;
  public surname2Focus: boolean = false;
  public languageFocus: boolean = false;
  public countryFocus: boolean = false;
  public emailFocus: boolean = false;
  public shortDescriptionFocus: boolean = false;
  public longDescriptionFocus: boolean = false;

  constructor() {
    this.initForm();
  }

  public contact(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    console.log('contact action');
  }

  public onBlur(item: string) {
    switch (item) {
      case 'name':
        this.nameFocus = false;
        break;
      case 'surname1':
        this.surname1Focus = false;
        break;
      case 'surname2':
        this.surname2Focus = false;
        break;
      case 'language':
        this.languageFocus = false;
        break;
      case 'country':
        this.countryFocus = false;
        break;
      case 'email':
        this.emailFocus = false;
        break;
      case 'shortDescription':
        this.shortDescriptionFocus = false;
        break;
      case 'longDescription':
        this.longDescriptionFocus = false;
        break;
      default:
        console.warn(`Unhandled item: ${item}`);
    }
  }

  public onFocus(item: string) {
    switch (item) {
      case 'name':
        this.nameFocus = true;
        break;
      case 'surname1':
        this.surname1Focus = true;
        break;
      case 'surname2':
        this.surname2Focus = true;
        break;
      case 'country':
        this.countryFocus = true;
        break;
      case 'language':
        this.languageFocus = true;
        break;
      case 'email':
        this.emailFocus = true;
        break;
      case 'shortDescription':
        this.shortDescriptionFocus = true;
        break;
      case 'longDescription':
        this.longDescriptionFocus = true;
        break;
      default:
        console.warn(`Unhandled item: ${item}`);
    }
  }
  private initForm(): void {
    this.form = this.formBuilder.group(
      {
        name: ['', Validators.required],
        surname1: ['', Validators.required],
        surname2: ['', Validators.required],
        language: ['', Validators.required],
        country: ['', Validators.required],
        email: ['', Validators.required],
        shortDescription: ['', Validators.required],
        longDescription: ['', Validators.required],
      },
      {
        validators: [],
      },
    );
  }
  public get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
}
