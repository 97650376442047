<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<section>
  <div class="checkout-top-banner">
    <div class="checkout-top-banner-container">
      <a class="navbar-brand" [routerLink]="homeUrl()"
        ><img
          src="/assets/images/shared/checkout_logo.png"
          alt=""
          class="img-producto img-fluid"
      /></a>
      <h1>{{ "template.cart.header.title" | translate }}</h1>
    </div>
  </div>
</section>

<section>
  <div class="checkout-breadcrumb">
    <a class="navbar-brand" [routerLink]="cartUrl()"
      ><i class="fa fa-caret-left"></i>
      {{ "template.cart.header.step1" | translate }}</a
    >
  </div>
</section>

<section>
  <div class="checkout-steps">
    <div>
      <h1 class="active">
        1<span class="subtext">{{
          "template.cart.header.step1" | translate
        }}</span
        ><i class="step-icon fa fa-ticket"></i>
      </h1>
    </div>
    <div class="separator active"></div>
    <div>
      <h1>
        2<span class="subtext">{{
          "template.cart.header.step2" | translate
        }}</span
        ><i class="step-icon fa-solid fa-pencil"></i>
      </h1>
    </div>
    <div class="separator"></div>
    <div>
      <h1>
        3<span class="subtext">{{
          "template.cart.header.step3" | translate
        }}</span
        ><i class="step-icon fa fa-check"></i>
      </h1>
    </div>
  </div>
  <div style="clear: both"></div>
</section>

<section>
  <div class="checkout-title">
    <h1>{{ "template.cart.header.step1" | translate }}</h1>
  </div>
</section>

@if (productsQuantity() > 0) {
  <!-- eslint-disable @angular-eslint/template/label-has-associated-control -->

  <section class="section-checkout-container">
    <div class="container-fluid checkout-container">
      <div class="row">
        <div class="col-sm-12">
          <div class="row ch-headers">
            <div class="col-sm-9">
              <h2>{{ "template.payment.contact_email" | translate }}</h2>
            </div>
            <div class="col-sm-3 ch-mh">
              <h2>{{ "template.cart.header.your_purchase" | translate }}</h2>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-9">
            <!-- Title bar -->
            <div class="row ch-title-columns-bar">
              <div class="col-sm-10">
                <h3>{{ "template.cart.product" | translate }}</h3>
              </div>
              <div class="col-sm-2">
                <h3 style="margin-bottom: 0px; text-align: center">
                  {{ "template.cart.subtotal" | translate }}
                </h3>
              </div>
            </div>
            <section class="checkout-section">
              <div class="row mrb30" style="margin: 0px">
                <div class="col-sm-12" style="padding: 0px">
                  @for (
                    cartSummaryProductsGroup of cartSummaryProductsGroups();
                    track $index
                  ) {
                    <div
                      class="row ch-product-item item"
                      style="border-bottom: none !important"
                    >
                      <div class="col-sm-12 col-lg-2">
                        <img
                          class="img-producto img-fluid"
                          alt="Producto"
                          src="/assets/images/home-widget/5.jpg"
                        />

                        <div class="ch-mob-actions">
                          <button
                            class="remove-item"
                            (click)="
                              deleteGroup(cartSummaryProductsGroup.products)
                            "
                          >
                            <i class="fa fa-trash" aria-hidden="true"></i>
                          </button>
                          <div class="edit-item">
                            <a
                              (click)="
                                navigateToGroup(cartSummaryProductsGroup)
                              "
                              ><i class="fa fa-pencil" aria-hidden="true"></i
                            ></a>
                          </div>
                        </div>
                        <h2 class="ch-product-name-mob">
                          {{ cartSummaryProductsGroup.name }}
                        </h2>
                      </div>

                      <div class="col-sm-10 ch-mob-container">
                        <div class="row" style="margin-left: 0px">
                          <div class="col-sm-12">
                            <h2 class="ch-product-name">
                              {{ cartSummaryProductsGroup.name }}
                              <button
                                class="remove-item"
                                (click)="
                                  deleteGroup(cartSummaryProductsGroup.products)
                                "
                              >
                                <i class="fa fa-trash" aria-hidden="true"></i>
                              </button>
                              <div class="edit-item">
                                <a
                                  (click)="
                                    navigateToGroup(cartSummaryProductsGroup)
                                  "
                                  ><i
                                    class="fa fa-pencil"
                                    aria-hidden="true"
                                  ></i
                                ></a>
                              </div>
                            </h2>
                          </div>
                          <div class="col-sm-12">
                            @if (cartSummaryProductsGroup.departDate) {
                              <p>
                                {{
                                  "template.product.booking.service_date"
                                    | translate
                                }}
                                <span class="bold">
                                  {{
                                    cartSummaryProductsGroup.departDate
                                      | date: "dd/MM/yyyy"
                                  }}
                                </span>
                              </p>
                              <p>
                                {{
                                  "template.cart.booking_departure_hour"
                                    | translate
                                }}
                                <span class="bold">
                                  {{
                                    cartSummaryProductsGroup.departDate
                                      | date: "HH:mm:ss"
                                  }}
                                </span>
                              </p>
                            }
                            @if (cartSummaryProductsGroup.returnDate) {
                              <p>
                                {{
                                  "template.cart.booking_return_hour"
                                    | translate
                                }}
                                <span class="bold">
                                  {{
                                    cartSummaryProductsGroup.returnDate
                                      | date: "HH:mm:ss"
                                  }}
                                </span>
                              </p>
                            }
                            @if (cartSummaryProductsGroup.tagCode) {
                              <p>
                                {{
                                  "template.product.booking.selected_booking_route"
                                    | translate
                                }}
                                <span class="bold">{{
                                  cartSummaryProductsGroup.tagCode
                                }}</span>
                              </p>
                            }
                          </div>
                          <div class="col-sm-10">
                            @for (
                              cartSummaryProduct of cartSummaryProductsGroup.products;
                              track $index
                            ) {
                              <div class="add-ticket-box-block row">
                                <div class="col-4 col-lg-6 ch-attribute">
                                  <!--TODO: Aquí debería ir solo el atributo (ejemplo: Adulto, Senior, etc)-->
                                  {{ cartSummaryProduct.product.name }}
                                </div>
                                <div class="col-6 col-lg-4 add-ticket-box cart">
                                  <button
                                    class="btn-add prdt"
                                    (click)="add(cartSummaryProduct)"
                                  >
                                    <i
                                      class="fa fa-plus"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <div class="content-copy">
                                    <span class="quantity"
                                      >{{ cartSummaryProduct.quantity }}
                                    </span>
                                  </div>
                                  <span> </span>
                                  <button
                                    class="btn-subtract prdt"
                                    (click)="remove(cartSummaryProduct)"
                                    [disabled]="
                                      cartSummaryProduct.quantity === 0
                                    "
                                  >
                                    <i
                                      class="fa fa-minus"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>

                                <div class="col-2">
                                  <span class="current-price">
                                    {{
                                      cartSummaryProduct.activePrice
                                        | customCurrency: locale()
                                    }}
                                  </span>
                                </div>
                              </div>
                            }
                          </div>
                          <div class="col-sm-2 ch-product-detail">
                            <div class="ch-product-detail-container">
                              @if (
                                cartSummaryProductsGroup.totalActivePrice >
                                cartSummaryProductsGroup.totalReducedPrice
                              ) {
                                <span class="price-tag">
                                  {{
                                    cartSummaryProductsGroup.totalActivePrice
                                      | customCurrency: locale()
                                  }}
                                </span>
                              }
                              <span class="reduced-price-tag">
                                {{
                                  cartSummaryProductsGroup.totalReducedPrice
                                    | customCurrency: locale()
                                }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </section>
          </div>

          <div class="col-md-3 checkout-discounts">
            <hola-purchase-summary
              [showDiscountForm]="true"
              [showOrderSummary]="false"
              [showButtons]="true"
              (checkout)="checkout()"
            ></hola-purchase-summary>
          </div>
        </div>
      </div>
    </div>
  </section>
} @else {
  <section>
    <div class="container">
      <div class="carry-empty-block">
        <div>
          <span class="icon-carry"
            ><i class="fa fa-cart-shopping" aria-hidden="true"></i
          ></span>
          <span>{{ "template.cart.empty_cart" | translate }}</span>
          <p class="btn-action">
            <button
              (click)="navigateToHomePage()"
              (keydown)="navigateToHomePage()"
              class="btn-primary grey"
            >
              <span>{{ "template.cart.continue_shopping" | translate }}</span>
            </button>
          </p>
        </div>
      </div>
      <!-- end carry-empty-block -->
    </div>
  </section>
}
