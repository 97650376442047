import { Injectable, computed, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject } from 'rxjs';

interface CartMenuState {
  isVisible: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CartMenuVisibilityService {
  private state = signal<CartMenuState>({
    isVisible: false,
  });

  public isVisible = computed(() => this.state().isVisible);

  private show$ = new Subject<void>();
  private hide$ = new Subject<void>();

  constructor() {
    this.show$
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.updateVisibility(true));
    this.hide$
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.updateVisibility(false));
  }

  public show(): void {
    this.show$.next();
  }
  public hide(): void {
    this.hide$.next();
  }

  private updateVisibility(isVisible: boolean): void {
    this.state.update((state) => ({ ...state, isVisible: isVisible }));
  }
}
