import { AsyncPipe, NgComponentOutlet } from '@angular/common';
import { Component, effect, inject } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { filter, map, of, switchMap } from 'rxjs';
import { ApiStatus } from '../shared/enums/api-status.enum';
import { AppLanguage } from '../shared/enums/app-language.enum';
import { MetaName } from '../shared/enums/meta-name.enum';
import { PageId } from '../shared/enums/page-id.enum';
import { PageResolverParams } from '../shared/models/page-params.interface';
import { CatalogService } from '../shared/services/catalog.service';
import { CountriesService } from '../shared/services/countries.service';
import { ErrorService } from '../shared/services/error.service';
import { LanguageService } from '../shared/services/language.service';
import { NavigationService } from '../shared/services/navigation.service';
import { SelectProductService } from '../shared/services/select-product.service';
import { SuperProductService } from '../shared/services/super-product.service';
import { TranslateLoaderService } from '../shared/services/translate-loader.service';
import { VerifiedOpinionsService } from '../shared/services/verified-opinions.service';
import { PageService } from './services/page.service';

@Component({
  selector: 'hola-page',
  standalone: true,
  imports: [AsyncPipe, NgComponentOutlet, TranslateModule],
  templateUrl: './page.component.html',
  styleUrl: './page.component.scss',
})
export class PageComponent {
  private readonly pageService = inject(PageService);
  private readonly superProductService = inject(SuperProductService);
  private readonly catalogService = inject(CatalogService);
  private readonly countriesService = inject(CountriesService);
  private readonly selectProductService = inject(SelectProductService);
  private readonly verifiedOpinionsService = inject(VerifiedOpinionsService);
  private readonly route = inject(ActivatedRoute);
  private readonly navigationService = inject(NavigationService);
  private readonly errorService = inject(ErrorService);
  private readonly metaService = inject(Meta);
  private readonly titleService = inject(Title);

  private page$ = toObservable(this.pageService.page);
  private superProduct$ = toObservable(
    this.selectProductService.selectedSuperProduct,
  );

  public status = this.pageService.status;
  public error = this.pageService.error;
  public page = this.pageService.page;
  public ApiStatus = ApiStatus;

  private languageService = inject(LanguageService);
  private appTranslateService = inject(TranslateLoaderService);

  constructor() {
    // TODO mover a app?
    this.superProductService.load();
    this.catalogService.load();
    this.countriesService.load();
    this.verifiedOpinionsService.load();

    this.route.data
      .pipe(
        takeUntilDestroyed(),
        map((data) => data['params'] as PageResolverParams),
      )
      .subscribe((params) => this.initPage(params.language, params.pageId));

    this.page$
      .pipe(
        switchMap((page) =>
          page?.id === PageId.Ticket ? this.superProduct$ : of(page),
        ),
        filter((item) => item !== null && item !== undefined),
        takeUntilDestroyed(),
      )
      .subscribe((item) => {
        this.setPageMetaData(
          item.metaTitle,
          item.metaDescription,
          item.metaKeywords,
        );
      });

    effect(() => {
      if (this.errorService.hasGlobalAPIError()) {
        this.navigationService.navigateToPage(PageId.Error);
      }
    });
  }

  private initPage(language: AppLanguage | null, pageId: PageId | null) {
    console.log(
      `Cargando página ${pageId} en ${language}     =====================================================================`,
    );
    if (
      language &&
      pageId &&
      Object.values(AppLanguage).includes(language) &&
      Object.values(PageId).includes(pageId)
    ) {
      console.log(`Renderiza la página introducida`);
      this.appTranslateService.load(language);
      this.languageService.setLanguage(language);
      this.pageService.loadPage(pageId, language);
      this.pageService.addToHistory(pageId);
    } else if (language && Object.values(AppLanguage).includes(language)) {
      console.log(
        `No existe la página introducida - Redirige a Home con el mismo idioma`,
      );
      this.navigationService.navigateToPage(PageId.Home, language);
    } else {
      console.log(
        `No existe la página introducida - Redirige a Home en el idioma por defecto (EN)`,
      );
      this.navigationService.navigateToPage(PageId.Home, AppLanguage.EN);
    }
  }

  private setPageMetaData(
    title: string,
    description: string,
    keywords: string,
  ): void {
    this.titleService.setTitle(title);
    this.metaService.updateTag({
      name: MetaName.Description,
      content: description,
    });
    this.metaService.updateTag({
      name: MetaName.Keywords,
      content: keywords,
    });
  }
}
