import { Component, computed, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DiscountConcept } from '../../../../models/discount-concept.interface';
import { Discount } from '../../../../models/discount.interface';
import { LanguageService } from '../../../../services/language.service';
import { OrderService } from '../../../../services/order.service';

@Component({
  selector: 'hola-order-discounts-concepts',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './order-discounts-concepts.component.html',
  styleUrl: './order-discounts-concepts.component.scss',
})
export class OrderDiscountsConceptsComponent {
  private readonly languageService = inject(LanguageService);
  private readonly orderService = inject(OrderService);

  public appLanguage = this.languageService.language;
  public itemDiscountConcepts = computed(() =>
    this.orderService
      .discounts()
      .reduce((acc: DiscountConcept[], curr: Discount) => {
        const item = acc.find((a) => a.discountCode === curr?.discountCode);
        if (item) {
          item.productConcepts.push({
            name: curr.productName,
            quantity: curr.quantity,
            amount: curr.discountAmount,
            percentage: curr.discountPercentage,
          });
        } else {
          acc = [
            ...acc,
            {
              discountName: curr.discountName,
              discountCode: curr.discountCode,
              productConcepts: [
                {
                  amount: curr.discountAmount,
                  name: curr.productName,
                  percentage: curr.discountPercentage,
                  quantity: curr.quantity,
                },
              ],
            } as DiscountConcept,
          ];
        }
        return acc;
      }, []),
  );
}
