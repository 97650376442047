import { NgClass } from '@angular/common';
import { Component, computed, inject, input, signal } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { i18nRoutes } from '../../../assets/i18n/routes.i18n';
import { PageService } from '../../page/services/page.service';
import { CartMenuComponent } from '../../shared/components/cart-menu/cart-menu.component';
import { ApiStatus } from '../../shared/enums/api-status.enum';
import { AppLanguage } from '../../shared/enums/app-language.enum';
import { PageId } from '../../shared/enums/page-id.enum';
import { StrapiSuperProductsGroupCode } from '../../shared/enums/strapi-super-products-group-code.enum';
import { SuperProductsGroupProductType } from '../../shared/enums/super-products-group-product-type.enum';
import { HolaProduct } from '../../shared/models/hola-product.interface';

import { toObservable } from '@angular/core/rxjs-interop';
import { combineLatest, filter, Subject, take } from 'rxjs';
import { GtmDataLayerService } from '../../shared/gtm/gtm.service';
import { SuperProduct } from '../../shared/models/super-product.interface';
import { CustomCurrencyPipe } from '../../shared/pipes/custom-currency.pipe';
import { CartMenuVisibilityService } from '../../shared/services/cart-menu-visibility.service';
import { CartService } from '../../shared/services/cart.service';
import { LanguageService } from '../../shared/services/language.service';
import { NavigationService } from '../../shared/services/navigation.service';
import { SuperProductService } from '../../shared/services/super-product.service';
import { SuperProductsGroupService } from '../../shared/services/super-products-group.service';
import { UrlService } from '../../shared/services/url.service';
import { MenuWidgetConfiguration } from './models/menu-widget-configuration.interface';

@Component({
  selector: 'hola-menu-widget',
  standalone: true,
  imports: [
    RouterModule,
    TranslateModule,
    NgClass,
    CartMenuComponent,
    CustomCurrencyPipe,
  ],
  templateUrl: './menu-widget.component.html',
  styleUrl: './menu-widget.component.scss',
})
export class MenuWidgetComponent {
  public configuration = input.required<MenuWidgetConfiguration>();

  private readonly cartService = inject(CartService);
  private readonly cartMenuVisibilityService = inject(
    CartMenuVisibilityService,
  );
  private readonly languageService = inject(LanguageService);
  private readonly pageService = inject(PageService);
  private readonly urlService = inject(UrlService);
  private readonly router = inject(Router);
  private readonly superProductService = inject(SuperProductService);
  private readonly superProductsGroupService = inject(
    SuperProductsGroupService,
  );

  private readonly navigationService = inject(NavigationService);
  private readonly gtmDataLayerService = inject(GtmDataLayerService);

  public AppLanguage = AppLanguage;

  //true cuando hay que mostrar los items del menu de productos
  public megaVisible: boolean = false;
  public langsVisible: boolean = false;

  // número de index del producto seleccionado (mouseover) en el menu
  public menuSelectedProduct: number = -1;
  //public products = this.footerService.products;
  public appLanguage = this.languageService.language;
  public urlHolaBcnBlog = `https://blog.holabarcelona.com/ + ${this.appLanguage()}`;
  public showBuyTicketsButton = signal(true);

  public ApiStatus = ApiStatus;
  public status = this.superProductService.status;
  public error = this.superProductService.error;
  public locale = this.languageService.locale;
  public homeUrl = this.navigationService.homeUrl;
  public mouteAmbHolaUrl = this.navigationService.mouteAmbHolaUrl;
  public appHolaBarcelonaUrl = this.navigationService.appHolaBarcelonaUrl;
  public productsQuantity = this.cartService.productsQuantity;
  public superProductsGroup = computed(() =>
    this.superProductsGroupService.getSuperProductsGroupByCode(
      StrapiSuperProductsGroupCode.Menu,
    ),
  );
  public superProductsGroupProducts = computed(
    () =>
      this.superProductsGroup()?.products?.filter((product) =>
        this.superProductService
          .superProducts()
          .find(
            (p) =>
              product.code === p.code ||
              product.type === SuperProductsGroupProductType.SuperProductGroup,
          ),
      ) || [],
  );
  /* TODO: Habrá que cambiar la agrupación de productos para que use la de menu cuando exista */
  public products = computed(() =>
    this.superProductsGroupProducts().map((p, index) => {
      const product =
        p.type === SuperProductsGroupProductType.SuperProduct
          ? this.superProductService.getSuperProductByCode(p.code)
          : this.superProductsGroupService.getSuperProductsGroupByCode(
              p.code as StrapiSuperProductsGroupCode,
            );
      return {
        title: product.title,
        code: product.code,
        description: product.description,
        shortDescription: product.shortDescription,
        imageUrl: product.mainImageUrl,
        analyticsId: product.analyticsId,
        analyticsName: product.analyticsName,
        analyticsCategory: product.analyticsCategory,
        activePrice: product.activePrice,
        reducedPrice: product.reducedPrice,
        active: product.active,
        visible: product.visible,
        onClick:
          p.type === SuperProductsGroupProductType.SuperProduct
            ? () => {
                this.gtmDataLayerService.productClick(
                  product as SuperProduct,
                  StrapiSuperProductsGroupCode.Menu,
                  index,
                );
                this.navigationService.navigateByUrl(
                  `/${this.languageService.language()}/tickets/${(product as SuperProduct).slug}`,
                );
              }
            : () =>
                this.navigationService.navigateToSuperProductsGroup(
                  p.code as StrapiSuperProductsGroupCode,
                ),
      } as HolaProduct;
    }),
  );

  private products$ = toObservable(this.products);
  private firstOpenning$ = new Subject<void>();

  constructor() {
    combineLatest([
      this.products$.pipe(filter((p) => p?.length > 0)),
      this.firstOpenning$,
    ])
      .pipe(take(1))
      .subscribe(([products]) =>
        this.gtmDataLayerService.impressions(
          products,
          StrapiSuperProductsGroupCode.Menu,
        ),
      );
  }

  public toogleTickets() {
    console.log('toogleando');
    if (this.megaVisible) {
      console.log('ocultando');
      this.megaVisible = false;
    } else {
      console.log('mostrando');
      this.megaVisible = true;
      this.langsVisible = false;
    }
  }

  public showTickets() {
    this.firstOpenning$.next();
    this.megaVisible = true;
    this.langsVisible = false;
  }
  public showLangs() {
    this.langsVisible = true;
    this.megaVisible = false;
  }
  public hideTickets(event: MouseEvent) {
    const currentTarget = event.currentTarget as HTMLElement;
    const relatedTarget = event.relatedTarget as HTMLElement;

    if (!relatedTarget || !currentTarget.contains(relatedTarget)) {
      console.log('Mouse realmente salió del elemento.');
      this.megaVisible = false;
    } else {
      console.log(
        'Mouse sigue dentro del elemento o se movió a un elemento hijo.',
      );
    }
  }
  public hideLangs(event: MouseEvent | FocusEvent) {
    const currentTarget = event.currentTarget as HTMLElement;
    const relatedTarget = event.relatedTarget as HTMLElement;

    if (!relatedTarget || !currentTarget.contains(relatedTarget)) {
      console.log('Mouse realmente salió del elemento.');
      this.langsVisible = false;
    } else {
      console.log(
        'Mouse sigue dentro del elemento o se movió a un elemento hijo.',
      );
    }
  }

  public selectLang(language: AppLanguage) {
    const currentLanguage = this.languageService.language();
    if (language && language !== currentLanguage) {
      const currentPageId = this.pageService.page()?.id;
      if (currentPageId) {
        const nextPageValue = i18nRoutes[currentPageId][language][0];
        const currentTicketName = this.urlService.url()?.getTicketName();
        // Si es tickets* quitamos el * añadimos el ticket y navegamos.
        const nextRoute =
          currentPageId === PageId.Ticket && currentTicketName
            ? `${nextPageValue.split('*').join('')}/${currentTicketName}`
            : nextPageValue;
        this.router.navigate([nextRoute]);
      } else {
        this.router.navigate([`/${language}`]);
      }
    }
  }

  //nos dice si un producto hay que mostrarlo
  public mostrarProducto(selectedProduct: number): boolean {
    if (this.menuSelectedProduct == selectedProduct) {
      return true;
    }
    return false;
  }

  //activa el producto que hay que mostrar
  public muestraProducto(selectedProduct: number) {
    this.menuSelectedProduct = selectedProduct;
  }

  public showCart(): void {
    this.cartMenuVisibilityService.show();
  }

  public onClickBuyTickets(): void {
    this.navigationService.navigateToPage(PageId.Tickets);
  }
}
