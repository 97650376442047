<!-- img-header -->
<section class="img-header">
  <div class="container">
    <div class="row">
      <div class="container-header-img">
        @if (configuration().images.length > 0) {
          <!-- obtener las images del id de imagenes -->
          @for (image of configuration().images; track $index) {
            <img
              [src]="image.url"
              [alt]="configuration().title"
              class="img-fluid img-header"
              style="min-height: 401px"
            />
          }
        }
        <div class="container tcontainer">
          <div class="row">
            <!-- OPINIONES VERIFICADAS STORE VALORATION -->
            <hola-opiniones-verificadas-store-valoration></hola-opiniones-verificadas-store-valoration>

            <div class="container-header-img-txt">
              <h1 class="title-page">{{ configuration().title }}</h1>
              <h2 class="subtitle">{{ configuration().description }}</h2>
            </div>
            @if (configuration().button_text) {
              <div class="container-header-img-button">
                <a
                  [routerLink]="homeUrl()"
                  class="button_text-page btn-comprar"
                  >{{ configuration().button_text }}</a
                >
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- img-header -->
