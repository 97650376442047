import { Component, input } from '@angular/core';
import { AboutWidgetConfiguration } from './models/about-widget-configuration.interface';

@Component({
  selector: 'hola-about-widget',
  standalone: true,
  imports: [],
  templateUrl: './about-widget.component.html',
  styleUrl: './about-widget.component.scss',
})
export class AboutWidgetComponent {
  configuration = input.required<AboutWidgetConfiguration>();
}
