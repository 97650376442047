import { AbstractControl, ValidatorFn } from '@angular/forms';

export function emailValidator(
  control: AbstractControl,
): { [key: string]: boolean } | null {
  if (control != null && control.value != null) {
    const emailRegExp = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
    if (!new RegExp(emailRegExp).test(control.value)) {
      return { invalid: true };
    }
  }
  return null;
}

export function validOrEmptyEmailValidator(
  control: AbstractControl,
): { [key: string]: boolean } | null {
  if (control != null && control.value != null) {
    if (!control.value || control.value === '') {
      return null;
    }
    const emailRegExp = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
    if (!new RegExp(emailRegExp).test(control.value)) {
      return { invalid: true };
    }
  }
  return null;
}

export function emailEqualityValidator(email: {
  (): AbstractControl;
}): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!(control && control.value)) {
      return null;
    }

    const emailControl = email();
    if (emailControl && emailControl.value !== control.value) {
      return { equality: true };
    }

    return null;
  };
}
