import { Component, input } from '@angular/core';
import { UnsubscribeWidgetConfiguration } from './models/unsubscribe-widget-configuration.interface';

@Component({
  selector: 'hola-unsubscribe-widget',
  standalone: true,
  imports: [],
  templateUrl: './unsubscribe-widget.component.html',
  styleUrl: './unsubscribe-widget.component.scss',
})
export class UnsubscribeWidgetComponent {
  configuration = input.required<UnsubscribeWidgetConfiguration>();
}
