<!-- home intro -->
@if (configuration().title) {
  <section class="home_intro">
    <div class="container-fluid home-intro-container">
      <div class="col-home">
        <div class="row">
          <div class="col-sm-4">
            <h1 class="home_intro_title">{{ configuration().title }}</h1>
          </div>
          <div class="col-sm-8">
            <div class="home_intro_description">
              <div class="home_intro_desc intro-des">
                {{ configuration().description }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- home intro -->
}
