import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { EMPTY, Observable, catchError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Order } from '../models/order.interface';
import { ErrorService } from './error.service';

export interface HppDataRedsys {
  Ds_MerchantParameters: string;
  Ds_Signature: string;
  Ds_SignatureVersion: string;
}

@Injectable({ providedIn: 'root' })
export class OrderApiService {
  public orderApiUrl = `${environment.clientApiUrl}/sell-service/order`;

  private readonly http = inject(HttpClient);

  // asignar valor incorrecto para prueba de error en compra
  //private orderApiUrl = `${environment.clientApiUrl}/sell-service/orderX`;
  private errorService = inject(ErrorService);

  public createOrder(order: Order | null): Observable<Order> {
    if (!order) {
      return EMPTY;
    }

    const httpParams = new HttpParams()
      .set('app_key', environment.appKey)
      .set('app_id', environment.appId);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.http
      .put<Order>(this.orderApiUrl, order, {
        params: httpParams,
      })
      .pipe(
        catchError(() => {
          this.errorService.set('Fallo creación orden');
          return EMPTY;
        }),
      );
  }

  public loadHppData(order: Order | null): Observable<HppDataRedsys> {
    if (!order) {
      return EMPTY;
    }
    const httpParams = new HttpParams()
      .set('app_key', environment.appKey)
      .set('app_id', environment.appId);
    const hppDataUrl = `${this.orderApiUrl}/${order.orderCode}/HppLightBoxPaymentData`;

    return this.http.get<HppDataRedsys>(hppDataUrl, { params: httpParams });
  }
}
