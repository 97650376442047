<!-- tickets intro -->
@if (configuration().title) {
  <section class="tickets_intro">
    <div class="tickets-intro-container">
      <div>
        <h1 class="tickets_intro_title">{{ configuration().title }}</h1>
      </div>
      <div>
        <div class="tickets_intro_description">
          {{ configuration().description }}
        </div>
      </div>
    </div>
  </section>
  <!-- tickets intro -->
}
