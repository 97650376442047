@if (status() === ApiStatus.Success) {
  @for (widget of page()?.widgets; track $index) {
    @if (widget.component && widget.active) {
      <ng-container
        *ngComponentOutlet="
          widget.component;
          inputs: { configuration: widget.configuration }
        "
      />
    }
  }
} @else if (status() === ApiStatus.Error) {
  <p class="page-error">{{ error()?.message }}</p>
}
