import { Component, computed, inject, input } from '@angular/core';
import { CartProduct } from '../../../../shared/models/cart-product.interface';
import { CustomCurrencyPipe } from '../../../../shared/pipes/custom-currency.pipe';
import { CartTempService } from '../../../../shared/services/cart-temp.service';
import { LanguageService } from '../../../../shared/services/language.service';

@Component({
  selector: 'hola-product-select',
  standalone: true,
  imports: [CustomCurrencyPipe],
  templateUrl: './product-select.component.html',
  styleUrl: './product-select.component.scss',
})
export class ProductSelectComponent {
  private readonly cartTempService = inject(CartTempService);
  private readonly languageService = inject(LanguageService);

  public locale = this.languageService.locale;

  public cartProduct = input.required<CartProduct>();
  public product = computed(() => this.cartProduct().product);
  public reservationInfo = computed(() => this.cartProduct().reservationInfo);
  public reservationForm = computed(() => this.cartProduct().reservationForm);
  public activePrice = computed(() => this.product().activePrice);
  public reducedPrice = computed(() => this.product().reducedPrice);

  public quantity = computed(
    () =>
      this.cartTempService
        .products()
        .filter(
          (p) =>
            this.product().code === p.product.code &&
            this.reservationForm()?.departTrip?.tagCode ===
              p.reservationForm?.departTrip?.tagCode &&
            this.reservationForm()?.departTrip?.startDatetime ===
              p.reservationForm?.departTrip?.startDatetime &&
            this.reservationForm()?.returnTrip?.startDatetime ===
              p.reservationForm?.returnTrip?.startDatetime,
        ).length,
  );

  public add(): void {
    this.cartTempService.add(this.cartProduct());
  }
  public remove(): void {
    this.cartTempService.remove(this.cartProduct());
  }
}
