import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  OnDestroy,
  PLATFORM_ID,
  inject,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SuperProduct } from '../../../../shared/models/super-product.interface';
import { LanguageService } from '../../../../shared/services/language.service';

@Component({
  selector: 'hola-product-module-slider',
  standalone: true,
  imports: [TranslateModule, CommonModule],
  templateUrl: './product-module-slider.component.html',
  styleUrl: './product-module-slider.component.scss',
})
export class ProductModuleSliderComponent implements AfterViewInit, OnDestroy {
  @Input() superProduct!: SuperProduct;
  private readonly languageService = inject(LanguageService);
  public currentSlideIndex = 0;
  private autoSlideInterval: ReturnType<typeof setInterval> | null = null;
  public locale = this.languageService.locale;

  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.startAutoSlide();
    }
  }

  startAutoSlide() {
    this.autoSlideInterval = setInterval(() => {
      this.nextSlide();
    }, 5000); // Cambia de imagen cada 5 segundos
  }

  ngOnDestroy() {
    this.stopAutoSlide();
  }

  stopAutoSlide() {
    if (this.autoSlideInterval) {
      clearInterval(this.autoSlideInterval);
    }
  }

  nextSlide() {
    console.log('next slide');
    this.currentSlideIndex =
      (this.currentSlideIndex + 1) % this.superProduct.sliderImagesUrls.length;
  }

  prevSlide() {
    this.currentSlideIndex =
      (this.currentSlideIndex - 1 + this.superProduct.sliderImagesUrls.length) %
      this.superProduct.sliderImagesUrls.length;
  }

  goToSlide(index: number) {
    this.currentSlideIndex = index;
  }
}
