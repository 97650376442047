import { Component, input } from '@angular/core';
import { GeneralAvisWidgetConfiguration } from './models/general-avis-widget-configuration.interface';

@Component({
  selector: 'hola-general-avis-widget',
  standalone: true,
  imports: [],
  templateUrl: './general-avis-widget.component.html',
  styleUrl: './general-avis-widget.component.scss',
})
export class GeneralAvisWidgetComponent {
  configuration = input.required<GeneralAvisWidgetConfiguration>();
}
