import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { EnvironmentName } from '../../../environments/environment-name.enum';
import { AppLanguage } from '../enums/app-language.enum';

enum TranslationFileType {
  Local = 'local',
  Build = 'build',
}

interface Translation {
  lang: string;
  value: object | null;
}

@Injectable({
  providedIn: 'root',
})
export class TranslateLoaderService {
  private http = inject(HttpClient);
  private translateService = inject(TranslateService);

  private load$ = new Subject<AppLanguage>();
  private translation$ = this.load$.pipe(
    switchMap((lang) =>
      combineLatest([
        this.loadTranslationFromApi(lang).pipe(
          startWith({ lang, value: null }),
        ),
        this.loadTranslationFromFile(lang, TranslationFileType.Build),
        this.loadTranslationFromFile(lang, TranslationFileType.Local),
      ]).pipe(
        map((translations) => ({
          lang,
          value: this.generateTranslations(translations),
        })),
      ),
    ),
  );

  public currentTranslation = signal<Translation | null>(null);

  constructor() {
    this.translation$.pipe(takeUntilDestroyed()).subscribe((translation) => {
      this.translateService.setTranslation(translation.lang, translation.value);
      this.translateService.use(translation.lang);
      this.currentTranslation.set(translation);
    });
  }

  public load(lang: AppLanguage, force = false) {
    if (
      (Object.values(AppLanguage).includes(lang) &&
        lang !== this.currentTranslation()?.lang) ||
      force
    ) {
      this.load$.next(lang);
    }
  }

  private loadTranslationFromFile(
    lang: AppLanguage,
    type: TranslationFileType,
  ): Observable<Translation> {
    const i18nFileRoute = this.getTranslationFilePath(lang, type);
    return this.http.get(i18nFileRoute).pipe(
      map((translation) => ({ lang, value: translation })),
      catchError(() => of({ lang, value: null })),
    );
  }

  private loadTranslationFromApi(lang: AppLanguage): Observable<Translation> {
    const apiTranslationFileUrl = this.getApiTranslationUrl(lang);
    return this.http.get(apiTranslationFileUrl).pipe(
      map((translation) => ({ lang, value: translation })),
      catchError(() => of({ lang, value: null })),
    );
  }

  private getApiTranslationUrl(lang: AppLanguage) {
    return `${environment.clientApiUrl}/translation/application/WebVendaECNR/version/${environment.translationApiVersion}/language/${lang}?app_key=${environment.appKey}&app_id=${environment.appId}`;
  }
  private getTranslationFilePath(lang: AppLanguage, type: TranslationFileType) {
    return `/assets/i18n/${lang}-${type}.json`;
  }

  private generateTranslations(translations: Translation[]): object {
    if (environment.name === EnvironmentName.DEV) {
      return translations.filter((t) => t.value)[0]?.value || {};
    } else {
      return translations.filter((t) => t.value)[0]?.value || {};
    }
  }
}
