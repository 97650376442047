import { Component, effect, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FaqWidgetConfiguration } from './models/faq-widget-configuration.interface';

@Component({
  selector: 'hola-faq-widget',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './faq-widget.component.html',
  styleUrl: './faq-widget.component.scss',
})
export class FaqWidgetComponent {
  configuration = input.required<FaqWidgetConfiguration>();

  constructor() {
    effect(() => console.log('faq configuration', this.configuration()));
  }
}
