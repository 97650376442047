import { Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CustomCurrencyPipe } from '../../../../pipes/custom-currency.pipe';
import { CartService } from '../../../../services/cart.service';
import { LanguageService } from '../../../../services/language.service';

@Component({
  selector: 'hola-order-products-summary',
  standalone: true,
  imports: [TranslateModule, CustomCurrencyPipe],
  templateUrl: './order-products-summary.component.html',
  styleUrl: './order-products-summary.component.scss',
})
export class OrderProductsSummaryComponent {
  private readonly languageService = inject(LanguageService);
  private readonly cartService = inject(CartService);

  public locale = this.languageService.locale;
  public cartSummaryProductsGroups = this.cartService.cartSummaryProductsGroups;
}
