<!--TODO: Consultar a David si el producto está visible para este canal-->

@if (superProduct() && superProduct().visible && superProduct().active) {
  <section class="section-detail-tabs-mob">
    <div class="detail-container container-fluid">
      <div class="col-detail">
        <div class="detail-tabs">
          <div class="detail-tab-link active" data-link="rate-product">
            {{ "template.product.detail_tabs.prices" | translate }}
          </div>
          <div class="detail-tab-link" data-link="info-product">
            {{ "template.product.detail_tabs.info" | translate }}
          </div>
          <div style="clear: both"></div>

          <!--TODO: Controlar error -->
        </div>
      </div>
    </div>
    <div style="clear: both"></div>
  </section>

  <section>
    <div
      class="detail-top-banner-noimg product-name-container detail-container"
      id="top"
    >
      <div class="col-detail">
        <h1>
          {{ superProduct().title }}
        </h1>
        <div class="product-description">
          {{ superProduct().shortDescription }}
        </div>
        <!--TODO: Mostrar las calificaciones de opiniones verificadas-->
      </div>
    </div>
  </section>

  <section class="section-detail-tabs">
    <div class="detail-container">
      <div class="col-detail">
        <div class="detail-tabs">
          <div class="detail-tab-link active" data-link="rate-product">
            {{ "template.product.detail_tabs.prices" | translate }}
          </div>
          <div class="detail-tab-link" data-link="info-product">
            {{ "template.product.detail_tabs.info" | translate }}
          </div>
          <div style="clear: both"></div>
        </div>
      </div>
    </div>
    <div style="clear: both"></div>
  </section>

  <section class="section-message onlydesk">
    <!--TODO: Hay que controlar el aviso si lo hay, según el tipo puede cambiar la class "warning", también hace falta una función que una vez cerrado el aviso no se lo vuelva a mostrar a esa sesión-->
    @if (AVIS?.title) {
      <div class="detail-container container-disable-message">
        <div class="row col-detail">
          <div class="col-sm-12">
            <div class="disable-message {{ AVIS?.type }}">
              <div class="col-2 col-sm-2 col-md-1">
                <div class="info-product-ico"></div>
              </div>
              <div class="col-10 col-sm-10 col-md-11">
                <div class="disable-message-title">
                  {{ AVIS?.title }}
                </div>
                <div class="disable-message-text">{{ AVIS?.text }}</div>
                <button
                  type="button"
                  class="close closeAvis"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  &times;
                </button>
              </div>
              <div style="clear: both"></div>
            </div>
          </div>
        </div>
      </div>
    }
  </section>
  <!--TODO: Comprobar si este producto está enabled para este canal-->
  <!-- TICKED + DESCRIPTION BLOCK -->

  <section class="terms_and_conditions_section">
    <!--TODO: Poner en el hidden la currency que corresponda-->
    <input type="hidden" value="EUR" id="currency-symbol" />
    <div class="detail-container">
      <div class="row col-detail">
        <div class="col-12 col-lg-7 detail-sale-intro-container">
          <div class="detail-sale-intro">
            @if (superProduct().moduleSlider) {
              <hola-product-module-slider
                [superProduct]="superProduct()"
              ></hola-product-module-slider>
            }
            <div [innerHTML]="superProduct().summary"></div>
          </div>
        </div>
        <div class="onlymob">
          <!--TODO: Hay que controlar el aviso si lo hay, según el tipo puede cambiar la class "warning", también hace falta una función que una vez cerrado el aviso no se lo vuelva a mostrar a esa sesión-->
          @if (AVIS?.title) {
            <div
              id="avis-SUPERPRODUCTO-SKU"
              class="detail-container container-disable-message"
            >
              <div class="row col-detail">
                <div class="col-sm-12">
                  <div class="disable-message {{ AVIS?.type }}">
                    <div class="col-2 col-sm-2 col-md-1">
                      <div class="info-product-ico"></div>
                    </div>
                    <div class="col-10 col-sm-10 col-md-11">
                      <div class="disable-message-title">
                        {{ AVIS?.title }}
                      </div>
                      <div class="disable-message-text">{{ AVIS?.text }}</div>
                      <button
                        type="button"
                        class="close closeAvis"
                        data-dismiss="modal"
                        aria-hidden="true"
                      >
                        &times;
                      </button>
                    </div>
                    <div style="clear: both"></div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        <div class="col-12 col-lg-5 mrg0">
          <div id="rate-product">
            <div class="detail-parse-product">
              @if (superProduct() && isBrowser) {
                <div class="detail-parse-product-price container-fluid">
                  <div class="row">
                    @if (superProduct().percentageDiscount !== 0) {
                      <div class="col-2 col-sm-3">
                        <span class="discount-info"
                          >-{{ superProduct().percentageDiscount * 100 }}%</span
                        >
                      </div>
                    } @else if (superProduct().fixDiscount !== 0) {
                      <div class="col-2 col-sm-3">
                        <span class="discount-info"
                          >-{{
                            superProduct().fixDiscount
                              | customCurrency: locale()
                          }}</span
                        >
                      </div>
                    }
                    <div class="col-10 col-sm-9">
                      <div class="detail-price-from">
                        {{ "template.cart.from" | translate }}
                        @if (
                          superProduct().activePrice >
                          superProduct().reducedPrice
                        ) {
                          <span>
                            {{
                              superProduct().activePrice
                                | customCurrency: locale()
                            }}
                          </span>
                        }
                      </div>
                      <div class="detail-reduced-price-from">
                        {{
                          superProduct().reducedPrice | customCurrency: locale()
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="detail-parse-product-addToCart">
                  <div class="product-add-to-cart big-ticket-box container">
                    @if (isBrowser && reservationInfo()?.bookingServiceCode) {
                      <!-- TODO: Quizá sea neceraio poner un spinner o algo-->
                      <hola-product-reservation
                        [(reservation)]="reservationForm"
                      ></hola-product-reservation>
                    }
                    <div>
                      <h2 class="detail-label">
                        {{ "template.cart.ticket_type" | translate }}
                      </h2>
                      @for (p of parentProducts(); track $index) {
                        <div>
                          <label
                            class="detail-people-content detail-ticket-type rad"
                          >
                            <input
                              type="radio"
                              name="ticket_type"
                              class="ticket_type_radio"
                              (click)="selectParentProduct(p)"
                              [checked]="
                                selectedParentProduct()?.code === p.code
                              "
                            />
                            <i></i>
                            <div class="ticket_type_label">
                              {{ p?.name }}
                            </div>
                          </label>
                        </div>
                      }
                    </div>

                    @if (addToCartProducts().length > 0) {
                      <h2 class="detail-label">
                        {{ "template.cart.fares" | translate }}
                      </h2>
                      <div class="actions-blocks">
                        <div class="actions-block container">
                          @for (
                            cartProduct of addToCartProducts();
                            track $index
                          ) {
                            <hola-product-select
                              [cartProduct]="cartProduct"
                            ></hola-product-select>
                          }
                        </div>
                      </div>
                    }

                    <div class="resume-block">
                      <h3 class="price-text">
                        {{ "template.product.total" | translate }}
                      </h3>
                      <div class="price-values">
                        <span class="price">
                          <span>
                            {{ totalReducedPrice() | customCurrency: locale() }}
                          </span>
                        </span>
                        @if (totalActivePrice() > totalReducedPrice()) {
                          <span class="line-through">
                            <span>
                              {{
                                totalActivePrice() | customCurrency: locale()
                              }}
                            </span>
                          </span>
                        }
                        <div style="clear: both"></div>
                      </div>
                      <div style="clear: both"></div>
                      <br /><br /><br />
                      <button
                        (click)="checkout()"
                        class="btn-secundary red buy_product_now"
                      >
                        {{ "template.product.buy_now" | translate }}
                      </button>
                      <div style="clear: both"></div>
                    </div>
                    <!-- end resume-block -->
                  </div>
                </div>
              } @else {
                @if (isBrowser) {
                  <p>No existe el producto seleccionado</p>
                } @else {
                  <div class="detail-parse-product-price container-fluid">
                    <hola-shimmer-placeholder></hola-shimmer-placeholder>
                  </div>
                }
              }

              <!-- <div>
                                    Variantes:
                                    @for (v of selectedProductVariants(); track $index) {
                                    <div>
                                        <p>{{ v.code }}</p>
                                    </div>
                                    }
                                </div>
                                <div>
                                    Variantes:
                                    @for (v of productsWithoutVariants(); track $index) {
                                    <div>
                                        <p>{{ v.code }}</p>
                                    </div>
                                    }
                                </div> -->

              <div style="clear: both"></div>
              <div id="info-product"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    class="product-detail-content terms_and_conditions_section detail_section"
    #content
  >
    <div class="container-fluid detail-container-info">
      <div
        class="mrb30 hl_bold_content"
        style="padding-bottom: 0px; padding-top: 45px"
      >
        <h2 class="detail-container-info-title">
          <div class="info-product-ico"></div>
          Information
        </h2>
        <button
          data-link="top"
          class="detail-tab-link info-container-top"
          (click)="scrollToTop()"
          (keydown)="scrollToTop()"
        >
          &nbsp;
        </button>
        <div
          class="info-container"
          [innerHTML]="superProduct().description"
        ></div>
        <div id="detail-product"></div>
        <div class="info-container">
          <!--TODO: Controlar los distintos modules del producto-->
          @if (superProduct().moduleAccordion) {
            <hola-product-module-accordion
              [superProduct]="superProduct()"
            ></hola-product-module-accordion>
          }

          @if (superProduct().moduleBox) {
            <hola-product-module-boxes
              [superProduct]="superProduct()"
            ></hola-product-module-boxes>
          }
        </div>
        <!--TODO: Valoraciones de opiniones verificadas, configurable para cada producto si se muestra o no-->
      </div>
      <div style="clear: both"></div>
    </div>
    <!--TODO: Carrousel de productos relacionados-->
  </section>
} @else {
  <!-- TODO maquetación producto no existe -->
  <section>
    <div class="container">
      <div class="carry-empty-block">
        <div>
          <span class="icon-carry"
            ><i class="fa fa-cart-shopping" aria-hidden="true"></i
          ></span>
          <span
            >{{ "template.cart.product" | translate }}
            {{ "template.discover_catalunya.season_ended" | translate }}</span
          >
          <p class="btn-action">
            <button
              (click)="navigateToHomePage()"
              (keydown)="navigateToHomePage()"
              class="btn-primary grey"
            >
              <span>{{ "template.cart.continue_shopping" | translate }}</span>
            </button>
          </p>
        </div>
      </div>
      <!-- end carry-empty-block -->
    </div>
  </section>
}
