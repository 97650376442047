import { formatCurrency } from '@angular/common';
import { Pipe, PipeTransform, inject } from '@angular/core';
import { LanguageService } from '../services/language.service';

@Pipe({
  name: 'customCurrency',
  standalone: true,
})
export class CustomCurrencyPipe implements PipeTransform {
  private readonly languageService = inject(LanguageService);

  transform(value: number, locale = this.languageService.locale()): string {
    return formatCurrency(value, locale, '€', 'EUR');
  }
}
