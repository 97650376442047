import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AppLanguage } from '../enums/app-language.enum';
import { StrapiVerifiedOpinionData } from '../models/strapi.interface';
import { VerifiedOpinion } from '../models/verified-opinion.interface';
import { StrapiService } from './strapi.service';

@Injectable({
  providedIn: 'root',
})
export class StrapiVerifiedOpinionsService extends StrapiService<StrapiVerifiedOpinionData> {
  private verifiedOpinionesApiUrl = (locale: AppLanguage) =>
    `${environment.strapiUrl}/op-verificadas-configs?locale=${locale}`;

  public loadVerifiedOpinions(
    locale = AppLanguage.EN,
  ): Observable<VerifiedOpinion[]> {
    return this.getAll(`${this.verifiedOpinionesApiUrl(locale)}`).pipe(
      map((strapiVerifiedOpinions) =>
        this.mapVerifiedOpinions(strapiVerifiedOpinions),
      ),
    );
  }

  private mapVerifiedOpinions(
    strapiVerifiedOpinions: StrapiVerifiedOpinionData[],
  ): VerifiedOpinion[] {
    return strapiVerifiedOpinions.map((strapiVerifiedOpinionData) =>
      this.mapVerifiedOpinion(strapiVerifiedOpinionData),
    );
  }

  private mapVerifiedOpinion(
    strapiVerifiedOpinionData: StrapiVerifiedOpinionData,
  ): VerifiedOpinion {
    return {
      productValorationScript:
        strapiVerifiedOpinionData.attributes.productValorationScript,
      siteValorationCarrousel:
        strapiVerifiedOpinionData.attributes.siteValorationCarrousel,
      siteValorationScript:
        strapiVerifiedOpinionData.attributes.siteValorationScript,
      siteValorationUrl: strapiVerifiedOpinionData.attributes.siteValorationUrl,
    };
  }
}
