import { Component, inject, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { OpinionesVerificadasStoreValorationComponent } from '../../shared/components/opiniones-verificadas/components/opiniones-verificadas-store-valoration/opiniones-verificadas-store-valoration.component';
import { NavigationService } from '../../shared/services/navigation.service';
import { HeaderPrincipalImageWidgetConfiguration } from './models/header-principal-image-widget-configuration.interface';

@Component({
  selector: 'hola-header-principal-image-widget',
  standalone: true,
  imports: [RouterModule, OpinionesVerificadasStoreValorationComponent],
  templateUrl: './header-principal-image-widget.component.html',
  styleUrl: './header-principal-image-widget.component.scss',
})
export class HeaderPrincipalImageWidgetComponent {
  public configuration =
    input.required<HeaderPrincipalImageWidgetConfiguration>();

  private readonly navigationService = inject(NavigationService);
  public homeUrl = this.navigationService.homeUrl;
}
