import { Component, input } from '@angular/core';
import { HeaderWidgetConfiguration } from './models/header-widget-configuration.interface';

@Component({
  selector: 'hola-header-widget',
  standalone: true,
  imports: [],
  templateUrl: './header-widget.component.html',
  styleUrl: './header-widget.component.scss',
})
export class HeaderWidgetComponent {
  configuration = input.required<HeaderWidgetConfiguration>();
}
