<div class="row ch-discount-import-title mt28">
  <div class="col-sm-12">
    <h2>{{ "template.cart.discount.resume" | translate }}</h2>
  </div>
</div>

@for (group of cartSummaryProductsGroups(); track $index) {
  <div class="row row-discount-code code-valid ch-product-item">
    <div class="col-sm-7 col-xl-8 ch-discount-name">
      <h2>{{ group.name }}</h2>
    </div>
    <div class="col-sm-5 col-xl-4 ch-resume-amount">
      <h2>{{ group.totalReducedPrice | customCurrency: locale() }}</h2>
    </div>
  </div>
}
