import { computed, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject } from 'rxjs';
import { PageService } from '../../page/services/page.service';
import { ApiStatus } from '../enums/api-status.enum';
import { PacksCatalogService } from './packs-catalog.service';
import { SuperProductService } from './super-product.service';
import { TicketsCatalogService } from './tickets-catalog.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private pageService = inject(PageService);
  private superProductsService = inject(SuperProductService);
  private ticketsCatalogService = inject(TicketsCatalogService);
  private packsCatalogService = inject(PacksCatalogService);

  public hasGlobalAPIError = computed(
    () =>
      this.pageService.status() === ApiStatus.Error ||
      this.superProductsService.status() === ApiStatus.Error ||
      this.ticketsCatalogService.status() === ApiStatus.Error ||
      this.packsCatalogService.status() === ApiStatus.Error,
  );

  private state = signal<string | null>(null);
  private set$ = new Subject<string | null>();

  public error = computed(() => this.state());

  constructor() {
    this.set$
      .pipe(takeUntilDestroyed())
      .subscribe((message) => this.state.set(message));
  }

  public set(message: string): void {
    this.set$.next(message);
  }
  public clear(): void {
    this.set$.next(null);
  }
}
