<section style="margin-top: 40px">
  <div class="container-fluid home-products-container home-body">
    <div class="col-home d-flex flex-wrap" id="contenedor">
      @for (product of products(); track $index) {
        <!-- #FEATURED HOME BLOCKS -->
        <div
          class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-4 content-col-home comprar_tours_{{
            product.code
          }}"
          #elemento
        >
          <div class="featured-box">
            <!-- eslint-disable-next-line -->
            <a
              (click)="product?.onClick()"
              [title]="product.title"
              class="home_event_buy"
            >
              <div
                class="img-over-block"
                data-discount-label="Hasta 10%"
                [attr.data-discount]="
                  product.percentageDiscount !== 0
                    ? '-' + product.percentageDiscount * 100 + '%'
                    : product.fixDiscount !== 0
                      ? '-' + (product.fixDiscount | customCurrency: locale())
                      : null
                "
              >
                <img
                  class="img-bg"
                  [alt]="product.title"
                  [src]="product.imageUrl"
                />
              </div>
              <span class="feature-caption-box title-box">
                {{ product.title }}
              </span>
              <div class="blank-line"></div>
              <p class="feature-caption-box">{{ product.shortDescription }}</p>
              <div class="blank-line"></div>
              <div class="desde">{{ "template.menu.desde" | translate }}</div>
              <div class="blank-line"></div>
              @if (product.activePrice > product.reducedPrice) {
                <div class="preciosd">
                  {{ product.activePrice | customCurrency: locale() }}
                </div>
              }
              <div class="blank-line"></div>

              <div class="preciocd">
                <span class="valorPreciocd">
                  {{ product.reducedPrice | customCurrency: locale() }}
                </span>
                <span class="btn-comprar"
                  >{{ "template.product.buy" | translate }}
                </span>
              </div>
            </a>
          </div>
          <!-- end featured-box-large -->
        </div>
      }
    </div>
  </div>
  <div style="clear: both"></div>
  <div style="float: left; margin-bottom: 55px; width: 100%"></div>
</section>
