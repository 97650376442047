import { AboutWidgetComponent } from '../../page-widget/about-widget/about-widget.component';
import { AppDownloadButtonsWidgetComponent } from '../../page-widget/app-download-buttons-widget/app-download-buttons-widget.component';
import { AvisWidgetComponent } from '../../page-widget/avis-widget/avis-widget.component';
import { BlogWidgetComponent } from '../../page-widget/blog-widget/blog-widget.component';
import { CartWidgetComponent } from '../../page-widget/cart-widget/cart-widget.component';
import { CheckoutWidgetComponent } from '../../page-widget/checkout-widget/checkout-widget.component';
import { ContactWidgetComponent } from '../../page-widget/contact-widget/contact-widget.component';
import { CookiesWidgetComponent } from '../../page-widget/cookies-widget/cookies-widget.component';
import { DiscoverCatalunyaWidgetComponent } from '../../page-widget/discover-catalunya-widget/discover-catalunya-widget.component';
import { DiscoverWidgetComponent } from '../../page-widget/discover-widget/discover-widget.component';
import { FaqWidgetComponent } from '../../page-widget/faq-widget/faq-widget.component';
import { FooterWidgetComponent } from '../../page-widget/footer-widget/footer-widget.component';
import { FreeHtmlWidgetComponent } from '../../page-widget/free-html-widget/free-html-widget.component';
import { GeneralAvisWidgetComponent } from '../../page-widget/general-avis-widget/general-avis-widget.component';
import { HeaderPrincipalImageWidgetComponent } from '../../page-widget/header-principal-image-widget/header-principal-image-widget.component';
import { HeaderWidgetComponent } from '../../page-widget/header-widget/header-widget.component';
import { HomeIntroWidgetComponent } from '../../page-widget/home-intro-widget/home-intro-widget.component';
import { HomeProductsWidgetComponent } from '../../page-widget/home-products-widget/home-products-widget.component';
import { InfoAlertWidgetComponent } from '../../page-widget/info-alert-widget/info-alert-widget.component';
import { LegalAdviceSignupWidgetComponent } from '../../page-widget/legal-advice-signup-widget/legal-advice-signup-widget.component';
import { LegalWidgetComponent } from '../../page-widget/legal-widget/legal-widget.component';
import { LinkCardsWidgetComponent } from '../../page-widget/link-cards-widget/link-cards-widget.component';
import { MenuWidgetComponent } from '../../page-widget/menu-widget/menu-widget.component';
import { MoreInformationWidgetComponent } from '../../page-widget/more-information-widget/more-information-widget.component';
import { MouteAmbHolaWidgetComponent } from '../../page-widget/moute-amb-hola-widget/moute-amb-hola-widget.component';
import { OpinionesVerificadasCarrouselWidgetComponent } from '../../page-widget/opiniones-verificadas-carrousel-widget/opiniones-verificadas-carrousel-widget.component';
import { PackWidgetComponent } from '../../page-widget/pack-widget/pack-widget.component';
import { ProductCardWidgetComponent } from '../../page-widget/product-card-widget/product-card-widget.component';
import { ProductDetailWidgetComponent } from '../../page-widget/product-detail-widget/product-detail-widget.component';
import { PromoAppWidgetComponent } from '../../page-widget/promo-app-widget/promo-app-widget.component';
import { PromoWidgetComponent } from '../../page-widget/promo-widget/promo-widget.component';
import { PromotionWidgetComponent } from '../../page-widget/promotion-widget/promotion-widget.component';
import { RecoverWidgetComponent } from '../../page-widget/recover-widget/recover-widget.component';
import { SectionIntroWidgetComponent } from '../../page-widget/section-intro-widget/section-intro-widget.component';
import { ShowInfoWidgetComponent } from '../../page-widget/show-info-widget/show-info-widget.component';
import { SightseeingWidgetComponent } from '../../page-widget/sightseeing-widget/sightseeing-widget.component';
import { SitemapWidgetComponent } from '../../page-widget/sitemap-widget/sitemap-widget.component';
import { SliderWidgetComponent } from '../../page-widget/slider-widget/slider-widget.component';
import { TermsAndConditionsWidgetComponent } from '../../page-widget/terms-and-conditions-widget/terms-and-conditions-widget.component';
import { ThanksWidgetComponent } from '../../page-widget/thanks-widget/thanks-widget.component';
import { TransportDealsLandingWidgetComponent } from '../../page-widget/transport-deals-landing-widget/transport-deals-landing-widget.component';
import { UnsubscribeMessageWidgetComponent } from '../../page-widget/unsubscribe-message-widget/unsubscribe-message-widget.component';
import { UnsubscribeWidgetComponent } from '../../page-widget/unsubscribe-widget/unsubscribe-widget.component';
import { WidgetType } from '../../shared/enums/widget-id.enum';
import {
  transformAboutWidgetConfiguration,
  transformAppDownloadButtonsWidgetConfiguration,
  transformAvisWidgetConfiguration,
  transformBlogWidgetConfiguration,
  transformCartWidgetConfiguration,
  transformCheckoutWidgetConfiguration,
  transformContactWidgetConfiguration,
  transformCookiesWidgetConfiguration,
  transformDiscoverCatalunyaWidgetConfiguration,
  transformDiscoverWidgetConfiguration,
  transformFaqWidgetConfiguration,
  transformFooterWidgetConfiguration,
  transformFreeHtmlWidgetConfiguration,
  transformGeneralAvisWidgetConfiguration,
  transformHeaderPrincipalImageWidgetConfiguration,
  transformHeaderWidgetConfiguration,
  transformHomeIntroWidgetConfiguration,
  transformHomeProductsWidgetConfiguration,
  transformInfoAlertWidgetConfiguration,
  transformLegalAdviceSignupWidgetConfiguration,
  transformLegalWidgetConfiguration,
  transformLinkCardsWidgetConfiguration,
  transformMenuWidgetConfiguration,
  transformMoreInformationWidgetConfiguration,
  transformMouteAmbHolaWidgetConfiguration,
  transformOpinionesVerificadasCarrouselWidgetConfiguration,
  transformPackWidgetConfiguration,
  transformProductCardWidgetConfiguration,
  transformProductDetailWidgetConfiguration,
  transformPromoAppWidgetConfiguration,
  transformPromoWidgetConfiguration,
  transformPromotionWidgetConfiguration,
  transformRecoverWidgetConfiguration,
  transformSectionIntroWidgetConfiguration,
  transformShowInfoWidgetConfiguration,
  transformSightseeingWidgetConfiguration,
  transformSitemapWidgetConfiguration,
  transformSliderWidgetConfiguration,
  transformTermsAndConditionsWidgetConfiguration,
  transformThanksWidgetConfiguration,
  transformTransportDealsLandingWidgetConfiguration,
  transformUnsubscribeMessageWidgetConfiguration,
  transformUnsubscribeWidgetConfiguration,
} from './widget.util';

export const widgetConfiguration = [
  {
    type: WidgetType.About,
    imageResize: { width: 400, height: 205 },
    component: AboutWidgetComponent,
    transform: transformAboutWidgetConfiguration,
  },
  {
    type: WidgetType.AppDownloadButtons,
    imageResize: { width: 400, height: 205 },
    component: AppDownloadButtonsWidgetComponent,
    transform: transformAppDownloadButtonsWidgetConfiguration,
  },
  {
    type: WidgetType.Avis,
    imageResize: { width: 400, height: 205 },
    component: AvisWidgetComponent,
    transform: transformAvisWidgetConfiguration,
  },
  {
    type: WidgetType.Blog,
    imageResize: { width: 400, height: 205 },
    component: BlogWidgetComponent,
    transform: transformBlogWidgetConfiguration,
  },
  {
    type: WidgetType.Cart,
    imageResize: { width: 400, height: 205 },
    component: CartWidgetComponent,
    transform: transformCartWidgetConfiguration,
  },
  {
    type: WidgetType.Checkout,
    imageResize: { width: 400, height: 205 },
    component: CheckoutWidgetComponent,
    transform: transformCheckoutWidgetConfiguration,
  },
  {
    type: WidgetType.Contact,
    imageResize: { width: 400, height: 205 },
    component: ContactWidgetComponent,
    transform: transformContactWidgetConfiguration,
  },
  {
    type: WidgetType.Cookies,
    imageResize: { width: 400, height: 205 },
    component: CookiesWidgetComponent,
    transform: transformCookiesWidgetConfiguration,
  },
  {
    type: WidgetType.Discover,
    imageResize: { width: 400, height: 205 },
    component: DiscoverWidgetComponent,
    transform: transformDiscoverWidgetConfiguration,
  },
  {
    type: WidgetType.DiscoverCatalunya,
    imageResize: { width: 400, height: 205 },
    component: DiscoverCatalunyaWidgetComponent,
    transform: transformDiscoverCatalunyaWidgetConfiguration,
  },
  {
    type: WidgetType.Faq,
    imageResize: { width: 1900, height: 400 },
    component: FaqWidgetComponent,
    transform: transformFaqWidgetConfiguration,
  },
  {
    type: WidgetType.Footer,
    imageResize: { width: 400, height: 205 },
    component: FooterWidgetComponent,
    transform: transformFooterWidgetConfiguration,
  },
  {
    type: WidgetType.FreeHtml,
    imageResize: { width: 400, height: 205 },
    component: FreeHtmlWidgetComponent,
    transform: transformFreeHtmlWidgetConfiguration,
  },
  {
    type: WidgetType.GeneralAvis,
    imageResize: { width: 400, height: 205 },
    component: GeneralAvisWidgetComponent,
    transform: transformGeneralAvisWidgetConfiguration,
  },
  {
    type: WidgetType.Header,
    imageResize: { width: 400, height: 205 },
    component: HeaderWidgetComponent,
    transform: transformHeaderWidgetConfiguration,
  },
  {
    type: WidgetType.HeaderPrincipalImage,
    imageResize: { width: 400, height: 205 },
    component: HeaderPrincipalImageWidgetComponent,
    transform: transformHeaderPrincipalImageWidgetConfiguration,
  },
  {
    type: WidgetType.HomeIntro,
    imageResize: { width: 400, height: 205 },
    component: HomeIntroWidgetComponent,
    transform: transformHomeIntroWidgetConfiguration,
  },
  {
    type: WidgetType.HomeProducts,
    imageResize: { width: 400, height: 205 },
    component: HomeProductsWidgetComponent,
    transform: transformHomeProductsWidgetConfiguration,
  },
  {
    type: WidgetType.InfoAlert,
    imageResize: { width: 400, height: 205 },
    component: InfoAlertWidgetComponent,
    transform: transformInfoAlertWidgetConfiguration,
  },
  {
    type: WidgetType.Legal,
    imageResize: { width: 400, height: 205 },
    component: LegalWidgetComponent,
    transform: transformLegalWidgetConfiguration,
  },
  {
    type: WidgetType.LegalAdviceSignup,
    imageResize: { width: 400, height: 205 },
    component: LegalAdviceSignupWidgetComponent,
    transform: transformLegalAdviceSignupWidgetConfiguration,
  },
  {
    type: WidgetType.LinkCards,
    imageResize: { width: 400, height: 205 },
    component: LinkCardsWidgetComponent,
    transform: transformLinkCardsWidgetConfiguration,
  },
  {
    type: WidgetType.Menu,
    imageResize: { width: 400, height: 205 },
    component: MenuWidgetComponent,
    transform: transformMenuWidgetConfiguration,
  },
  {
    type: WidgetType.MoreInformation,
    imageResize: { width: 400, height: 205 },
    component: MoreInformationWidgetComponent,
    transform: transformMoreInformationWidgetConfiguration,
  },
  {
    type: WidgetType.MouteAmbHola,
    imageResize: { width: 400, height: 205 },
    component: MouteAmbHolaWidgetComponent,
    transform: transformMouteAmbHolaWidgetConfiguration,
  },
  {
    type: WidgetType.OpinionesVerificadasCarrousel,
    imageResize: { width: 400, height: 205 },
    component: OpinionesVerificadasCarrouselWidgetComponent,
    transform: transformOpinionesVerificadasCarrouselWidgetConfiguration,
  },
  {
    type: WidgetType.Pack,
    imageResize: { width: 400, height: 205 },
    component: PackWidgetComponent,
    transform: transformPackWidgetConfiguration,
  },
  {
    type: WidgetType.ProductDetail,
    imageResize: { width: 400, height: 205 },
    component: ProductDetailWidgetComponent,
    transform: transformProductDetailWidgetConfiguration,
  },
  {
    type: WidgetType.ProductCard,
    imageResize: { width: 400, height: 205 },
    component: ProductCardWidgetComponent,
    transform: transformProductCardWidgetConfiguration,
  },
  {
    type: WidgetType.Promo,
    imageResize: { width: 400, height: 205 },
    component: PromoWidgetComponent,
    transform: transformPromoWidgetConfiguration,
  },
  {
    type: WidgetType.PromoApp,
    imageResize: { width: 400, height: 205 },
    component: PromoAppWidgetComponent,
    transform: transformPromoAppWidgetConfiguration,
  },
  {
    type: WidgetType.Promotion,
    imageResize: { width: 400, height: 205 },
    component: PromotionWidgetComponent,
    transform: transformPromotionWidgetConfiguration,
  },
  {
    type: WidgetType.Recover,
    imageResize: { width: 400, height: 205 },
    component: RecoverWidgetComponent,
    transform: transformRecoverWidgetConfiguration,
  },
  {
    type: WidgetType.SectionIntro,
    imageResize: { width: 400, height: 205 },
    component: SectionIntroWidgetComponent,
    transform: transformSectionIntroWidgetConfiguration,
  },
  {
    type: WidgetType.ShowInfo,
    imageResize: { width: 400, height: 205 },
    component: ShowInfoWidgetComponent,
    transform: transformShowInfoWidgetConfiguration,
  },
  {
    type: WidgetType.Sightseeing,
    imageResize: { width: 400, height: 205 },
    component: SightseeingWidgetComponent,
    transform: transformSightseeingWidgetConfiguration,
  },
  {
    type: WidgetType.Slider,
    imageResize: { width: 400, height: 205 },
    component: SliderWidgetComponent,
    transform: transformSliderWidgetConfiguration,
  },
  {
    type: WidgetType.Sitemap,
    imageResize: { width: 400, height: 205 },
    component: SitemapWidgetComponent,
    transform: transformSitemapWidgetConfiguration,
  },
  {
    type: WidgetType.TermsAndConditions,
    imageResize: { width: 400, height: 205 },
    component: TermsAndConditionsWidgetComponent,
    transform: transformTermsAndConditionsWidgetConfiguration,
  },
  {
    type: WidgetType.Thanks,
    imageResize: { width: 400, height: 205 },
    component: ThanksWidgetComponent,
    transform: transformThanksWidgetConfiguration,
  },
  {
    type: WidgetType.TransportDealsLanding,
    imageResize: { width: 400, height: 205 },
    component: TransportDealsLandingWidgetComponent,
    transform: transformTransportDealsLandingWidgetConfiguration,
  },
  {
    type: WidgetType.Unsubscribe,
    imageResize: { width: 400, height: 205 },
    component: UnsubscribeWidgetComponent,
    transform: transformUnsubscribeWidgetConfiguration,
  },
  {
    type: WidgetType.UnsubscribeMessage,
    imageResize: { width: 400, height: 205 },
    component: UnsubscribeMessageWidgetComponent,
    transform: transformUnsubscribeMessageWidgetConfiguration,
  },
];
