import { computed, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { map, Subject, tap } from 'rxjs';
import { SessionStorageKey } from '../enums/session-storage-key.enum';
import { GtmDataLayerService } from '../gtm/gtm.service';
import { CartProduct } from '../models/cart-product.interface';
import { CartSummaryProduct } from '../models/cart-summary-product.interface';
import { CartSummaryProductsGroup } from '../models/cart-summary-products-group.interface';
import { SessionStorageService } from './session-storage.service';

@Injectable({ providedIn: 'root' })
export class CartService {
  private readonly sessionStorageService = inject(SessionStorageService);
  private readonly gtmDataLayerService = inject(GtmDataLayerService);

  public products = signal<CartProduct[]>(
    (this.sessionStorageService.get(SessionStorageKey.Cart) as CartProduct[]) ||
      [],
  );
  public productsQuantity = computed(() => this.products().length);

  public cartSummaryProducts = computed(() =>
    this.products().reduce(
      (acc: CartSummaryProduct[], cartProduct: CartProduct) => {
        const item = acc.find(
          (a) =>
            a.product?.code === cartProduct?.product?.code &&
            a.reservationForm?.departTrip?.tagCode ===
              cartProduct.reservationForm?.departTrip?.tagCode &&
            a.reservationForm?.departTrip?.startDatetime ===
              cartProduct.reservationForm?.departTrip?.startDatetime &&
            a.reservationForm?.returnTrip?.tagCode ===
              cartProduct.reservationForm?.returnTrip?.tagCode &&
            a.reservationForm?.returnTrip?.startDatetime ===
              cartProduct.reservationForm?.returnTrip?.startDatetime,
        );
        if (item) {
          item.quantity++;
          item.activePrice = item.product.activePrice;
          item.reducedPrice = item.product.reducedPrice;
        } else {
          if (cartProduct.parentProduct?.products?.length) {
            const items = cartProduct.parentProduct.products.map(
              (p) =>
                ({
                  superProduct: cartProduct.superProduct,
                  parentProduct: cartProduct.parentProduct,
                  product: p,
                  quantity: p.code === cartProduct.product.code ? 1 : 0,
                  activePrice:
                    p.code === cartProduct.product.code ? p.activePrice : 0,
                  reducedPrice:
                    p.code === cartProduct.product.code ? p.reducedPrice : 0,
                  reservationInfo: cartProduct.reservationInfo,
                  reservationForm: cartProduct.reservationForm,
                  tagCode: cartProduct.reservationForm?.departTrip?.tagCode,
                  departDate:
                    cartProduct.reservationForm?.departTrip?.startDatetime,
                  returnDate:
                    cartProduct.reservationForm?.returnTrip?.startDatetime,
                }) as CartSummaryProduct,
            );
            acc = [...acc, ...items];
          } else {
            const item = {
              parentProduct: null,
              product: cartProduct.product,
              quantity: 1,
              activePrice: cartProduct.product.activePrice,
              reducedPrice: cartProduct.product.reducedPrice,
              reservationInfo: cartProduct.reservationInfo,
              reservationForm: cartProduct.reservationForm,
              tagCode: cartProduct.reservationForm?.departTrip?.tagCode,
              departDate:
                cartProduct.reservationForm?.departTrip?.startDatetime,
              returnDate:
                cartProduct.reservationForm?.returnTrip?.startDatetime,
            } as CartSummaryProduct;
            acc = [...acc, item];
          }
        }
        return acc;
      },
      [],
    ),
  );

  private cartSummaryProductsGroupsObject = computed(() =>
    this.cartSummaryProducts().reduce(
      (
        acc: {
          [key: string]: CartSummaryProductsGroup;
        },
        curr: CartSummaryProduct,
      ) => {
        const key = `${curr.parentProduct?.code || ''} ${curr.departDate || ''} ${curr.returnDate || ''} ${curr.tagCode || ''}`;
        if (!acc[key]) {
          acc[key] = {
            products: [],
            totalActivePrice: 0,
            totalReducedPrice: 0,
            code: curr.parentProduct?.code || '',
            name: curr.parentProduct?.name || '',
            tagCode: curr.tagCode || '',
            departDate: curr.departDate || '',
            returnDate: curr.returnDate || '',
          };
        }
        acc[key] = {
          ...acc[key],
          products: [...acc[key].products, curr],
          totalActivePrice:
            acc[key].totalActivePrice + curr.activePrice * curr.quantity,
          totalReducedPrice:
            acc[key].totalReducedPrice + curr.reducedPrice * curr.quantity,
        };
        return acc;
      },
      {},
    ),
  );

  public cartSummaryProductsGroups = computed(() =>
    Array.from(
      Object.entries(this.cartSummaryProductsGroupsObject()),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([key, value]) => value,
    ),
  );

  // Precio total de la orden
  public orderActivePrice = computed(() =>
    this.cartSummaryProductsGroups().reduce(
      (acc, curr) => acc + curr.totalActivePrice,
      0,
    ),
  );

  // Precio total de la orden con descuento
  public orderReducedPrice = computed(() =>
    this.cartSummaryProductsGroups().reduce(
      (acc, curr) => acc + curr.totalReducedPrice,
      0,
    ),
  );

  private store$ = new Subject<CartProduct[]>();
  private clear$ = new Subject<void>();
  private add$ = new Subject<CartProduct>();
  private remove$ = new Subject<CartProduct>();
  private delete$ = new Subject<CartProduct[]>();

  constructor() {
    this.store$
      .pipe(
        takeUntilDestroyed(),
        tap((p) => console.log('Guardando carrito: ', p)),
        tap((products) => this.gtmDataLayerService.addToCart(products)),
        map((products) =>
          [...this.products(), ...products].sort(
            (a, b) => +a.product.code - +b.product.code,
          ),
        ),
        tap((products) =>
          this.sessionStorageService.set(SessionStorageKey.Cart, products),
        ),
      )
      .subscribe((products) => this.products.set(products));

    this.clear$
      .pipe(
        takeUntilDestroyed(),
        tap(() => console.log('Vaciando carrito')),
        tap(() => this.gtmDataLayerService.removeFromCart(this.products())),
        map(() => []),
        tap((products) =>
          this.sessionStorageService.set(SessionStorageKey.Cart, products),
        ),
      )
      .subscribe((products) => this.products.set(products));

    this.add$
      .pipe(
        takeUntilDestroyed(),
        tap((p) => console.log('Añadiendo producto al carrito', p)),
        tap((p) => this.gtmDataLayerService.addToCart([p])),
        map((product) =>
          [...this.products(), product].sort(
            (a, b) => +a.product.code - +b.product.code,
          ),
        ),
        tap((products) =>
          this.sessionStorageService.set(SessionStorageKey.Cart, products),
        ),
      )
      .subscribe((products) => this.products.set(products));

    this.remove$
      .pipe(
        takeUntilDestroyed(),
        tap((p) => console.log('Eliminando producto del carrito', p)),
        tap((p) => this.gtmDataLayerService.removeFromCart([p])),
        map((product) => {
          const index = this.products().findIndex(
            (p) =>
              p.product.code === product.product.code &&
              p.reservationForm?.departTrip?.startDatetime ===
                product.reservationForm?.departTrip?.startDatetime &&
              p.reservationForm?.returnTrip?.startDatetime ===
                product.reservationForm?.returnTrip?.startDatetime &&
              p.reservationForm?.tagCode === product.reservationForm?.tagCode,
          );
          return this.products()
            .slice(0, index)
            .concat(this.products().slice(index + 1));
        }),
        tap((products) =>
          this.sessionStorageService.set(SessionStorageKey.Cart, products),
        ),
      )
      .subscribe((products) => this.products.set(products));

    this.delete$
      .pipe(
        takeUntilDestroyed(),
        tap((p) => console.log('Eliminando producto padre del carrito', p)),
        map((products) =>
          this.products().filter(
            (s) =>
              !products.find(
                (p) =>
                  p.product.code === s.product.code &&
                  p.reservationForm?.departTrip?.startDatetime ===
                    s.reservationForm?.departTrip?.startDatetime &&
                  p.reservationForm?.returnTrip?.startDatetime ===
                    s.reservationForm?.returnTrip?.startDatetime,
              ),
          ),
        ),
        tap((products) =>
          this.sessionStorageService.set(SessionStorageKey.Cart, products),
        ),
      )
      .subscribe((products) => this.products.set(products));
  }

  public store(products: CartProduct[]): void {
    this.store$.next(products);
  }
  public clear(): void {
    this.clear$.next();
  }
  public add(product: CartProduct): void {
    this.add$.next(product);
  }
  public remove(product: CartProduct): void {
    this.remove$.next(product);
  }
  public delete(products: CartProduct[]): void {
    this.delete$.next(products);
  }
}
