import { NgClass } from '@angular/common';
import {
  Component,
  ElementRef,
  ViewChild,
  computed,
  inject,
  input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PurchaseSummaryComponent } from '../../shared/components/purchase-summary/purchase-summary.component';
import { RedsysIframeComponent } from '../../shared/components/redsys-iframe/redsys-iframe.component';
import { CustomerType } from '../../shared/enums/customer-type.enum';
import { PageId } from '../../shared/enums/page-id.enum';
import { GtmDataLayerService } from '../../shared/gtm/gtm.service';
import { Country } from '../../shared/models/country.interface';
import {
  CustomerInformation,
  FiscalAddress,
  OrderInvoice,
} from '../../shared/models/order.interface';
import { CartService } from '../../shared/services/cart.service';
import { CountriesService } from '../../shared/services/countries.service';
import { NavigationService } from '../../shared/services/navigation.service';
import { OrderService } from '../../shared/services/order.service';
import Validation from '../../shared/utils/validation';
import { documentNumberValidator } from '../../shared/validators/document-number.validator';
import { emailValidator } from '../../shared/validators/email.validator';
import { CheckoutWidgetConfiguration } from './models/checkout-widget-configuration.interface';

enum DocumentType {
  DNI = 'DNI',
  NIE = 'NIE',
  CIF = 'CIF',
  Others = 'ALTRES',
}

@Component({
  selector: 'hola-checkout-widget',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgClass,
    TranslateModule,
    RouterModule,
    RedsysIframeComponent,
    PurchaseSummaryComponent,
  ],
  templateUrl: './checkout-widget.component.html',
  styleUrl: './checkout-widget.component.scss',
})
export class CheckoutWidgetComponent {
  @ViewChild('customerType', { static: false }) customerType!: ElementRef;

  public configuration = input.required<CheckoutWidgetConfiguration>();

  private readonly formBuilder = inject(FormBuilder);
  private readonly countriesService = inject(CountriesService);
  private readonly orderService = inject(OrderService);
  private readonly navigationService = inject(NavigationService);
  private readonly cartService = inject(CartService);
  private readonly gtmDataLayerService = inject(GtmDataLayerService);

  public homeUrl = this.navigationService.homeUrl;
  public cartUrl = this.navigationService.cartUrl;
  public termsAndConditionsUrl = this.navigationService.termsAndConditionsUrl;

  public form: FormGroup = new FormGroup({
    name: new FormControl(''),
    surname: new FormControl(''),
    email: new FormControl(''),
    confirmEmail: new FormControl(''),
    requestInvoice: new FormControl(false),
    acceptTerms: new FormControl(false),
    invoiceRequested: this.formBuilder.group({
      customerType: new FormControl(''),
      invoiceName: new FormControl(''),
      country: new FormControl(''),
      // countryCode: new FormControl(''),
      province: new FormControl(''),
      city: new FormControl(''),
      documentType: new FormControl(''),
      vatNumber: new FormControl(''),
      address: new FormControl(''),
      addressNumber: new FormControl(''),
      addressFloor: new FormControl(''),
      postalCode: new FormControl(''),
      contactEmail: new FormControl(''),
      contactInformation: new FormControl(''),
    }),
    extraInformation: new FormControl(false),
  });

  public hppData = this.orderService.hppData;

  public countries = computed(() => this.countriesService.countries());
  public customerTypes = computed(() => Object.values(CustomerType));
  public documentTypes = computed(() =>
    Object.entries(DocumentType).map((entry) => ({
      key: `commons.document_type.${entry[0].toLowerCase()}`,
      value: entry[1],
    })),
  );

  public submitted = false;
  public nameFocus: boolean = false;
  public surnameFocus: boolean = false;
  public email1Focus: boolean = false;
  public email2Focus: boolean = false;

  public customerTypeFocus: boolean = false;
  public invoiceNameFocus: boolean = false;
  public countryFocus: boolean = false;
  public provinceFocus: boolean = false;
  public cityFocus: boolean = false;
  public documentTypeFocus: boolean = false;
  public vatNumberFocus: boolean = false;
  public addressFocus: boolean = false;
  public addressNumberFocus: boolean = false;
  public addressFloorFocus: boolean = false;
  public postalCodeFocus: boolean = false;
  public contactEmailFocus: boolean = false;
  public contactInformationFocus: boolean = false;

  public get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  public get invoiceForm(): { [key: string]: AbstractControl } {
    return (this.form.controls['invoiceRequested'] as FormGroup).controls;
  }

  constructor() {
    this.orderService.reset();
    if (this.orderService.tempOrder()) {
      this.initForm();
      // Gtm Checkout 2
      this.gtmDataLayerService.checkout(
        this.cartService.products(),
        2,
        'datos',
      );
    } else {
      this.navigationService.navigateToPage(PageId.Cart);
    }
  }

  public pay(): void {
    this.submitted = true;

    // TODO lanzar validación formulario?

    if (this.form.invalid) {
      this.scrollToFirstInvalidControl();
      console.log('form invalid', this.form);
      return;
    }

    this.orderService.applyCustomerInformation(
      this.getCustomerInformation(this.form),
    );

    if (this.form.controls['requestInvoice'].value)
      this.orderService.applyOrderInvoice(
        this.getRequestedOrderInvoice(
          this.form.controls['invoiceRequested'] as FormGroup,
        ),
      );

    this.orderService.create();
  }

  public onBlur(item: string) {
    switch (item) {
      case 'name':
        this.nameFocus = false;
        break;
      case 'surname':
        this.surnameFocus = false;
        break;
      case 'email1':
        this.email1Focus = false;
        break;
      case 'email2':
        this.email2Focus = false;
        break;
      case 'customerType':
        this.customerTypeFocus = false;
        break;
      case 'invoiceName':
        this.invoiceNameFocus = false;
        break;
      case 'country':
        this.countryFocus = false;
        break;
      case 'province':
        this.provinceFocus = false;
        break;
      case 'city':
        this.cityFocus = false;
        break;
      case 'documentType':
        this.documentTypeFocus = false;
        break;
      case 'vatNumber':
        this.vatNumberFocus = false;
        break;
      case 'address':
        this.addressFocus = false;
        break;
      case 'addressNumber':
        this.addressNumberFocus = false;
        break;
      case 'addressFloor':
        this.addressFloorFocus = false;
        break;
      case 'postalCode':
        this.postalCodeFocus = false;
        break;
      case 'contactEmail':
        this.contactEmailFocus = false;
        break;
      case 'contactInformation':
        this.contactInformationFocus = false;
        break;
      default:
        console.warn(`Unhandled item: ${item}`);
    }
  }

  public onFocus(item: string) {
    switch (item) {
      case 'name':
        this.nameFocus = true;
        break;
      case 'surname':
        this.surnameFocus = true;
        break;
      case 'email1':
        this.email1Focus = true;
        break;
      case 'email2':
        this.email2Focus = true;
        break;
      case 'customerType':
        this.customerTypeFocus = true;
        break;
      case 'invoiceName':
        this.invoiceNameFocus = true;
        break;
      case 'country':
        this.countryFocus = true;
        break;
      case 'province':
        this.provinceFocus = true;
        break;
      case 'city':
        this.cityFocus = true;
        break;
      case 'documentType':
        this.documentTypeFocus = true;
        break;
      case 'vatNumber':
        this.vatNumberFocus = true;
        break;
      case 'address':
        this.addressFocus = true;
        break;
      case 'addressNumber':
        this.addressNumberFocus = true;
        break;
      case 'addressFloor':
        this.addressFloorFocus = true;
        break;
      case 'postalCode':
        this.postalCodeFocus = true;
        break;
      case 'contactEmail':
        this.contactEmailFocus = true;
        break;
      case 'contactInformation':
        this.contactInformationFocus = true;
        break;
      default:
        console.warn(`Unhandled item: ${item}`);
    }
  }

  private initForm(): void {
    this.form = this.formBuilder.group(
      {
        name: ['', Validators.required],
        surname: ['', Validators.required],
        email: ['', emailValidator],
        confirmEmail: ['', emailValidator],
        acceptTerms: [false, Validators.requiredTrue],
        requestInvoice: [false],
        invoiceRequested: this.formBuilder.group({
          customerType: [''],
          invoiceName: [''],
          country: [''],
          // countryCode: [''],
          province: [''],
          city: [''],
          documentType: [''],
          vatNumber: [''],
          address: [''],
          addressNumber: [''],
          addressFloor: [''],
          postalCode: [''],
          contactEmail: [''],
          contactInformation: [''],
        }),
        extraInformation: [false],
      },
      {
        validators: [Validation.match('email', 'confirmEmail')],
      },
    );

    this.form.controls['requestInvoice'].valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe((value) => this.updateInvoiceFormValidators(value));
    this.invoiceForm['documentType'].valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.invoiceForm['vatNumber'].reset());
  }

  private updateInvoiceFormValidators(invoice: boolean): void {
    if (invoice) {
      this.invoiceForm['customerType'].setValidators(Validators.required);
      this.invoiceForm['invoiceName'].setValidators(Validators.required);
      this.invoiceForm['country'].setValidators(Validators.required);
      // this.invoiceForm['countryCode'].setValidators(Validators.required);
      this.invoiceForm['province'].setValidators(Validators.required);
      this.invoiceForm['city'].setValidators(Validators.required);
      this.invoiceForm['documentType'].setValidators(Validators.required);
      this.invoiceForm['vatNumber'].setValidators(
        Validators.compose([
          Validators.required,
          documentNumberValidator(() => this.invoiceForm['documentType']),
        ]),
      );
      this.invoiceForm['postalCode'].setValidators(Validators.required);
      this.invoiceForm['address'].setValidators(Validators.required);
      this.invoiceForm['addressNumber'].setValidators(Validators.required);
      this.invoiceForm['addressFloor'].setValidators(Validators.required);
      this.invoiceForm['contactEmail'].setValidators(emailValidator);
    } else {
      this.invoiceForm['customerType'].setValidators(null);
      this.invoiceForm['invoiceName'].setValidators(null);
      this.invoiceForm['country'].setValidators(null);
      // this.invoiceForm['countryCode'].setValidators(null);
      this.invoiceForm['province'].setValidators(null);
      this.invoiceForm['city'].setValidators(null);
      this.invoiceForm['documentType'].setValidators(null);
      this.invoiceForm['vatNumber'].setValidators(null);
      this.invoiceForm['postalCode'].setValidators(null);
      this.invoiceForm['address'].setValidators(null);
      this.invoiceForm['addressNumber'].setValidators(null);
      this.invoiceForm['addressFloor'].setValidators(null);
      this.invoiceForm['contactEmail'].setValidators(null);
    }
    this.invoiceForm['customerType'].updateValueAndValidity();
    this.invoiceForm['invoiceName'].updateValueAndValidity();
    this.invoiceForm['country'].updateValueAndValidity();
    // this.invoiceForm['countryCode'].updateValueAndValidity();
    this.invoiceForm['province'].updateValueAndValidity();
    this.invoiceForm['city'].updateValueAndValidity();
    this.invoiceForm['documentType'].updateValueAndValidity();
    this.invoiceForm['vatNumber'].updateValueAndValidity();
    this.invoiceForm['postalCode'].updateValueAndValidity();
    this.invoiceForm['address'].setValidators(null);
    this.invoiceForm['addressNumber'].setValidators(null);
    this.invoiceForm['addressFloor'].setValidators(null);
    this.invoiceForm['contactEmail'].updateValueAndValidity();
  }

  private getCustomerInformation(form: FormGroup): CustomerInformation {
    const email = form.controls['email'].value;
    const name = form.controls['name'].value;
    const surname = form.controls['surname'].value;
    const fullName = `${name} ${surname}`;
    return { email, name, surname, fullName };
  }

  private getRequestedOrderInvoice(form: FormGroup): OrderInvoice {
    const companyCode = '-';
    const companyOrProfessional =
      form.controls['customerType'].value === CustomerType.Professional;
    const contactData = form.controls['contactInformation'].value;
    const contactEmail = form.controls['contactEmail'].value;
    const customerType = form.controls['customerType'].value;
    const fiscalAddress = this.getFiscalAddress(form);
    const identificationNumber = `${form.controls['vatNumber'].value}`;
    const identificationType = form.controls['documentType'].value;
    const registeredName = form.controls['invoiceName'].value;
    return {
      companyCode,
      companyOrProfessional,
      contactData,
      contactEmail,
      customerType,
      fiscalAddress,
      identificationNumber,
      identificationType,
      registeredName,
    };
  }

  private getFiscalAddress(form: FormGroup): FiscalAddress {
    const address = form.controls['address'].value;
    const cityName = form.controls['city'].value;
    const countryCode = (form.controls['country'].value as Country)?.codeAlfa3;
    const countryName = (form.controls['country'].value as Country)?.name;
    const provinceName = form.controls['province'].value;
    const zipCode = form.controls['postalCode'].value;
    const optionalInformation = ''; // TODO de dónde se obtiene?

    return {
      address,
      cityName,
      countryCode,
      countryName,
      optionalInformation,
      provinceName,
      zipCode,
    };
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement | null =
      document.querySelector('.is-invalid');

    if (firstInvalidControl) {
      firstInvalidControl.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      firstInvalidControl.focus();
    }
  }

  openSelect(item: string) {
    switch (item) {
      case 'customerType':
        this.customerType.nativeElement.click();
        this.customerType.nativeElement.focus();
        break;
      // otros casos
    }
  }
}
