<!--Promocio especial -->
@if (isVisible() && configuration().text !== "") {
  <div class="promo-container">
    <section class="head">
      <!-- top alert -->
      <div id="top-alert">
        <span class="float-left d-inline-block">
          {{ configuration().title }}
        </span>
        <span class="center d-inline-block">
          {{ configuration().text }}
        </span>
        <button
          type="button"
          class="btn-close"
          data-dismiss="modal"
          aria-hidden="true"
          (click)="hide()"
        >
          &times;
        </button>
      </div>
    </section>
  </div>
}
