// widgetType - asociamos un tipo al nombre de widgetType que hay en Strapi
// por ejemplo MouteAmbHola = 'moute_amb_hola' (en Strapi se llama "moute_amb_hola")

export enum WidgetType {
  About = 'about',
  AppDownloadButtons = 'app_download_buttons',
  Avis = 'avis',
  Blog = 'blog',
  Cart = 'cart',
  Checkout = 'checkout',
  Contact = 'contact',
  Cookies = 'cookies',
  Discover = 'discover',
  DiscoverCatalunya = 'discover_catalunya',
  Faq = 'faq',
  Footer = 'footer',
  FreeHtml = 'free_html',
  GeneralAvis = 'general_avis',
  Header = 'header',
  HeaderPrincipalImage = 'header_principal_image',
  HomeIntro = 'home_intro',
  HomeProducts = 'home_products',
  InfoAlert = 'info_alert',
  Legal = 'legal',
  LegalAdviceSignup = 'legal_advice_signup',
  LinkCards = 'link_cards',
  Menu = 'menu',
  MoreInformation = 'more_information',
  MouteAmbHola = 'moute_amb_hola',
  OpinionesVerificadasCarrousel = 'opiniones_verificadas_carrousel',
  Pack = 'pack',
  ProductDetail = 'product_detail',
  ProductCard = 'product_card',
  Promo = 'promo',
  PromoApp = 'promo_app',
  Promotion = 'promotion',
  Recover = 'recover',
  SectionIntro = 'section_intro',
  ShowInfo = 'show_info',
  Sightseeing = 'sightseeing',
  Slider = 'slider',
  Sitemap = 'sitemap',
  TermsAndConditions = 'terms_and_conditions',
  Thanks = 'thanks',
  TransportDealsLanding = 'transport_deals_landing',
  Unsubscribe = 'unsubscribe',
  UnsubscribeMessage = 'unsubscribe_message',
}
