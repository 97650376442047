import { Component, input, signal } from '@angular/core';
import { PromotionWidgetConfiguration } from './models/promotion-widget-configuration.interface';

@Component({
  selector: 'hola-promotion-widget',
  standalone: true,
  imports: [],
  templateUrl: './promotion-widget.component.html',
  styleUrl: './promotion-widget.component.scss',
})
export class PromotionWidgetComponent {
  configuration = input.required<PromotionWidgetConfiguration>();
  isVisible = signal(true);

  hide() {
    this.isVisible.set(false);
  }
}
