import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CookiesWidgetConfiguration } from './models/cookies-widget-configuration.interface';

@Component({
  selector: 'hola-cookies-widget',
  standalone: true,
  imports: [TranslateModule, NgClass],
  templateUrl: './cookies-widget.component.html',
  styleUrl: './cookies-widget.component.scss',
})
export class CookiesWidgetComponent {
  configuration = input.required<CookiesWidgetConfiguration>();
  numbers: number[] = Array.from({ length: 12 }, (_, i) => i + 1);
}
