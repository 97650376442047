<section>
  <div class="full-width-top-banner detail-top-banner">
    <img
      class="ticket-shadow"
      alt="shadow"
      src="/assets/images/shared/header_shadow.png"
    />
    <div class="faq-title-container">
      <h2 class="title-terms_and_conditions">
        {{ "template.terms_and_conditions.title" | translate }}
      </h2>
    </div>
  </div>
</section>

<!-- INIT CONTENT MAIN TEXT -->
<section class="terms_and_conditions_section">
  <div class="container-fluid home-products-container">
    <div class="row col-home">
      <!--TODO: revisar como solucionar los translate con parametros-->
      <div
        class="col-sm-8"
        [innerHTML]="'template.terms_and_conditions.content' | translate"
      ></div>
    </div>
  </div>
</section>
