import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SessionStorageKey } from '../enums/session-storage-key.enum';
import { isPlatformBrowser } from '@angular/common';

interface SessionStorageItem {
  value: unknown;
  lastUpdateDate: string;
}

@Injectable({ providedIn: 'root' })
export class SessionStorageService {

  private readonly platformIdService = inject(PLATFORM_ID);
  private isBrowser=isPlatformBrowser(this.platformIdService);
  public expirationTime = environment.sessionStorageExpirationTime;

  public get(key: SessionStorageKey): unknown | null {
    if (!this.isBrowser) {
      return null;
    }
    try {
      const sessionStorageValue = sessionStorage.getItem(key);
      if (!sessionStorageValue) {
        console.log(
          `No es posible encontrar el item ${key} en el session storage`,
        );
        return null;
      }
      const item = JSON.parse(sessionStorageValue) as SessionStorageItem;
      const hasExpired =
        new Date().getTime() - new Date(item.lastUpdateDate).getTime() >
        this.expirationTime;
      if (!hasExpired) {
        return item.value;
      }
      console.log(`El item ${key} del session storage ha expirado`);
      this.remove(key);
      return null;
    } catch {
      console.log('Error al parsear el objeto del session storage');
      return null;
    }
  }

  public set(key: SessionStorageKey, value: unknown): void {
    if (!this.isBrowser) {
      return
    }
    const data = JSON.stringify({
      value,
      lastUpdateDate: new Date().toISOString(),
    });
    sessionStorage.setItem(key, data);
  }

  public remove(key: SessionStorageKey): void {
    sessionStorage.removeItem(key);
  }

  public clear(): void {
    sessionStorage.clear();
  }
}
