import { Component, input } from '@angular/core';
import { DiscoverWidgetConfiguration } from './models/discover-widget-configuration.interface';

@Component({
  selector: 'hola-discover-widget',
  standalone: true,
  imports: [],
  templateUrl: './discover-widget.component.html',
  styleUrl: './discover-widget.component.scss',
})
export class DiscoverWidgetComponent {
  configuration = input.required<DiscoverWidgetConfiguration>();
}
