import { Component, computed, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DiscountAmount } from '../../../../models/discount-amount.interface';
import { Discount } from '../../../../models/discount.interface';
import { CustomCurrencyPipe } from '../../../../pipes/custom-currency.pipe';
import { LanguageService } from '../../../../services/language.service';
import { OrderService } from '../../../../services/order.service';

@Component({
  selector: 'hola-order-discounts-amounts',
  standalone: true,
  imports: [TranslateModule, CustomCurrencyPipe],
  templateUrl: './order-discounts-amounts.component.html',
  styleUrl: './order-discounts-amounts.component.scss',
})
export class OrderDiscountsAmountsComponent {
  private readonly languageService = inject(LanguageService);
  private readonly orderService = inject(OrderService);

  public appLanguage = this.languageService.language;
  public locale = this.languageService.locale;

  public itemDiscountsAmounts = computed(() =>
    this.orderService
      .discounts()
      .reduce((acc: DiscountAmount[], curr: Discount) => {
        const item = acc.find((a) => a.code === curr?.discountCode);
        if (item) {
          item.amount = item.amount + curr.discountAmount;
        } else {
          const item = {
            code: curr.discountCode,
            name: curr.discountName,
            amount: curr.discountAmount,
          } as DiscountAmount;
          acc = [...acc, item];
        }
        return acc;
      }, []),
  );
}
