import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { OrderService } from '../../../../services/order.service';

@Component({
  selector: 'hola-order-discounts-apply',
  standalone: true,
  imports: [TranslateModule, ReactiveFormsModule],
  templateUrl: './order-discounts-apply.component.html',
  styleUrl: './order-discounts-apply.component.scss',
})
export class OrderDiscountsApplyComponent {
  private readonly orderService = inject(OrderService);
  private readonly formBuilder = inject(FormBuilder);

  public discountForm: FormGroup = this.formBuilder.group({
    discount: [''],
  });

  public apply(): void {
    const discount = this.discountForm.controls['discount'].value as string;
    if (discount) {
      this.orderService.applyDiscount(discount);
    }
  }
}
