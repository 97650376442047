import { CommonModule } from '@angular/common';
import { Component, HostListener, input } from '@angular/core';
import { MouteAmbHolaWidgetConfiguration } from './models/moute-amb-hola-widget-configuration.interface';

@Component({
  selector: 'hola-moute-amb-hola-widget',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './moute-amb-hola-widget.component.html',
  styleUrl: './moute-amb-hola-widget.component.scss',
})
export class MouteAmbHolaWidgetComponent {
  configuration = input.required<MouteAmbHolaWidgetConfiguration>();
  public currentSlideIndex = 0;
  nextSlide() {
    console.log('next slide');
    this.currentSlideIndex =
      (this.currentSlideIndex + 1) % this.configuration().images.length;
  }

  prevSlide() {
    this.currentSlideIndex =
      (this.currentSlideIndex - 1 + this.configuration().images.length) %
      this.configuration().images.length;
  }

  public slideWidth: number = 25; // Valor por defecto para pantallas grandes

  constructor() {
    this.updateSlideWidth(window.innerWidth); // Configuración inicial
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const target = event.target as Window | null;
    if (target && 'innerWidth' in target) {
      this.updateSlideWidth(target.innerWidth);
    }
  }
  updateSlideWidth(width: number) {
    if (width <= 575) {
      this.slideWidth = 100;
    } else if (width <= 767) {
      this.slideWidth = 50;
    } else if (width <= 992) {
      this.slideWidth = 33;
    } else {
      this.slideWidth = 25;
    }
  }
}
