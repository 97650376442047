import { Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CustomCurrencyPipe } from '../../shared/pipes/custom-currency.pipe';
import { PackWidgetConfiguration } from './models/pack-widget-configuration.interface';

@Component({
  selector: 'hola-pack-widget',
  standalone: true,
  imports: [CustomCurrencyPipe, TranslateModule],
  templateUrl: './pack-widget.component.html',
  styleUrl: './pack-widget.component.scss',
})
export class PackWidgetComponent {
  public configuration = input.required<PackWidgetConfiguration>();
}
