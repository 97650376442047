@for (cartSummaryProducts of productsPerDate(); track $index) {
  <br />
  <p>
    {{ cartSummaryProducts[0].departDate | date: "dd/MM/yyyy HH:mm:ss" }}
    {{ cartSummaryProducts[0].returnDate | date: "dd/MM/yyyy HH:mm:ss" }}
    {{ cartSummaryProducts[0].tagCode }}
  </p>
  @for (cartSummaryProduct of cartSummaryProducts; track $index) {
    <div>
      {{ cartSummaryProduct.product.name }}
      {{ cartSummaryProduct.quantity }}
      {{ cartSummaryProduct.activePrice | customCurrency: locale() }}
    </div>
  }
}
