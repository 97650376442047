@if (configuration().title) {
  <section class="blog">
    <div class="top-content">
      <div id="cbt-blog" class="container-fluid">
        <h2 class="blog-title">{{ configuration().title }}</h2>
        <h4 class="blog-subtitle">{{ configuration().description }}</h4>

        <div
          id="carousel-blog"
          class="carousel slide mrb30"
          data-ride="carousel"
          data-interval="false"
        >
          <div class="carousel-inner row w-100 mx-auto" role="listbox">
            <!--TODO: Obtener los post del blog-->
            <!--for(){-->
            <div class="carousel-item col-6 col-sm-6 col-md-4 active">
              <a
                href="#"
                title="TITULO POST"
                target="_blank"
                class="comprar_home_packs blog-links"
              >
                <div class="">
                  <img
                    class="blog-img"
                    alt="TITULO POST"
                    src="/assets/images/home-widget/5.jpg"
                  />
                  <div class="slider-shadow"></div>
                </div>
                <div class="d-md-block">
                  <span class="blog-title-span">TITULO POST</span>
                  <span class="blog-summary-span">SUMMARY POST</span>
                  <span class="blog-button"
                    >{{ "template.home.read_more" | translate }}
                    <i
                      class="fa fa-chevron-right fa-chevron-right-extra"
                      aria-hidden="true"
                    ></i
                  ></span>
                </div>
              </a>
            </div>

            <!--}-->
          </div>
          <a
            class="carousel-control-prev"
            href="#carousel-blog"
            role="button"
            data-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"
              ><b
                class="fa fa-chevron-left fa-chevron-left-extra"
                aria-hidden="true"
              ></b
            ></span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next"
            href="#carousel-blog"
            role="button"
            data-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"
              ><b
                class="fa fa-chevron-right fa-chevron-right-extra"
                aria-hidden="true"
              ></b
            ></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>

      <a
        type="button"
        id="banner-button"
        class="btn btn-primary blog-button-go"
        href="{{ configuration().button_url }}"
        target="_blank"
        aria-hidden="true"
        >{{ configuration().button }}</a
      >
    </div>

    <div style="clear: both"></div>
  </section>
  <!-- BLOG -->
}
