import { Component, input } from '@angular/core';
import { SliderWidgetConfiguration } from './models/slider-widget-configuration.interface';

@Component({
  selector: 'hola-slider-widget',
  standalone: true,
  imports: [],
  templateUrl: './slider-widget.component.html',
  styleUrl: './slider-widget.component.scss',
})
export class SliderWidgetComponent {
  configuration = input.required<SliderWidgetConfiguration>();
}
